import React from 'react';
import { TotBackground as TotBackgroundInj } from '../TotBackground';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';

export const NotAllowed = (): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], NotAllowed);
    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Container maxWidth="xs">
                    <Paper elevation={0}>
                        <Box p={12} textAlign="center">
                            <Typography variant="h1">403</Typography>
                            <Typography variant="subtitle1" component="p">
                                <FormattedMessage id="common.actionNotAllowed" />
                            </Typography>
                        </Box>
                    </Paper>
                </Container>
            </Grid>
        </TotBackground>
    );
};
