import { Card, CardContent, IconButton, Link, Typography, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';

// Стили основного текста карточки
type CampaignLinkType = {
    isDarkBackground?: boolean;
} & PropsWithChildren<any>;

export const CardCampaignLink = withTheme(styled(
    React.forwardRef(
        (props: CampaignLinkType, ref): JSX.Element => {
            const { isDarkBackground, ...otherProps } = props;
            return <Link {...otherProps} ref={ref} />;
        },
    ),
)`
    font-size: 15px !important;
    font-weight: bold;
    text-decoration: none !important;
    color: ${({ theme, isDarkBackground }): string =>
        `${isDarkBackground ? theme.variables.palette.mainContrast : theme.variables.palette.textDark}`} !important;

    &:focus {
        outline: 1px solid ${({ theme }): string => `${theme.variables.palette.accent}`};
    }
    &:hover {
        color: ${({ theme, isDarkBackground }): string =>
            `${isDarkBackground ? theme.variables.palette.accent : theme.variables.palette.main}`} !important;
    }
`);

// Стили кнопки заявок (лампочка)
type CampaignRequestsButtonType = {
    requests?: number;
    isDarkBackground?: boolean;
} & PropsWithChildren<any>;

export const CampaignRequestsButton = withTheme(styled(
    (props: CampaignRequestsButtonType): JSX.Element => {
        const { requests, isDarkBackground, ...otherProps } = props;
        return <IconButton {...otherProps} disabled />;
    },
)`
    background-color: transparent !important;
    color: ${({ theme, requests }): string =>
        `${requests ? theme.variables.palette.accentDark : theme.variables.palette.mainMiddleLight}`} !important;
    &:hover {
        color: ${({ theme, isDarkBackground }): string =>
            `${
                isDarkBackground ? theme.variables.palette.mainContrast : theme.variables.palette.accentContrast
            }`} !important;
    }
`);

export const NotAccentText = withTheme(styled(Typography)`
    font-size: 10px !important;
    text-transform: uppercase;
    font-weight: 700 !important;
    color: ${({ theme }): string => `${theme.variables.palette.mainLight}`};
`);

export const AccentText = withTheme(styled(Typography)`
    font-size: 13px !important;
    text-transform: uppercase;
    font-weight: 700 !important;
    color: ${({ theme }): string => `${theme.variables.palette.red}`};
`);

// Стили контейнера контента карточки

type CampaignCardContentType = {
    isImage: boolean;
} & PropsWithChildren<any>;

export const CampaignCardContent = withTheme(styled(
    (props: CampaignCardContentType): JSX.Element => {
        const { isImage, ...otherProps } = props;
        return <CardContent {...otherProps} />;
    },
)`
    position: relative;
    padding: 0 !important;
    &:after {
      content: '';
      width: 100%;
      height: ${({ isImage }): string => (isImage ? '204px' : '0')};
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(28, 29, 31, 0.6);
    },
`);

type CampaignCardWrapperType = {
    isImage: boolean;
    showCreateRequestButton: boolean;
} & PropsWithChildren<any>;

export const CampaignCardWrapper = withTheme(styled(
    (props: CampaignCardWrapperType): JSX.Element => {
        const { isImage, showCreateRequestButton, ...otherProps } = props;
        return <Card {...otherProps} />;
    },
)`
    position: relative;
    width: 284px;
    ${({ theme, isImage }): string =>
        isImage ? '' : `background-color: ${theme?.variables.palette.campaignList.backgroundCard};`}
    height: ${({ showCreateRequestButton }): string => (showCreateRequestButton ? '320px' : '272px')};
`);
