import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FormattedMessage } from 'react-intl';
import { StartPageHeaderLink as StartPageHeaderLinkInj } from './StartPageHeaderLink';
import { di } from 'react-magnetic-di';

export type StartPageLoginLinkProps = {
    anchorRef: React.RefObject<HTMLDivElement>;
    handleToggle: () => void;
};

export const StartPageLoginLink = (props: StartPageLoginLinkProps): JSX.Element => {
    const { handleToggle, anchorRef } = props;

    const [StartPageHeaderLink] = di([StartPageHeaderLinkInj], StartPageLoginLink);

    return (
        <StartPageHeaderLink
            ref={anchorRef}
            component={Button}
            onClick={handleToggle}
            color="secondary"
            underline="none"
        >
            <Grid container item alignItems="center">
                <FormattedMessage id="startPage.headerLinks.login" />
                <Box ml={1} style={{ lineHeight: 1 }}>
                    <ExitToAppIcon />
                </Box>
            </Grid>
        </StartPageHeaderLink>
    );
};
