import { action, computed, IObservableArray, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { di } from 'react-magnetic-di';
import {
    ApprovalSheetDTO,
    ApprovalSheetGroupDTO,
    ApprovalSheetGroupExtended,
    ApprovalSheetGroupsDTO,
    ApprovalSheetResolution,
    ApprovalSheetStatus,
} from '@platform/approval-ui';
import { apiConfigs } from '../apiConfigs';
import { asyncAction } from 'mobx-utils';
import { AxiosResponse } from 'axios';
import { IdTitle } from '../models';

export class ApprovalStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;
    @observable sheets?: IObservableArray<ApprovalSheetDTO>;
    @observable isSheetsLoading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @asyncAction
    @action.bound
    async loadApprovalSheets(requestId: string): Promise<void> {
        this.setSheetsLoading(true);
        try {
            const response: AxiosResponse<ApprovalSheetDTO[] | undefined> = await this.api.client(
                apiConfigs.loadRequestApprovalSheets(requestId),
            );
            const listsOrUndefined = response.data;
            if (listsOrUndefined) {
                this.setApprovalSheets(listsOrUndefined);
            }
            this.setSheetsLoading(false);
        } catch (e) {
            this.setSheetsLoading(false);
        }
    }

    @action.bound
    setApprovalSheets(sheets: ApprovalSheetDTO[]): void {
        sheets.forEach((sheet) => {
            sheet.groups = this.mapSheetGroups(sheet.groups, sheet.activeGroupId);
        });
        this.sheets = observable.array(sheets);
    }

    @computed
    get hasApprovalTab(): boolean {
        return !!this.sheets && this.sheets.length > 0;
    }

    @action.bound
    updateApprovalSheet = (sheetId: string): Promise<void> => {
        return this.api
            .client(apiConfigs.getApprovalSheet(sheetId))
            .then((r) => r.data)
            .then((updatedSheet) => {
                updatedSheet.groups = this.mapSheetGroups(updatedSheet.groups, updatedSheet.activeGroupId);
                if (this.sheets) {
                    const sheetIndex = this.sheets.findIndex((sheet) => sheet.id === sheetId);
                    if (sheetIndex !== -1) {
                        this.sheets.splice(sheetIndex, 1, updatedSheet);
                    }
                }
            });
    };

    @action.bound
    changeApprovalSheetStatus = (reloadModel: () => void) => (
        sheetId: string,
        status: ApprovalSheetStatus,
    ): Promise<void> => {
        return this.api
            .client(apiConfigs.changeApprovalSheetStatus(sheetId, status))
            .then((r) => r.data)
            .then(() => this.updateApprovalSheet(sheetId))
            .then(reloadModel);
    };

    @action.bound
    sendApprovalSheetResolution = (reloadModel: () => void) => (
        sheetId: string,
        resolution: ApprovalSheetResolution,
        participantId: string,
    ): Promise<void> => {
        return this.api
            .client(apiConfigs.sendApprovalSheetResolution(sheetId, resolution, participantId))
            .then((r) => r.data)
            .then(() => this.updateApprovalSheet(sheetId))
            .then(reloadModel);
    };

    @action.bound
    saveApprovalSheetGroups = (dto: ApprovalSheetGroupsDTO): Promise<void> => {
        const { sheetId } = dto;
        return this.api
            .client(apiConfigs.saveApprovalSheetGroups(dto))
            .then((r) => r.data)
            .then(() => this.updateApprovalSheet(sheetId));
    };

    @action.bound
    getAllUsers = (): Promise<IdTitle[]> => {
        return this.api.client(apiConfigs.getAllActiveNonBlockedUsers()).then((r) => r.data);
    };

    @action.bound
    setSheetsLoading(state: boolean) {
        this.isSheetsLoading = state;
    }

    mapSheetGroups(groups: ApprovalSheetGroupDTO[], activeGroupId = ''): ApprovalSheetGroupExtended[] {
        const activeGroup = groups.find((group) => group.id === activeGroupId);
        const activeGroupIndex = activeGroup?.index || -1;
        return groups.map((group) => {
            const isActiveGroup = group.id === activeGroupId;
            const isAfterActiveGroup = group.index >= activeGroupIndex;
            return {
                ...group,
                isActiveGroup,
                isAfterActiveGroup,
            };
        });
    }
}

export const getApprovalStore = (): any => {
    const [_ApprovalStore] = di([ApprovalStore], getApprovalStore);
    return _ApprovalStore;
};
