import React, { ReactNode } from 'react';
import { List, ListItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FileModel } from '../models/FileModel';
import { FileComponent as FileComponentInj } from './FileComponent';
import { di } from 'react-magnetic-di';

export type FileListComponentProps = {
    files: FileModel[];
    downloadFile: (file: FileModel) => Promise<void>;
    noDataText: ReactNode | string;
};

export const FileListComponent = observer(
    (props: FileListComponentProps): JSX.Element => {
        const { files, downloadFile, noDataText } = props;
        const [FileComponent] = di([FileComponentInj], FileListComponent);
        return (
            <List disablePadding>
                {files && files.length > 0
                    ? files.map((f) => {
                          return (
                              <ListItem key={f.fileId} disableGutters>
                                  <FileComponent file={f} onDownloadFile={downloadFile} />
                              </ListItem>
                          );
                      })
                    : noDataText}
            </List>
        );
    },
);
