import { AxiosRequestConfig } from 'axios';
import { LoginDTO } from './models/LoginModel';
import { NewPasswordDTO } from './models/NewPasswordModel';
import { EmailDTO } from './models/RecoveryPasswordModel';
import { ConfirmEmailDTO } from './models/RegistrationConfirmModel';
import { RegistrationDTO } from './models/RegistrationModel';
import { TableQueryData } from './models/list/TableModel';
import { AuthorizationCheckQuery } from './store/AuthorizationStore';
import { FullSubmission } from '@platform/formiojs-react';
import { UserUpdateBody } from './store/UserStore';
import { CampaignRequestListFilterData } from './models/list/CampaignRequestListModel';
import { Preset, TTableRow, TTableQueryData } from '@platform/ttable';
import { RequestRow } from './pages/campaign-request/campaign-request-list-page/CampaignRequestListPageNew';
import { NotificationsQueryData } from '@platform/notification-widget';
import { ExecutorSettings, RequestFormTabSettingsDTO } from './pages';
import { ApprovalSheetGroupsDTO, ApprovalSheetResolution, ApprovalSheetStatus } from '@platform/approval-ui';

class ApiConfigs {
    login: (data: LoginDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/login',
            method: 'PUT',
            data,
        };
    };

    esiaAuthUrl: AxiosRequestConfig = {
        url: '/authUrl/esia',
        method: 'GET',
    };

    esiaLogin: (search: string) => AxiosRequestConfig = (search) => {
        return {
            url: `/login/esia${search}`,
            method: 'PUT',
        };
    };

    samlAuthUrl: (provider: string, backUrl: string) => AxiosRequestConfig = (provider, backUrl) => {
        const backUrlParam = backUrl ? `?backUrl=${backUrl}` : '';
        return {
            url: `/saml/${provider}/loginUrl${backUrlParam}`,
            method: 'GET',
        };
    };

    logout: AxiosRequestConfig = {
        url: '/logout',
        method: 'PUT',
    };

    resetPassword: (data: NewPasswordDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/reset-password',
            method: 'PUT',
            data,
        };
    };

    forgotPassword: (data: EmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/forgot-password',
            method: 'PUT',
            data,
        };
    };

    confirmEmail: (data: ConfirmEmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/confirm-email',
            method: 'PUT',
            data,
        };
    };

    register: (data: RegistrationDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/register',
            method: 'PUT',
            data,
        };
    };

    authorizationCheck: (queries: Readonly<AuthorizationCheckQuery[]>) => AxiosRequestConfig = (queries) => {
        return {
            url: '/check',
            method: 'PUT',
            data: queries,
        };
    };

    person: AxiosRequestConfig = {
        url: '/person',
        method: 'GET',
    };

    setLang: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: `/person?lang=${lang}`,
            method: 'PUT',
        };
    };

    userList: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/administration/users',
            method: 'PUT',
            data,
        };
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: `roles`,
        method: 'GET',
    };

    saveUserHead: (id: string, body: UserUpdateBody) => AxiosRequestConfig = (id, body) => {
        return {
            url: `/administration/user/${id}/update`,
            method: 'PUT',
            data: body,
        };
    };

    blockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/block`,
            method: 'PUT',
        };
    };

    unblockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/unblock`,
            method: 'PUT',
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    createCampaign: (lifecycleCode: string) => AxiosRequestConfig = (lifecycleCode) => {
        return {
            url: '/campaign',
            method: 'POST',
            data: {
                lifecycleCode,
            },
        };
    };

    campaigns: AxiosRequestConfig = {
        url: '/campaigns',
        method: 'GET',
    };

    campaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'GET',
        };
    };

    editCampaign: (id: string, data: FullSubmission) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/campaign/${id}`,
            method: 'PUT',
            data,
        };
    };

    campaignForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}/form`,
            method: 'GET',
        };
    };

    campaignFileUrl = (id: string): string => `/campaign/file/${id}`;

    campaignFile: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: this.campaignFileUrl(id),
            method: 'GET',
            responseType: 'blob',
        };
    };

    createRegForm: (campaignId: string) => AxiosRequestConfig & { params?: { campaign: string } } = (campaignId) => {
        return {
            url: '/regForm',
            method: 'POST',
            params: {
                campaign: campaignId,
            },
        };
    };

    editRegForm: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/regForm/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    regFormCard: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}`,
            method: 'GET',
        };
    };

    regForms: (campaignId: string, minimal: boolean, edit?: boolean) => AxiosRequestConfig = (
        campaignId,
        minimal = false,
        edit,
    ) => {
        return {
            url: '/regForms',
            method: 'GET',
            params: {
                campaign: campaignId,
                minimal: minimal,
                edit: edit,
            },
        };
    };

    regFormsByCampaignsForSelect: (campaigns: string[]) => AxiosRequestConfig = (campaigns) => {
        const campaignsQuery = campaigns.map((c) => `campaign=${c}`).join('&');
        return {
            url: `/select/regForms/grouped?${campaignsQuery}`,
            method: 'GET',
        };
    };

    changeRegFormsPosition: (regFormId: string, newPosition: number) => AxiosRequestConfig = (
        regFormId,
        newPosition,
    ) => {
        return {
            url: `/regForm/${regFormId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    regForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/form`,
            method: 'GET',
        };
    };

    deleteRegForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}`,
            method: 'DELETE',
        };
    };

    regFormFile: (fileId: string) => AxiosRequestConfig = (fileId) => {
        return {
            url: `/regForm/file/${fileId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    templates: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `/regForm/${regFormId}/templates`,
            method: 'GET',
        };
    };

    template: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'GET',
        };
    };

    createTemplate: (regFormId: string, formData: FormData) => AxiosRequestConfig & { params?: { regForm: string } } = (
        regFormId,
        formData,
    ) => {
        return {
            url: `/template`,
            method: 'POST',
            params: {
                regForm: regFormId,
            },
            data: formData,
        };
    };

    saveTemplate: (templateId: string, formData: FormData) => AxiosRequestConfig = (templateId, formData) => {
        return {
            url: `/template/${templateId}`,
            method: 'PUT',
            data: formData,
        };
    };

    deleteTemplate: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}`,
            method: 'DELETE',
        };
    };

    changeTemplatePosition: (templateId: string, newPosition: number) => AxiosRequestConfig = (
        templateId,
        newPosition,
    ) => {
        return {
            url: `/template/${templateId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    downloadTemplateFile: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/file`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    createRequest: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `/request?regForm=${regFormId}`,
            method: 'POST',
        };
    };

    getRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}`,
            method: 'GET',
        };
    };

    getDeadlines: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/deadline`,
            method: 'GET',
        };
    };

    updatePreScores: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/preScores`,
            method: 'PUT',
            data: submission,
        };
    };

    updatePreExpertise: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/preExpertise`,
            method: 'PUT',
            data: submission,
        };
    };

    updateExpertise: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/expertise`,
            method: 'PUT',
            data: submission,
        };
    };

    deleteRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}`,
            method: 'DELETE',
        };
    };

    loadRequestApprovalSheets = (requestId: string): AxiosRequestConfig => ({
        url: `/request/${requestId}/approval-sheets`,
        method: 'GET',
    });

    getApprovalSheet = (sheetId: string): AxiosRequestConfig => ({
        url: `/approval-sheet/${sheetId}`,
        method: 'GET',
    });

    changeApprovalSheetStatus = (sheetId: string, status: ApprovalSheetStatus): AxiosRequestConfig => ({
        url: `/approval-sheet/${sheetId}/manage`,
        method: 'POST',
        data: {
            decision: status,
        },
    });

    sendApprovalSheetResolution = (
        sheetId: string,
        resolution: ApprovalSheetResolution,
        participantId: string,
    ): AxiosRequestConfig => ({
        url: `/approval-sheet/${sheetId}/sendResolution`,
        method: 'POST',
        data: {
            participantId,
            resolution,
        },
    });

    saveApprovalSheetGroups = (dto: ApprovalSheetGroupsDTO): AxiosRequestConfig => ({
        url: `/approval-sheet/${dto.sheetId}/editGroups`,
        method: 'POST',
        data: dto.groups,
    });

    getAllActiveNonBlockedUsers = (): AxiosRequestConfig => ({
        url: `/select/users/v2`,
        method: 'GET',
        params: {
            blocked: false,
            emailConfirmed: true,
        },
    });

    requestList: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/requests',
            method: 'PUT',
            data: queryData,
        };
    };

    registry: (registryCode: string, queryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => {
        return {
            url: '/registry/table',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    registryColumns: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: '/registry/columns',
            method: 'GET',
            params: {
                code: registryCode,
            },
        };
    };

    requestListFilterData: (data: CampaignRequestListFilterData) => AxiosRequestConfig = (data) => {
        return {
            url: '/requests/filters',
            method: 'PUT',
            data,
        };
    };

    requestListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/requestsXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    transitionRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/transitions`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStep: (transitionId: string, requestId: string) => AxiosRequestConfig = (
        transitionId,
        requestId,
    ) => {
        return {
            url: `/request/${requestId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    personsListFilterData: AxiosRequestConfig = {
        url: '/persons?filter',
        method: 'GET',
    };

    requestPrintForms: (formId: string) => AxiosRequestConfig = (formId) => {
        return {
            url: `/request-form/${formId}/templates`,
            method: 'GET',
        };
    };

    getPrintForm: (templateId: string, formId: string) => AxiosRequestConfig = (templateId, formId) => {
        return {
            url: `/pf-template/${templateId}/form/${formId}`,
            method: 'GET',
        };
    };

    generatePrintForm: (formId: string, templateId: string, submission?: FullSubmission) => AxiosRequestConfig = (
        formId,
        templateId,
        submission,
    ) => {
        return {
            method: 'POST',
            url: '/pf',
            params: {
                template: templateId,
                customForm: formId,
            },
            data: submission,
        };
    };

    downloadPrintFormFile: (pfId: string) => AxiosRequestConfig = (pfId) => {
        return {
            url: `/pf/${pfId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    downloadAllPrintFormFiles: (formId: string) => AxiosRequestConfig = (formId) => {
        return {
            url: `/pf/${formId}/all`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getActualCampaignList: AxiosRequestConfig = {
        url: `/campaigns/active`,
        method: 'GET',
    };

    getLifecycleList: AxiosRequestConfig = {
        url: `/select/lifecycles?processCode=CampaignProcess`,
        method: 'GET',
    };

    catalogItems: (catalogCode: string, lang: string) => AxiosRequestConfig = (catalogCode, lang) => {
        return {
            url: `/catalogItems`,
            method: 'GET',
            params: {
                catalogCode,
                lang,
            },
        };
    };

    deleteCampaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'DELETE',
        };
    };

    transitionCampaign: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/transitions`,
            method: 'GET',
        };
    };

    getFormForGeneralSettings: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/general-settings-form`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStepCampaign: (transitionId: string, campaignId: string) => AxiosRequestConfig = (
        transitionId,
        campaignId,
    ) => {
        return {
            url: `/campaign/${campaignId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    saveFormForGeneralSettings: (id: string, submission: FullSubmission) => AxiosRequestConfig = (
        campaignId,
        submission,
    ) => {
        return {
            url: `/campaign/${campaignId}/general-settings-form`,
            method: 'PUT',
            data: submission,
        };
    };

    loginAnonymously: AxiosRequestConfig = {
        url: `/login-anonymous`,
        method: 'PUT',
    };

    //<editor-fold desc="Конфиги для настроек форм заявки">

    requestFormSettings: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: '/request-form-settings',
            method: 'GET',
            params: {
                regForm: regFormId,
            },
        };
    };

    createRequestFormSetting: (
        regFormId: string,
        dto: RequestFormTabSettingsDTO,
    ) => AxiosRequestConfig & { params?: { regForm: string } } = (regFormId, dto) => {
        return {
            url: '/request-form-setting',
            method: 'POST',
            params: {
                regForm: regFormId,
            },
            data: dto,
        };
    };

    loadRequestFormSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/request-form-setting/${id}`,
            method: 'GET',
        };
    };

    saveRequestFormSetting: (id: string, dto: RequestFormTabSettingsDTO) => AxiosRequestConfig = (id, dto) => {
        return {
            url: `/request-form-setting/${id}`,
            method: 'PUT',
            data: dto,
        };
    };

    deleteRequestFormSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/request-form-setting/${id}`,
            method: 'DELETE',
        };
    };

    changePositionRequestFormSetting: (id: string, position: number) => AxiosRequestConfig = (id, position) => {
        return {
            url: `/request-form-setting/${id}/position/${position}`,
            method: 'PUT',
        };
    };

    loadExecutors: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users/v2?role=Employee&role=SeniorEmployee`,
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    loadStates: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/states`,
            method: 'GET',
        };
    };

    loadPermissions: () => AxiosRequestConfig = () => {
        return {
            url: `/select/permissions?entityCode=RequestCustomForm`,
            method: 'GET',
        };
    };

    loadFormCode: () => AxiosRequestConfig = () => {
        return {
            url: `/select/forms/search?code=RequestCustomForm_`,
            method: 'GET',
        };
    };
    //<editor-fold/>

    //<editor-fold desc="Кастомные формы заявки">
    loadRequestForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/request-form/${id}`,
            method: 'GET',
        };
    };

    saveRequestForm: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/request-form/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    requestFormCheckEditable: (requestFormId: string) => AxiosRequestConfig = (requestFormId) => {
        return {
            url: `/request-form/${requestFormId}/checkEditable`,
            method: 'GET',
        };
    };
    //<editor-fold/>

    fetchExecutorList: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `/regForm/${regFormId}/executors`,
            method: 'GET',
        };
    };

    deleteExecutor: (regFormId: string, settingsExecId: string) => AxiosRequestConfig = (regFormId, settingsExecId) => {
        return {
            url: `/regForm/${regFormId}/executor/${settingsExecId}`,
            method: 'DELETE',
        };
    };

    addExecutor: (regFormId: string, settings: ExecutorSettings) => AxiosRequestConfig = (regFormId, settings) => {
        return {
            method: 'POST',
            url: `/regForm/${regFormId}/executor`,
            data: settings,
        };
    };

    fetchUsers: () => AxiosRequestConfig = () => {
        return {
            url: '/persons',
            method: 'GET',
        };
    };

    fetchExecutorsSettings: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `regForm/${regFormId}/executors-settings`,
            method: 'GET',
        };
    };

    getAllActiveEmployees: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Employee&role=SeniorEmployee`,
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllEmployees: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Employee&role=SeniorEmployee`,
            method: 'GET',
        };
    };

    setCurrentUserAsExecutor: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/executor`,
            method: 'GET',
        };
    };

    getExecutorSettings: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/executorSetting`,
            method: 'GET',
        };
    };

    editExecutorSettings: (requestId: string, userId: string) => AxiosRequestConfig = (requestId, userId) => {
        return {
            url: `/request/${requestId}/executorSetting`,
            method: 'PUT',
            data: {
                userId,
            },
        };
    };

    //<editor-fold desc="Пресеты">
    listPreset: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: `presets`,
            method: 'GET',
            params: {
                registryCode,
            },
        };
    };

    savePreset: <RowType extends TTableRow>(preset: Preset<RowType>) => AxiosRequestConfig = (preset) => {
        return {
            url: `preset`,
            method: `PUT`,
            data: preset,
        };
    };

    deletePreset: (presetId: string) => AxiosRequestConfig = (presetId) => {
        return {
            url: `preset/${presetId}`,
            method: 'DELETE',
        };
    };

    saveListPresets = <RowType extends TTableRow>(presets: Preset<RowType>[]): AxiosRequestConfig => ({
        url: '/presets',
        method: 'PUT',
        data: presets,
    });

    getLastPreset = (registryCode: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'GET',
        params: {
            registryCode,
        },
    });

    saveLastPreset = (registryCode: string, presetId: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'PUT',
        params: {
            registryCode,
            presetId,
        },
    });

    //<editor-fold/>

    //<editor-fold desc="Уведомления">
    loadNotifications = (queryData: NotificationsQueryData): AxiosRequestConfig => {
        return {
            url: `/notifications/lk`,
            method: 'PUT',
            data: queryData,
        };
    };

    loadCountNotViewedMessages = (): AxiosRequestConfig => ({
        url: '/notifications/lk/countUnviewedMessage',
        method: 'GET',
    });

    changeViewed = (id: string, viewed: boolean): AxiosRequestConfig => {
        return {
            url: `/notification/lk/changeViewed`,
            method: 'PUT',
            params: {
                deliveredNotificationId: id,
                viewed,
            },
        };
    };

    deleteNotification = (id: string): AxiosRequestConfig => {
        return {
            url: `/notification/lk`,
            method: 'DELETE',
            params: {
                deliveredNotificationId: id,
            },
        };
    };
    //<editor-fold/>

    loadModulesList = (): AxiosRequestConfig => ({
        url: '/getRoutes',
        method: 'GET',
    });

    upload: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    loadTemplatePermissions = (settingsId: string): AxiosRequestConfig => {
        return {
            url: `/settings/${settingsId}/templatePermissions`,
            method: 'GET',
        };
    };

    savedTemplatePermissions = (settingsId: string, permissions: any[]): AxiosRequestConfig => {
        return {
            url: `/settings/${settingsId}/templatePermissions`,
            method: 'PUT',
            data: permissions,
        };
    };

    checkEditablePf = (customFormId: string): AxiosRequestConfig => {
        return {
            url: `/request-form/${customFormId}/checkEditablePf`,
            method: 'GET',
        };
    };

    checkIsPasswordRecoveryTokenValid = (token: string): AxiosRequestConfig => {
        return {
            url: `/checkPasswordResetToken?token=${token}`,
            method: 'GET',
        };
    };

    getHashSum = (printFormId: string, oidAlgorithm: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/digest`,
            method: 'GET',
            params: {
                algOID: oidAlgorithm,
            },
        };
    };

    uploadSignature = (printFormId: string, signature: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadSignatureFile = (printFormId: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/upload-sign`,
            method: 'POST',
            data: signatureFile,
        };
    };

    getSignatureInfo = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign-info`,
            method: 'GET',
        };
    };

    downloadSignature = (printFormId: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteSignature = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'DELETE',
        };
    };

    getFormioHashSum = (url: string, oidAlgorithm: string): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
            params: {
                algOID: oidAlgorithm,
            },
        };
    };

    uploadFormioSignature = (url: string, signature: string): AxiosRequestConfig => {
        return {
            url,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadFormioSignatureFile = (url: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url,
            method: 'POST',
            data: signatureFile,
        };
    };

    getFormioSignatureInfo = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
        };
    };

    downloadFormioSignature = (url: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteFormioSignature = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'DELETE',
        };
    };
}

export const apiConfigs = new ApiConfigs();
