import React, { useState } from 'react';
import { StyledCircularProgress as StyledCircularProgressInj } from '../pages';
import { di } from 'react-magnetic-di';
import { App } from '../App';

export type UseAntiDoubleClickType = [boolean, boolean | JSX.Element, () => void];

export function useAntiDoubleClick(actionFunc: () => Promise<void>): UseAntiDoubleClickType {
    const [StyledCircularProgress] = di([StyledCircularProgressInj], App);

    const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
    const endIcon = isActionInProgress && <StyledCircularProgress />;

    const actionHandler = () => {
        setIsActionInProgress(true);

        actionFunc().catch(() => {
            setIsActionInProgress(false);
        });
    };

    return [isActionInProgress, endIcon, actionHandler];
}
