import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { IdTitle } from '../../../models/IdTitle';
import { Autocomplete } from '@material-ui/lab';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../../components/renderAutoCompleteInput';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { di } from 'react-magnetic-di';

export type CampaignRequestCreateDialogProps = {
    onClose: () => void;
};

export const CampaignRequestCreateDialog = observer(
    (props: CampaignRequestCreateDialogProps): JSX.Element => {
        const { onClose } = props;

        const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], CampaignRequestCreateDialog);

        const [activeCampaigns, setActiveCampaigns] = useState<IdTitle[]>([]);
        const [isLoadingActiveCampaigns, setIsLoadingActiveCampaigns] = useState<boolean>(true);
        const [activeCampaign, setActiveCampaign] = useState<string>('');
        const [validationStarted, setValidationStarted] = useState<boolean>(false);

        const { campaignsStore, intlStore: intl } = useStore();
        const { createRequest } = campaignsStore;

        useEffect(() => {
            campaignsStore.getActualCampaignList().then((r) => {
                setIsLoadingActiveCampaigns(false);
                setActiveCampaigns(r);
            });
        }, [campaignsStore]);

        const error = useMemo(() => {
            if (validationStarted && !activeCampaign) {
                return intl.formatMessage('validation.required');
            }
            return '';
        }, [activeCampaign, intl, validationStarted]);

        const onChangeCategory = (e: ChangeEvent<{}>, value: IdTitle | null): void => {
            setValidationStarted(true);
            setActiveCampaign(value ? value.id : '');
        };

        return (
            <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="campaignRequest.newRequest" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <Grid container spacing={6} direction="column" justify="center">
                            <Grid item>
                                <Autocomplete
                                    onChange={onChangeCategory}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.title}
                                    options={activeCampaigns}
                                    loading={isLoadingActiveCampaigns}
                                    renderInput={renderAutoCompleteInput(
                                        intl.formatMessage('campaign.titleCampaignListPage'),
                                        true,
                                        error,
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Button
                                            disabled={!activeCampaign}
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            onClick={() => createRequest(activeCampaign)}
                                        >
                                            <FormattedMessage id="campaign.createRequest" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={onClose} fullWidth size="large" variant="contained">
                                            <FormattedMessage id="common.cancel" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);
