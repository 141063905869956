import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { CampaignRequestRowNewDTO } from '../../../store';
import { createStyles, Link, makeStyles } from '@material-ui/core';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { Preset, SelectData, TTable, TTableFilterProps, TTableRow, TTableSelectForFilter } from '@platform/ttable';
import { RequestRow } from './CampaignRequestListPageNew';
import { useStore } from '../../../hooks';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { di } from 'react-magnetic-di';

export type CampaignRequestTableProps = {
    setRowActions: (row: CampaignRequestRowNewDTO, reloadData: () => void) => JSX.Element;
};

export const useCampaignRequestTableStylesInj = makeStyles(() =>
    createStyles({
        link: {
            display: 'inline',
        },
    }),
);

export const CampaignRequestTable = observer(
    (props: CampaignRequestTableProps): JSX.Element => {
        const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignRequestTable);
        const [useCampaignRequestTableStyles] = di([useCampaignRequestTableStylesInj], CampaignRequestTable);

        const classes = useCampaignRequestTableStyles();

        const { setRowActions } = props;
        const { requestStore, presetStore, intlStore, catalogStore, userStore } = useStore();

        const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
            presetStore.saveListPresets(settings);

        return (
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<RequestRow & CampaignRequestRowNewDTO>
                        registryCode="ics-requests"
                        fetchData={requestStore.registry}
                        fetchColumns={requestStore.registryColumns}
                        presetsSetting={presetStore}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        upload={requestStore.upload}
                        lang={intlStore.locale}
                        cellFormatters={{
                            number: function number(p): JSX.Element {
                                return (
                                    <Link
                                        className={classes.link}
                                        component={NavLink}
                                        underline="always"
                                        to={generatePath(clientRoute.request, { id: p.row.original.id })}
                                    >
                                        {p.row.original.number || (
                                            <FormattedMessage id="campaignRequest.withoutNumber" />
                                        )}
                                    </Link>
                                );
                            },
                            'campaign.title': function campaignTitle(p): JSX.Element {
                                return (
                                    <Link
                                        className={classes.link}
                                        component={NavLink}
                                        underline="always"
                                        to={generatePath(clientRoute.campaign, {
                                            id: p.row.original.campaign.id,
                                        })}
                                    >
                                        {p.row.original.campaign.title}
                                    </Link>
                                );
                            },
                        }}
                        customFilters={{ 'regForm.title': RegFormFilter }}
                        saveListPresets={saveListPresets}
                        fetchUserRoleList={userStore.userRoleList}
                        rowActions={setRowActions}
                        isAdmin={allowed}
                    />
                )}
            </AuthorizationCheck>
        );
    },
);

const RegFormFilter = <RowType extends TTableRow>(props: TTableFilterProps<RowType>): JSX.Element => {
    const { column, flatHeaders } = props;
    const { filterValue, setFilter, lang, columnType, filterField } = column;
    const [selectData, setSelectData] = useState<SelectData>([]);
    const filterType = 'select';

    const { campaignsStore } = useStore();

    // Достаём значение фильтра по категории
    const campaignFilterValue = useMemo(() => flatHeaders.find((_) => _.id === 'campaign.title')?.filterValue?.value, [
        flatHeaders,
    ]);

    useEffect(() => {
        campaignsStore.regFormsByCampaignsForSelect(campaignFilterValue || []).then((data) => setSelectData(data));
    }, [lang, setSelectData, campaignFilterValue]);

    const handleChange = useCallback(
        (e: ChangeEvent<{}>, value: string[]) => {
            let newValue;
            if (value.length !== 0) {
                newValue = filterField
                    ? { filterType, columnType, filterField, value: value }
                    : { filterType, columnType, value: value };
            }
            setFilter(newValue);
        },
        [setFilter],
    );

    return (
        <TTableSelectForFilter
            selectData={selectData}
            onChange={handleChange}
            values={!filterValue?.value ? [] : filterValue.value}
            label={column.shortHeader}
        />
    );
};
