export default {
    startPageLinks: true,
    startPage: true,
    loginWithoutRegister: true,
    customLogo: false,
    customBackground: false,
    customSidebarIcon: false,
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    startPageDefaultText: true,
    esiaLogin: true,
    showCreateRequestButton: true,
    showRequestsLamp: true,
    loginDialogClose: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    stickyHeader: false,
    samlAuth: {
        isEnabled: true,
        providers: [
            {
                code: 'onelogin',
                title: 'OneLogin',
            },
        ],
    },
    showCampaignPeriod: true,
};
