import React from 'react';
import { Link, MenuItem, MenuItemProps } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

export type ActionMenuItemProps = {
    path?: string;
    messageId: string;
} & Omit<MenuItemProps, 'button'>;

export const ActionMenuItem = (props: ActionMenuItemProps): JSX.Element => {
    const { path, messageId, ...rest } = props;

    return (
        <MenuItem dense button {...rest}>
            {path ? (
                <Link component={NavLink} underline="none" to={path} style={{ color: 'inherit' }}>
                    <FormattedMessage id={messageId} />
                </Link>
            ) : (
                <FormattedMessage id={messageId} />
            )}
        </MenuItem>
    );
};
