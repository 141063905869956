import { ThemeOptions } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { ThemeOverrides } from '../../types/themeTypes';
import sidebar from './vars/sidebar';
import button from './vars/button';
import requestPanel from './vars/requestPanel';
import checkbox from './vars/formio/checkbox';
import checkRadioBox from './vars/formio/checkRadioBox';
import formControl from './vars/formio/formControl';
import select from './vars/formio/select';
import { spacing } from './vars/spacing';
import { transitionDefault } from './vars/transitions';
import input, { inputHeight } from './vars/formio/input';
import requestPrintForms from './vars/requestPrintForms';
import formioDateTime from './vars/formio/formioDateTime';
import footer from './vars/footer';

const createAppThemeOptions = (overrides: ThemeOverrides): ThemeOptions => {
    const { palette, fonts, icons } = overrides;

    return {
        breakpoints: createBreakpoints({
            keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
            values: {
                xs: 0,
                sm: 640,
                md: 960,
                lg: 1280,
                xl: 1536,
                xxl: 1920,
            },
        }),
        palette: {
            primary: {
                light: palette.mainLight,
                main: palette.main,
                dark: palette.mainDark,
                contrastText: palette.mainContrast,
            },
            secondary: {
                main: palette.accent,
                dark: palette.accentDark,
                contrastText: palette.accentContrast,
            },
            action: {
                hover: palette.hover,
            },
        },
        typography: {
            fontFamily: fonts.mainFont,
            fontSize: 13,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 600,
            fontWeightBold: 700,
        },
        shadows: [
            'none',
            `0px 6px 32px 0 rgba(0, 0, 0, 0.09)`,
            `0px 3px 1px -2px ${palette.textDark}33,0px 2px 2px 0px ${palette.textDark}24,0px 1px 5px 0px ${palette.textDark}1f`,
            `0px 3px 3px -2px ${palette.textDark}33,0px 3px 4px 0px ${palette.textDark}24,0px 1px 8px 0px ${palette.textDark}1f`,
            `0px 2px 4px -1px ${palette.textDark}33,0px 4px 5px 0px ${palette.textDark}24,0px 1px 10px 0px ${palette.textDark}1f`,
            `0px 3px 5px -1px ${palette.textDark}33,0px 5px 8px 0px ${palette.textDark}24,0px 1px 14px 0px ${palette.textDark}1f`,
            `0px 3px 5px -1px ${palette.textDark}33,0px 6px 10px 0px ${palette.textDark}24,0px 1px 18px 0px ${palette.textDark}1f`,
            `0px 4px 5px -2px ${palette.textDark}33,0px 7px 10px 1px ${palette.textDark}24,0px 2px 16px 1px ${palette.textDark}1f`,
            `0px 5px 5px -3px ${palette.textDark}33,0px 8px 10px 1px ${palette.textDark}24,0px 3px 14px 2px ${palette.textDark}1f`,
            `0px 5px 6px -3px ${palette.textDark}33,0px 9px 12px 1px ${palette.textDark}24,0px 3px 16px 2px ${palette.textDark}1f`,
            `0px 6px 6px -3px ${palette.textDark}33,0px 10px 14px 1px ${palette.textDark}24,0px 4px 18px 3px ${palette.textDark}1f`,
            `0px 6px 7px -4px ${palette.textDark}33,0px 11px 15px 1px ${palette.textDark}24,0px 4px 20px 3px ${palette.textDark}1f`,
            `0px 7px 8px -4px ${palette.textDark}33,0px 12px 17px 2px ${palette.textDark}24,0px 5px 22px 4px ${palette.textDark}1f`,
            `0px 7px 8px -4px ${palette.textDark}33,0px 13px 19px 2px ${palette.textDark}24,0px 5px 24px 4px ${palette.textDark}1f`,
            `0px 7px 9px -4px ${palette.textDark}33,0px 14px 21px 2px ${palette.textDark}24,0px 5px 26px 4px ${palette.textDark}1f`,
            `0px 8px 9px -5px ${palette.textDark}33,0px 15px 22px 2px ${palette.textDark}24,0px 6px 28px 5px ${palette.textDark}1f`,
            `0px 8px 10px -5px ${palette.textDark}33,0px 16px 24px 2px ${palette.textDark}24,0px 6px 30px 5px ${palette.textDark}1f`,
            `0px 8px 11px -5px ${palette.textDark}33,0px 17px 26px 2px ${palette.textDark}24,0px 6px 32px 5px ${palette.textDark}1f`,
            `0px 9px 11px -5px ${palette.textDark}33,0px 18px 28px 2px ${palette.textDark}24,0px 7px 34px 6px ${palette.textDark}1f`,
            `0px 9px 12px -6px ${palette.textDark}33,0px 19px 29px 2px ${palette.textDark}24,0px 7px 36px 6px ${palette.textDark}1f`,
            `0px 10px 13px -6px ${palette.textDark}33,0px 20px 31px 3px ${palette.textDark}24,0px 8px 38px 7px ${palette.textDark}1f`,
            `0px 10px 13px -6px ${palette.textDark}33,0px 21px 33px 3px ${palette.textDark}24,0px 8px 40px 7px ${palette.textDark}1f`,
            `0px 10px 14px -6px ${palette.textDark}33,0px 22px 35px 3px ${palette.textDark}24,0px 8px 42px 7px ${palette.textDark}1f`,
            `0px 11px 14px -7px ${palette.textDark}33,0px 23px 36px 3px ${palette.textDark}24,0px 9px 44px 8px ${palette.textDark}1f`,
            `0px 11px 15px -7px ${palette.textDark}33,0px 24px 38px 3px ${palette.textDark}24,0px 9px 46px 8px ${palette.textDark}1f`,
        ],
        overrides: {
            MuiAppBar: {
                colorDefault: {
                    backgroundColor: palette.appBar.mainContrast,
                    color: palette.mainLight,
                },
            },
            MuiButton: {
                root: {
                    fontSize: '13px',
                    fontWeight: 'bold',
                    lineHeight: '1.25',
                    letterSpacing: '0.2px',
                    height: `${button.sizes.medium}px`,
                    padding: '0 10px',
                    transition: `background-color ${transitionDefault},
                                 box-shadow ${transitionDefault},
                                 border ${transitionDefault},
                                 color ${transitionDefault}`,
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    color: palette.textDark,

                    '&:hover': {
                        boxShadow: 'none !important',
                        backgroundColor: palette.main,
                        color: palette.mainContrast,
                    },

                    '&.Mui-disabled': {
                        background: palette.button.disabled,
                    },
                },
                containedPrimary: {
                    boxShadow: 'none',
                    background: palette.accentBackgroundGradient,
                    color: palette.button.accentContrast,
                    '& [class^=MuiButton-startIcon]': {
                        color: palette.textDark,
                    },

                    '&.Mui-disabled [class^=MuiButton-startIcon]': {
                        color: 'rgba(0, 0, 0, 0.26)',
                    },

                    '&:hover': {
                        boxShadow: 'none',
                        background: 'none',
                        backgroundColor: palette.main,
                        color: palette.mainContrast,
                        '& [class^=MuiButton-startIcon]': {
                            color: palette.mainContrast,
                        },
                        '&:focus': {
                            color: palette.mainContrast,
                        },
                    },
                    '&:focus': {
                        color: palette.accentContrastBackgroundGradient,
                    },
                },
                containedSecondary: {
                    boxShadow: 'none',
                    backgroundColor: palette.main,
                    color: palette.mainContrast,
                    '& [class^=MuiButton-startIcon]': {
                        color: palette.mainContrast,
                    },

                    '&:hover': {
                        boxShadow: 'none',
                        background: `${palette.accentBackgroundGradient}`,
                        color: palette.button.accentContrast,

                        '& [class^=MuiButton-startIcon]': {
                            color: palette.button.accentContrast,
                        },
                    },
                },
                containedSizeLarge: {
                    height: `${button.sizes.large}px`,
                    padding: '0 44px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '1.14',
                    letterSpacing: '0.84px',
                },
                containedSizeSmall: {
                    padding: '6px 16px',
                },
                text: {
                    lineHeight: 1.25, //1.85
                    letterSpacing: '1.04px',
                    padding: '8px 10px',

                    '&:hover': {
                        background: `${palette.accentBackgroundGradient}`,
                        color: palette.accentContrastBackgroundGradient,
                    },
                },
                textPrimary: {
                    color: palette.main,
                    '& [class^=MuiButton-startIcon]': {
                        color: palette.main,
                    },
                    '&:hover': {
                        background: palette.accentBackgroundGradient,
                        color: palette.button.accentContrast,
                    },
                    '&:hover [class^=MuiButton-startIcon]': {
                        color: palette.button.accentContrast,
                    },
                },
                textSecondary: {
                    color: palette.mainLight,

                    '&:hover': {
                        color: palette.hover,
                        background: 'transparent',
                        backgroundColor: 'transparent',
                    },
                    '&:hover [class^=MuiButton-startIcon]': {
                        color: palette.hover,
                    },

                    '&.active': {
                        color: palette.hover,
                    },
                    '&.active [class^=MuiButton-startIcon]': {
                        color: palette.hover,
                    },
                },
                textSizeSmall: {
                    padding: '6px 13px',
                    fontSize: 'inherit',
                },
                textSizeLarge: {
                    height: '48px',
                    padding: '6px 13px',
                    fontSize: 'inherit',
                },
                startIcon: {
                    marginLeft: 0,
                    color: palette.mainLight,
                    transition: `color ${transitionDefault}`,

                    '&:hover': {
                        color: palette.textDark,
                    },
                },
                label: {
                    whiteSpace: 'nowrap',
                },
            },
            MuiRadio: {
                root: {
                    padding: 0,
                },
                colorSecondary: {
                    '&.Mui-checked': {
                        color: palette.main,
                    },
                },
            },
            MuiIconButton: {
                root: {
                    width: `${button.sizes.medium}px`,
                    height: `${button.sizes.medium}px`,
                    padding: 0,

                    color: palette.main,

                    '&.active': {
                        color: palette.textDark,
                    },

                    '&:hover': {
                        backgroundColor: palette.main,
                        color: palette.mainContrast,
                    },

                    '&.active:hover': {
                        color: palette.mainContrast,
                    },

                    '@media (hover: none)': {
                        '&:hover': {
                            backgroundColor: `${palette.main} !important`,
                        },
                    },
                },

                sizeSmall: {
                    width: `${button.sizes.small}px`,
                    height: `${button.sizes.small}px`,
                    padding: 0,
                },

                colorPrimary: {
                    color: palette.main,

                    '&:hover': {
                        background: `${palette.accentBackgroundGradient}`,
                        color: palette.accentContrast,
                    },
                },

                colorSecondary: {
                    color: `${palette.mainLight} !important`,

                    '&:hover': {
                        color: `${palette.hover} !important`,
                        backgroundColor: 'transparent !important',
                    },

                    '&.active': {
                        color: `${palette.hover} !important`,
                    },
                },
            },

            MuiLink: {
                root: {
                    ...fonts.mainFontAttrs,

                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',

                    '&:hover': {
                        color: palette.textDark,
                    },

                    '&.active': {
                        color: palette.textDark,
                    },

                    '&.MuiTypography-colorPrimary': {
                        color: palette.textDark,

                        '&:hover': {
                            color: palette.main,
                        },
                    },

                    '&.MuiTypography-colorSecondary': {
                        color: palette.main,

                        '&:hover': {
                            color: palette.hover,
                        },
                    },

                    '&.MuiTypography-colorTextPrimary': {
                        color: palette.main,

                        '&:hover': {
                            color: palette.textDark,
                        },

                        '&.active': {
                            color: palette.textDark,
                        },
                    },

                    '& .MuiSvgIcon-root': {
                        paddingRight: '12px',
                    },
                },
            },
            MuiTypography: {
                h1: {
                    fontSize: '24px',
                    fontWeight: 600,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.5,
                    letterSpacing: 'normal',
                },
                h2: {
                    fontSize: '3rem',
                    fontWeight: 600,
                },
                h3: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.56,
                    letterSpacing: '0.36px',
                },
                body1: {
                    ...fonts.mainFontAttrs,
                },
                body2: {
                    fontSize: '15px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.47,
                    letterSpacing: '0.3px',
                },
            },
            MuiInputLabel: {
                root: {
                    backgroundColor: palette.mainContrast,
                    paddingRight: `${spacing}px`,
                },
                shrink: {
                    textTransform: 'uppercase',
                    letterSpacing: '0.4px',
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: '8px',
                },
            },
            MuiCheckbox: {
                colorPrimary: {
                    '&:hover': {
                        background: 'transparent',
                    },
                },
            },
            MuiFormControlLabel: {
                root: {
                    '&:hover .MuiIconButton-label': {
                        color: palette.textDark,
                    },
                },
            },
            MuiListItem: {
                root: {
                    outline: 'none',

                    '&.Mui-selected': {
                        color: palette.textDark,
                    },

                    '&.Mui-selected:hover': {
                        backgroundColor: palette.hoverInLists,
                    },
                },
                button: {
                    color: palette.main,

                    '&:hover': {
                        color: palette.textDark,
                        backgroundColor: palette.hoverInLists,
                    },
                },
            },
            MuiTabs: {
                indicator: {
                    height: '3px',
                },
                root: {
                    '&.MuiTabs-vertical .MuiTabs-indicator': {
                        width: '3px',
                    },
                },
                flexContainer: {
                    '&.MuiTabs-flexContainerVertical .MuiTab-wrapper': {
                        alignItems: 'flex-start',
                    },
                    '&.MuiTabs-flexContainerVertical .MuiTab-root': {
                        minHeight: 'auto',
                    },
                },
            },
            MuiDialogActions: {
                spacing: {
                    padding: '16px 24px',
                },
            },
            MuiTableRow: {
                root: {
                    '&:nth-child(even)': {
                        backgroundColor: palette.table.row.even,
                    },
                    '&:nth-child(odd)': {
                        backgroundColor: palette.table.row.odd,
                    },
                    '&.MuiTableRow-head': {
                        backgroundColor: palette.mainContrast,
                    },
                    '&.MuiTableRow-footer': {
                        backgroundColor: palette.mainContrast,
                    },
                    '&.MuiTableRow-hover': {
                        '&:hover': {
                            backgroundColor: palette.hoverInLists,
                        },
                    },
                },
            },
            MuiTableCell: {
                root: {
                    padding: `${4 * spacing}px`,
                    borderBottomWidth: 0,
                    paddingTop: `${3 * spacing}px`,
                    paddingBottom: `${3 * spacing}px`,
                    paddingLeft: `${1.5 * spacing}px`,
                    paddingRight: `${1.5 * spacing}px`,
                    '&:first-child': {
                        paddingLeft: `${6 * spacing}px`,
                    },
                    '&:last-child': {
                        paddingRight: `${6 * spacing}px`,
                    },
                    verticalAlign: 'top',
                },
                body: {
                    color: palette.textMain,
                },
                head: {
                    backgroundColor: 'white',
                    color: palette.textDark,
                    fontWeight: 600,
                },
            },

            MuiTableSortLabel: {
                icon: {
                    fontSize: 'x-large',
                },
            },

            MuiTooltip: {
                tooltip: {
                    backgroundColor: palette.tooltip.backgroundColor,
                    fontSize: 12,
                },
            },
            MuiExpansionPanelSummary: {
                root: {
                    paddingLeft: `${6 * spacing}px`,
                    paddingRight: 0,
                },
                expandIcon: {
                    marginRight: `${6 * spacing}px`,
                    '&:hover': {
                        backgroundColor: palette.main,
                    },
                },
            },
            MuiExpansionPanelDetails: {
                root: {
                    paddingLeft: `${6 * spacing}px`,
                    paddingRight: `${6 * spacing}px`,
                    paddingBottom: `${6 * spacing}px`,
                },
            },
            MuiPickersToolbar: {
                toolbar: {
                    height: '80px',
                },
            },
            MuiPickersToolbarButton: {
                toolbarBtn: {
                    backgroundColor: 'transparent',

                    '&.MuiButton-text': {
                        color: palette.mainContrast,
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                        backgroundImage: 'none',
                    },
                    '& .MuiPickersToolbarText-toolbarTxt': {
                        transition: transitionDefault,
                    },
                    '& .MuiPickersToolbarText-toolbarTxt:hover': {
                        color: palette.mainContrast,
                        transition: transitionDefault,
                    },
                    '& .MuiPickersToolbarText-toolbarBtnSelected': {
                        color: palette.mainContrast,
                    },
                },
            },
            MuiAutocomplete: {
                listbox: {
                    maxHeight: '180px',
                },
                tag: {
                    borderRadius: '4px',
                    backgroundColor: palette.hoverInLists,
                    '&:hover': {
                        backgroundColor: palette.main,
                        color: palette.mainContrast,
                        '& .MuiChip-deleteIcon': {
                            color: palette.mainContrast,
                        },
                    },
                    '& .MuiChip-deleteIcon': {
                        color: palette.main,
                        flexShrink: 0,
                    },
                },
                clearIndicator: {
                    height: '24px',
                    width: '24px',
                },
                option: {
                    padding: `0 ${4 * spacing}px !important`,
                    '&[data-focus="true"]': {
                        backgroundColor: palette.hoverInLists,
                    },

                    '&[area-selected="true"]': {
                        backgroundColor: palette.hoverInLists,
                    },
                    '&:hover': {
                        backgroundColor: palette.hoverInLists,
                    },
                    '&:hover .MuiCheckbox-colorPrimary': {
                        color: palette.textDark,
                    },

                    '& .MuiCheckbox-colorPrimary.Mui-checked': {
                        color: palette.main,
                    },
                },
                popupIndicator: {
                    width: '24px',
                    height: '24px',
                },
            },
            MuiInputBase: {
                input: {
                    '&[type="password"]::-ms-reveal': {
                        display: 'none',
                    },
                },
            },
            MuiCardActions: {
                root: {
                    padding: `${4 * spacing}px ${6 * spacing}px`,
                },
            },
            MuiPaginationItem: {
                root: {
                    color: palette.main,
                    '&:hover': {
                        color: palette.textDark,
                    },
                },
                textSecondary: {
                    '&:hover': {
                        background: palette.accentBackgroundGradient,
                    },
                    '&.Mui-selected': {
                        color: palette.textDark,
                        backgroundColor: palette.campaignList.selectedPaginationButton,
                    },
                },
            },
        },
        spacing,
        variables: {
            palette,
            fonts,
            icons,
            sass: {},
            button,
            requestPanel,
            footer,
            sidebar,
            alertLink: {
                color: palette.mainDark,
                hoverColor: palette.mainLight,
            },
            requestPrintForms,
            formio: {
                input,
                checkbox,
                checkRadioBox,
                formControl,
                formioDateTime,
                select,
                dateTime: {
                    addonPadding: (inputHeight - icons.calendarIcon.width) / 2,
                },
            },
        },
    };
};

export default createAppThemeOptions;
