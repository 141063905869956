import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { BreadcrumbsSettings } from './BreadcrumbsComponent';

// Основные наименования страниц
export const names: Record<string, string> = {
    mainPage: 'breadcrumbs.mainPage',
    campaigns: 'breadcrumbs.campaigns',
    campaign: 'breadcrumbs.campaign',
    requests: 'breadcrumbs.requests',
    requestsNew: 'breadcrumbs.requestsNew',
    campaignSettings: 'breadcrumbs.campaignSettings',
    regFormSelect: 'breadcrumbs.regFormSelect',
    users: 'breadcrumbs.users',
};

// Роуты без параметров
export const mainRoutes: Record<string, string> = {
    mainPage: '/',
    campaigns: '/campaigns',
    requests: '/requests',
    requestsNew: '/requests/new',
    users: '/users',
};

type RoutesFunction = (objectId: string) => string;

// Роуты с параметрами
export const routesWithParams: Record<string, RoutesFunction> = {
    campaign: (objectId) => generatePath(clientRoute.campaign, { id: objectId }),
    request: (objectId) => generatePath(clientRoute.request, { id: objectId }),
    campaignSettings: (objectId) => generatePath(clientRoute.campaignSettings, { id: objectId }),
    regFormSelect: (objectId) => generatePath(clientRoute.regFormSelect, { campaignId: objectId }),
};

export const breadCrumbsSettings: BreadcrumbsSettings = {
    names,
    mainRoutes,
    routesWithParams,
};
