const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Категории',
    titleCreateCampaignPage: 'Создание категории',
    noCategoriesText: 'В данный момент нет активных категорий для подачи заявки',
    newCampaign: 'Новая категория',
    create: 'Создать',
    createCampaign: 'Создать категорию',
    editCampaign: 'Редактирование категории',
    sections: 'Разделы',
    description: 'Описание: ',
    files: 'Файлы',
    campaignPeriod: 'Подача заявок',
    createRequest: 'Создать заявку',
    campaignRequests: 'Заявки категории',
    campaignSettings: 'Настройка категории',
    tabsSettings: 'Настройка вкладок',
    pfTemplates: 'Шаблоны ПФ',
    noTemplates: 'Нет настроенных шаблонов',

    campaignDescription: 'Описание категории',
    campaignDescriptionEdit: 'Редактирование категории',

    campaignRegForms: 'Формы заявок',
    campaignRegForm: '{count, plural, one {Форма} other {Формы}} заявки',
    addCampaignRegFormButton: 'Добавить форму заявки',
    newCampaignRegForm: 'Новая форма заявки',
    editCampaignRegForm: 'Редактирование формы заявки "{formTitle}"',
    deleteCampaignRegForm: 'Удаление формы заявки',
    confirmDeleteCampaignRegForm: 'Вы действительно хотите удалить форму заявки "{formTitle}"?',
    noRegForms: 'Формы регистрации отсутствуют',
    regFormInfo: 'Основная информация',
    regFormExecutors: 'Ответственные исполнители',

    templatesAndExport: 'Шаблоны и выгрузки',
    campaignManagement: 'Управление категорией',
    export: 'Выгрузки',
    templates: 'Шаблоны',
    addTemplate: 'Добавить шаблон',
    editTemplate: 'Редактировать шаблон',
    editPermission: 'Настроить доступ',
    generate: 'Генерация',
    accessSetting: 'Настройка доступа',
    confirmDeletionTemplateInfoText: 'Вы действительно хотите удалить шаблон "{title}"?',
    lifecycle: 'Жизненный цикл',
    tabSettingForm: {
        title: 'Наименование вкладки',
        identifier: 'Идентификатор',
        formCode: 'Код используемой формы',
        fileSize: 'Размер пакета загружаемых документов в Мб',
        executor: 'Ответственный исполнитель',
        access: 'Доступ',
        edit: 'Редактирование',
        view: 'Просмотр',
    },
    tabSettingFormTable: {
        role: 'Роль',
        status: 'Статус ЖЦ',
        access: 'Доступ',
        allStatuses: 'Все статусы',
    },
    templateForm: {
        title: 'Название',
        description: 'Описание',
        regForms: 'Формы заявок',
        file: 'Файл шаблона',
        templateIsRequired: 'Является обязательным для генерации',
    },
    templateFormErrors: {
        notUniqueTemplateName: 'Название шаблона должно быть уникальным',
        executorsNotAllowed:
            'Данный способ назначения недоступен, так как выбран способ назначения ответственных по факту',
    },
    documentCategory: 'Категория документа',
    tabs: 'Вкладки',

    campaignManagementDelete: 'Вы действительно хотите удалить категорию "{campaignName}"?',
    deleteCampaign: 'Удалить категорию',
    campaignTitle: 'Управление категорией {campaignName}',
    generalSettings: 'Общие настройки',

    requestFormSettingsTitle: 'Настройка вкладок для заявки',
    requestFormSetting: 'Настройка вкладки',
    createRequestFormSettings: 'Создать вкладку',
    confirmDeletionRequestFormSettingInfoText: 'Вы действительно хотите удалить вкладку "{title}"?',
    requestFormSettings: {
        title: 'Название',
        formTitle: 'Форма',
    },

    fileFormat: 'Формат',
    campaignRegFormExecutorEmployee: 'Сотрудник',

    campaignRegFormExecutorResponsibleExecutor: 'Ответственный исполнитель',
    campaignRegFormExecutorRequestTab: 'Вкладка заявки',

    campaignRegFormExecutorAdd: 'Добавить',

    confirmDeletionInfoText: 'Вы действительно хотите удалить ответственного исполнителя {executorName}?',

    employeeLabel: 'Сотрудник',
    requestTabLabel: 'Вкладка заявки',
    editFormDialogTitle: 'Изменить ответственного исполнителя',

    collectionCompleted: 'Сбор завершен',
    createRequestError: 'Создание заявок по категории недоступно',
};

export default campaign;
