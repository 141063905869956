import React from 'react';
import { DigitalSignature } from '@platform/crypto-ui';
import { useStore } from '../../../../hooks';
import { FileDownload } from '../../../../components';
import { CampaignRequestPfModel } from '../../../../models';
import { observer } from 'mobx-react';

export type CampaignRequestPrintFormsSignatureProps = {
    printForm: CampaignRequestPfModel;
    isView?: boolean;
    onSuccess?: () => void;
};

export const CampaignRequestPrintFormsSignature = observer(
    (props: CampaignRequestPrintFormsSignatureProps): JSX.Element => {
        const { printForm, isView = false, onSuccess } = props;
        const { intlStore, signatureStore } = useStore();
        const {
            getHashSum,
            uploadSignature,
            uploadSignatureFile,
            getSignatureInfo,
            downloadSignature,
            deleteSignature,
        } = signatureStore;
        const printFormId = printForm.pf?.id || '';
        const filename = printForm.pf?.filename || '';

        return (
            <DigitalSignature
                locale={intlStore.locale}
                isView={isView}
                fileTitle={printForm.templateTitle}
                fileTitleWithExtension={filename}
                signatureSetting={printForm.signatureSettings}
                signatureStatus={printForm.signatureStatus}
                fileDownloadComponent={
                    <FileDownload
                        fileName={filename}
                        title={filename}
                        downloadFile={printForm.downloadPf}
                        fontSize="16px"
                    />
                }
                getHashSum={getHashSum(printFormId)}
                uploadSignature={uploadSignature(printFormId)}
                uploadSignatureFile={uploadSignatureFile(printFormId)}
                getSignatureInfo={getSignatureInfo(printFormId)}
                downloadSignature={downloadSignature(printFormId)}
                deleteSignature={deleteSignature(printFormId)}
                onSuccess={onSuccess}
            />
        );
    },
);
