import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { ClickAwayListener, MenuList, Paper, Popover } from '@material-ui/core';
import { observer } from 'mobx-react';

export type MenuButtonProps = PropsWithChildren<{
    renderButton(onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element;
    renderMenuItems?(hideMenu: () => void): JSX.Element | JSX.Element[] | ReactNode[];
    handleClose?: () => void;
    disablePortal?: boolean;
    reloadKey?: string;
}>;

export const MenuButton = observer(
    (props: MenuButtonProps): JSX.Element => {
        const [buttonRef, setButtonRef] = useState<null | HTMLButtonElement>(null);
        const { renderButton, renderMenuItems, children, disablePortal, handleClose, reloadKey } = props;

        const hideMenu = (): void => {
            setButtonRef(null);
        };

        const closeMenu = (): void => {
            handleClose && handleClose();
            hideMenu();
        };

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
            setButtonRef(event.currentTarget);
        };

        const isMenuOpen = !!buttonRef;

        return (
            <React.Fragment>
                {renderButton(handleClick)}
                <Popover
                    open={isMenuOpen}
                    anchorEl={buttonRef}
                    disablePortal={disablePortal}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={closeMenu}>
                            <MenuList id="language-menu" role="menu" key={reloadKey}>
                                {renderMenuItems && renderMenuItems(hideMenu)}
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popover>
            </React.Fragment>
        );
    },
);
