import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CampaignRequestPfModel } from '../../../../../models';
import { FormApi } from '@platform/formiojs-react';
import { CampaignRequestPrintFormsRow as CampaignRequestPrintFormsRowInj } from './CampaignRequestPrintFormsRow';
import { di } from 'react-magnetic-di';

export type CampaignRequestPrintFormsTableProps = {
    formApi?: FormApi;
    validateEditPage(onSuccess?: () => Promise<void>): Promise<void>;
    validateReadonlyPage(onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean): Promise<void>;
    printForms: CampaignRequestPfModel[];
};

// todo: разнести на компоненты
export const CampaignRequestPrintFormsTable = observer(
    (props: CampaignRequestPrintFormsTableProps): JSX.Element => {
        const [CampaignRequestPrintFormsRow] = di([CampaignRequestPrintFormsRowInj], CampaignRequestPrintFormsTable);

        const { validateEditPage, printForms, validateReadonlyPage } = props;

        const location = useLocation();
        const isEditPage: boolean = location.pathname.includes('edit');

        const handleGenerateAction = (printForm: CampaignRequestPfModel) => (): void => {
            const validateFunction = isEditPage ? validateEditPage : validateReadonlyPage;
            printForm.generatePrintForm(validateFunction, isEditPage);
        };

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.printFormsTable.fileColumnName" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaign.documentCategory" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.printFormsTable.taskStatus" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.printFormsTable.dateColumnName" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <Typography>
                                    <FormattedMessage id="common.actions" />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {printForms.map((printForm) => {
                            return (
                                <CampaignRequestPrintFormsRow
                                    printForm={printForm}
                                    handleGenerateAction={handleGenerateAction}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    },
);
