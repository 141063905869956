import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { TotBackground as TotBackgroundInj } from '../../../TotBackground';
import { Grid } from '@material-ui/core';
import { SamlGetAuthUrlPage as SamlGetAuthUrlPageInj } from './SamlGetAuthUrlPage';
import { di } from 'react-magnetic-di';

export const SamlPages = (): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], SamlPages);
    const [SamlGetAuthUrlPage] = di([SamlGetAuthUrlPageInj], SamlPages);

    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Switch>
                    <Route path={clientRoute.samlGetAuthUrl}>
                        <SamlGetAuthUrlPage />
                    </Route>
                </Switch>
            </Grid>
        </TotBackground>
    );
};
