import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    createStyles,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    SvgIcon,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { clientRoute } from '../../../../../clientRoute';
import { AppRouterLink as AppRouterLinkInj } from '../../../../../components';
import { CampaignRegFormDTO } from '../../../../../store';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ReactComponent as MoveIcon } from '../../../../../resources/images/icons/move.svg';
import { reorderList } from '../../../../../utils';
import { di } from 'react-magnetic-di';
import { CampaignRegFormRouteParams } from '../types';

export const useCampaignRegFormsListStylesInj = makeStyles(() =>
    createStyles({
        link: {
            width: '100%',
        },
        icon: {
            padding: '12px',
        },
    }),
);

export const CampaignRegFormsList = observer(
    (): JSX.Element => {
        const [AppRouterLink] = di([AppRouterLinkInj], CampaignRegFormsList);
        const [useCampaignRegFormsListStyles] = di([useCampaignRegFormsListStylesInj], CampaignRegFormsList);

        const { id: campaignId } = useParams<CampaignRegFormRouteParams>();
        const { campaignsStore } = useStore();
        const { changeRegFormsPosition } = campaignsStore;
        const history = useHistory();
        const classes = useCampaignRegFormsListStyles();

        const [regForms, setRegForms] = useState<CampaignRegFormDTO[]>([]);

        const loadRegForms = useCallback(async (): Promise<void> => {
            const regForms = await campaignsStore.loadRegForms(campaignId, true, true);
            setRegForms(regForms);
        }, [campaignsStore, setRegForms, campaignId]);

        useEffect(() => {
            loadRegForms();
        }, [loadRegForms]);

        const createRegForm = async (): Promise<void> => {
            const rfId = await campaignsStore.createRegForm(campaignId);
            history.push(generatePath(clientRoute.campaignRegFormCreate, { id: campaignId, rfId }));
        };

        const onDragEnd = useCallback(
            (result: DropResult): void => {
                if (!result.destination) {
                    return;
                }

                const startIndex = result.source.index;
                const endIndex = result.destination.index;

                changeRegFormsPosition(result.draggableId, endIndex + 1);
                setRegForms(reorderList(regForms, startIndex, endIndex));
            },
            [changeRegFormsPosition, setRegForms, reorderList, regForms],
        );

        return (
            <Box width="100%">
                <Box pb={5} width="100%">
                    <Grid container direction="row" alignItems="center" spacing={5}>
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="campaign.campaignRegForms" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={createRegForm}
                            >
                                <FormattedMessage id="campaign.addCampaignRegFormButton" />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box ml={-4} mr={-4} width="100%">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={'droppable'}>
                            {(droppableProvided): JSX.Element => (
                                <List
                                    ref={droppableProvided.innerRef}
                                    disablePadding={true}
                                    {...droppableProvided.droppableProps}
                                >
                                    {regForms.map((rf, index) => {
                                        const id = rf.id;

                                        return (
                                            <Draggable draggableId={id} index={index} key={id}>
                                                {(provided): JSX.Element => (
                                                    <ListItem
                                                        key={id}
                                                        button
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <AppRouterLink
                                                            className={classes.link}
                                                            underline={'none'}
                                                            to={generatePath(clientRoute.campaignRegForm, {
                                                                id: campaignId,
                                                                rfId: id,
                                                            })}
                                                        >
                                                            <ListItemText primary={rf.title} />

                                                            <IconButton {...provided.dragHandleProps}>
                                                                <SvgIcon className={classes.icon} viewBox="0 0 20 20">
                                                                    <MoveIcon />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </AppRouterLink>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {droppableProvided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Box>
        );
    },
);
