const homePage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        requests: 'My requests',
        requestsNew: 'Requests',
        campaigns: 'Categories',
        users: 'Users',
    },
};

export default homePage;
