import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AutocompleteProps, textFieldProps } from '../../../../campaign-request';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../../../../components';
import { Delete, Edit } from '@material-ui/icons';
import { CodeTitle, RequestFormSettingModel } from '../../../../../models';
import { observer } from 'mobx-react';
import { Autocomplete } from '@material-ui/lab';
import { useStore } from '../../../../../hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    chipProps,
    editMessage,
    noOptionsText,
    renderOptionCampaignRegForm as renderOptionCampaignRegFormInj,
    roleMessage,
    statusMessage,
    useAccessPermissionStyles as useAccessPermissionStylesInj,
} from './dialogs/constants';
import { di } from 'react-magnetic-di';
import { CampaingPfTemplateTabModel } from '../../../../../models/campaign-settings-docs-templates/CampaingPfTemplateTabModel';

export type RequestFormPermissionSettingsProps = {
    model: RequestFormSettingModel | CampaingPfTemplateTabModel;
    handleEditPermissionDialogOpen: (currentPermission: number) => void;
    checkboxLabel?: string | JSX.Element;
};

export const RequestFormPermissionSettingsTable = observer(
    (props: RequestFormPermissionSettingsProps): JSX.Element => {
        const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], RequestFormPermissionSettingsTable);
        const [useAccessPermissionStyles] = di([useAccessPermissionStylesInj], RequestFormPermissionSettingsTable);
        const [renderOptionCampaignRegForm] = di([renderOptionCampaignRegFormInj], RequestFormPermissionSettingsTable);

        const { intlStore: intl } = useStore();
        const { model, handleEditPermissionDialogOpen, checkboxLabel = editMessage } = props;
        const { states } = model;

        const classes = useAccessPermissionStyles();

        const renderStates = (states: CodeTitle[]): string | string[] => {
            const { length } = states;

            if (!length) {
                return intl.formatMessage('campaign.tabSettingFormTable.allStatuses');
            }

            return states.map((state, index) => {
                return `${state.title}${index !== length - 1 ? ', ' : ''}`;
            });
        };

        return (
            <Grid container direction="column" spacing={4}>
                <Grid item container spacing={4} wrap="nowrap" alignItems="flex-start">
                    <Grid item container spacing={4} alignItems="flex-start">
                        <Grid item xs={4}>
                            <Autocomplete
                                {...AutocompleteProps}
                                value={model.permission}
                                onChange={model.onChangePermission}
                                popupIcon={<ExpandMoreIcon />}
                                noOptionsText={noOptionsText}
                                ChipProps={chipProps}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.title}
                                options={model.permissionsList}
                                renderInput={renderAutoCompleteInput(
                                    roleMessage,
                                    true,
                                    model.permissionMessage,
                                    textFieldProps,
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                {...AutocompleteProps}
                                multiple
                                disableCloseOnSelect
                                value={states.slice()}
                                onChange={model.onChangeStates}
                                popupIcon={<ExpandMoreIcon />}
                                noOptionsText={noOptionsText}
                                ChipProps={chipProps}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.title}
                                options={model.statesList}
                                renderInput={renderAutoCompleteInput(statusMessage, false, '', {
                                    ...textFieldProps,
                                    placeholder: states.length ? '' : intl.formatMessage('common.all'),
                                })}
                                renderOption={renderOptionCampaignRegForm}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        className={classes.editPermission}
                                        checked={model.editPermission}
                                        onChange={model.onChangeEditPermission}
                                    />
                                }
                                label={checkboxLabel}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button color="secondary" variant="contained" onClick={model.addAccessPermission}>
                            <FormattedMessage id="campaign.campaignRegFormExecutorAdd" />
                        </Button>
                    </Grid>
                </Grid>
                {!!model.permissions.length && (
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="campaign.tabSettingFormTable.role" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="campaign.tabSettingFormTable.status" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="campaign.tabSettingFormTable.access" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {model.permissions.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{item.permission.title}</TableCell>
                                            <TableCell>{renderStates(item.states)}</TableCell>
                                            <TableCell>
                                                {item.edit && <Box>{checkboxLabel}</Box>}
                                                <Box>
                                                    <FormattedMessage id="campaign.tabSettingForm.view" />
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                <Grid container spacing={2} wrap="nowrap">
                                                    <Grid item>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleEditPermissionDialogOpen(index)}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => model.deleteAccessPermission(index)}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        );
    },
);
