import React from 'react';
import { Box, Button, Chip, Container, Grid, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { getOrElse } from '../../utils/getOrElse';
import { UserPersonModel } from '../../models/users/UserPersonModel';
import { ReactComponent as EditIcon } from '../../resources/images/icons/edit.svg';
import { IconButtonLarge } from '../../components/buttons/IconButtonLarge';
import { clientRoute } from '../../clientRoute';
import { Route } from 'react-router-dom';
import { UserEditDialog as UserEditDialogInj } from './UserEditDialog';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../components/AuthorizationCheck';
import { entities, permissions } from '../../authSchemeConfig';
import {
    TotObjectHeader as TotObjectHeaderInj,
    TotObjectHeaderLabel as TotObjectHeaderLabelInj,
    TotObjectHeaderValue as TotObjectHeaderValueInj,
} from '../../components/tot-object/TotObjectHeader';
import { useModal } from '../../hooks/useModal';
import { di } from 'react-magnetic-di';

export type UserHeaderProps = {
    userPersonModel: UserPersonModel;
    loginAsUser: () => void;
};

export const UserHeader = observer(
    (props: UserHeaderProps): JSX.Element => {
        const { userPersonModel, loginAsUser } = props;

        const [TotObjectHeader] = di([TotObjectHeaderInj], UserHeader);
        const [TotObjectHeaderLabel] = di([TotObjectHeaderLabelInj], UserHeader);
        const [TotObjectHeaderValue] = di([TotObjectHeaderValueInj], UserHeader);
        const [UserEditDialog] = di([UserEditDialogInj], UserHeader);
        const [AuthorizationCheck] = di([AuthorizationCheckInj], UserHeader);

        const [isModalOpen, setModalIsOpen, setModalIsClosed] = useModal();

        return (
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column">
                        <Grid item container justify="space-between">
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h1">
                                            {getOrElse(userPersonModel.login, userPersonModel.login, '-')}
                                        </Typography>
                                    </Grid>
                                    <Route path={clientRoute.user} exact>
                                        <AuthorizationCheck
                                            entityCode={entities.System}
                                            entityId={userPersonModel.id}
                                            permCode={permissions.System.Administration}
                                        >
                                            <Grid item>
                                                <Tooltip title={<FormattedMessage id="users.editUser" />}>
                                                    <IconButtonLarge onClick={setModalIsOpen}>
                                                        <EditIcon />
                                                    </IconButtonLarge>
                                                </Tooltip>
                                                <UserEditDialog
                                                    userPersonModel={userPersonModel}
                                                    isOpen={isModalOpen}
                                                    close={setModalIsClosed}
                                                />
                                            </Grid>
                                        </AuthorizationCheck>
                                    </Route>
                                </Grid>
                            </Grid>
                            <Route path={clientRoute.user} exact>
                                <Grid item>
                                    <AuthorizationCheck
                                        entityCode={entities.User}
                                        permCode={permissions.User.LoginAs}
                                        entityId={userPersonModel.id}
                                    >
                                        <Button onClick={loginAsUser} variant="contained" color="primary">
                                            <FormattedMessage id="users.loginAsUser" />
                                        </Button>
                                    </AuthorizationCheck>
                                </Grid>
                            </Route>
                        </Grid>

                        <Grid item container spacing={6} direction="row">
                            <Grid item>
                                <Grid container direction="row" alignItems="baseline" spacing={2}>
                                    <Grid item>
                                        <TotObjectHeaderLabel>
                                            <FormattedMessage id="users.fields.roles" />
                                        </TotObjectHeaderLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectHeaderValue>
                                            {userPersonModel.roles.map((r) => (
                                                <Box pr={1} component="span" key={r.code}>
                                                    <Chip label={r.title} />
                                                </Box>
                                            ))}
                                        </TotObjectHeaderValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        );
    },
);
