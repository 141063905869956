const breadcrumbs: Record<string, string | Record<string, string>> = {
    mainPage: 'Главная страница',
    campaigns: 'Категории',
    campaign: 'Категория',
    requests: 'Реестр заявок',
    requestsNew: 'Реестр заявок',
    campaignSettings: 'Настройки категории',
    regFormSelect: 'Выбор формы участия заявки',
    users: 'Пользователи',
};

export default breadcrumbs;
