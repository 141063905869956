import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FormView, FormApi, MultiLangFormEdit } from '@platform/formiojs-react';
import { UserPersonModel } from '../../models/users/UserPersonModel';
import { clientRoute } from '../../clientRoute';
import { UserHeader as UserHeaderInj } from './UserHeader';
import { UserControlPanel as UserControlPanelInj } from './UserControlPanel';
import { useStore } from '../../hooks/useStore';
import { TotObjectMain as TotObjectMainInj } from '../../components/tot-object/TotObjectMain';
import { PageContentContainer as PageContentContainerInj } from '../../components/PageContentContainer';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../components/AuthorizationCheck';

export type UserPageRouteParams = {
    id: string;
};

export const UserPage = observer(
    (): JSX.Element => {
        const [UserHeader] = di([UserHeaderInj], UserPage);
        const [UserControlPanel] = di([UserControlPanelInj], UserPage);
        const [TotObjectMain] = di([TotObjectMainInj], UserPage);
        const [PageContentContainer] = di([PageContentContainerInj], UserPage);
        const [AuthorizationCheck] = di([AuthorizationCheckInj], UserPage);

        const { userStore, personStore, intlStore } = useStore();
        const { locale } = intlStore;
        const { id } = useParams<UserPageRouteParams>();
        const history = useHistory();

        const [userModel] = useState<UserPersonModel>(new UserPersonModel(id));
        const [formApi, setFormApi] = useState<FormApi>();
        const { personForm } = userModel;

        useEffect(() => {
            userStore.loadUser(id).then(userModel.load);
        }, [userStore, id, userModel]);

        const onFormReady = (form: FormApi): void => {
            setFormApi(form);
        };

        const savePersonForm = (): void => {
            if (formApi && formApi.validate()) {
                userStore.saveUser(userModel.id, formApi);
            }
        };

        const loginAsUser = (): void => {
            const { loginAsUser } = userStore;
            const { id } = userModel;
            loginAsUser(id).then(() => {
                personStore.getInfo().then(() => {
                    history.push(generatePath(personStore.redirectPath));
                });
            });
        };

        return (
            <PageContentContainer container direction="column">
                <Grid item>
                    <UserHeader userPersonModel={userModel} loginAsUser={loginAsUser} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <Grid item style={{ maxWidth: '100%' }}>
                                <Switch>
                                    <Route exact path={clientRoute.userEdit}>
                                        <MultiLangFormEdit
                                            intlStore={intlStore}
                                            form={personForm}
                                            onFormReady={onFormReady}
                                        />
                                    </Route>
                                    <Route exact path={clientRoute.user}>
                                        <FormView
                                            locale={locale}
                                            form={personForm}
                                            onFormReady={onFormReady}
                                            multiLang={true}
                                        />
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <AuthorizationCheck
                        entityCode={entities.User}
                        entityId={userModel.id}
                        permCode={permissions.User.UpdateForm}
                    >
                        <UserControlPanel userPersonModel={userModel} saveForm={savePersonForm} />
                    </AuthorizationCheck>
                </Grid>
            </PageContentContainer>
        );
    },
);
