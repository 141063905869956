import { action, computed, observable } from 'mobx';
import {
    ComponentWithValidationMessage,
    FormApi,
    FormioSidebarStore,
    validateReadonlyPage as validateReadonlyPageFormio,
} from '@platform/formiojs-react';
import { IdTitle } from './IdTitle';
import { WebForm } from 'formiojs/WebForm';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { History } from 'history';
import { DeadlineDTO, DeadlinesDTO, EmployeeDTO, RequestDTO, TabDTO } from '../types';
import { RequestFormModel } from './RequestFormModel';
import { NotificationStore, RequestStore, RootStore } from '../store';
import { handleAxiosErrorByResponseStatus } from '../utils';

export class RequestModel {
    @observable private formioSidebarStore: FormioSidebarStore;
    @observable private requestStore: RequestStore;
    @observable private history: History;
    @observable private notificationStore: NotificationStore;

    @observable id: string;
    @observable number = '';
    @observable regFormTitle = '';
    @observable campaign: IdTitle = { id: '', title: '' };
    @observable state = '';
    @observable formsTabs: TabDTO[] = [];
    @observable firstTabName: string = '';
    @observable executors: EmployeeDTO[] = [];
    @observable firstFormId: string = '';

    @observable currentTab?: any | null;

    @observable formApi?: FormApi;
    @observable isFormReady: boolean = false;
    @observable formErrors: ComponentWithValidationMessage[] = [];
    @observable deadlines: DeadlinesDTO = {};

    @observable showValidation: boolean = false;

    formName = 'request';

    constructor(id: string, rootStore: RootStore) {
        this.formioSidebarStore = rootStore.formioSidebarStore;
        this.requestStore = rootStore.requestStore;
        this.history = rootStore.history;
        this.notificationStore = rootStore.notificationStore;
        this.id = id;
    }

    @computed
    get executorsList(): string {
        if (!this.executors) {
            return '';
        }

        const executorsList = this.executors.map(
            (executor) =>
                `${executor.person.lastName} ${executor.person.firstName} ${executor.person.middleName || ''}`,
        );
        return executorsList.join(', ');
    }

    @action.bound
    load(dto: RequestDTO): void {
        const firstTab = dto.customTabs[0];

        this.regFormTitle = dto.regFormTitle;
        this.campaign = dto.campaign;
        this.number = dto.number;
        this.state = dto.state;
        this.formsTabs = dto.customTabs;
        this.executors = dto.executors;
        this.firstFormId = firstTab && firstTab.id;

        this.loadDeadlines();
    }

    @action.bound
    onFormChange(form: WebForm): void {
        this.formioSidebarStore.updateItemsVisibility(this.formName, form);
    }

    @action.bound
    onFormReady(form: FormApi): void {
        this.formApi = form;
        this.formioSidebarStore.initSidebarItems(this.formName, form.form);
        this.isFormReady = true;

        setTimeout(() => this.validateAfterReadonly(), 0);
    }

    @action.bound
    updateCurrentTab(): void {
        if (!this.currentTab) {
            return;
        }

        const tab = this.requestStore.getRequestFormModel(this.currentTab?.id);
        this.currentTab.tabExecutor = tab.tabExecutor;
    }

    @action.bound
    setCurrentTab(formId: string): void {
        this.requestStore
            .getRequestFormModel(formId)
            .then((model: RequestFormModel) => {
                this.currentTab = model;
            })
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.push(generatePath(clientRoute.request, { id: this.id })),
                    404: () => this.history.push(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    updateExecutors() {
        this.requestStore.getRequestDTO(this.id).then((data) => {
            this.executors = data.executors;
            this.formsTabs = data.customTabs;
            this.updateCurrentTab();
        });
    }

    @action.bound
    validateEditPage(onSuccess?: () => Promise<void>): Promise<void> {
        if (this.formApi) {
            return this.formApi.submit(true).finally(() => {
                if (!this.formErrors.length) {
                    this.formioSidebarStore.updateSidebarErrors(this.formName);
                    return onSuccess ? onSuccess() : Promise.resolve();
                }
                this.formioSidebarStore.updateSidebarErrors(this.formName, this.formErrors);
                console.log('validation errors:', this.formErrors);
                return Promise.reject(this.formErrors);
            });
        } else {
            return Promise.reject(['formApi is undefined']);
        }
    }

    @action.bound
    validateAfterReadonly(): void {
        if (this.showValidation) {
            this.validateEditPage().finally(() => {
                this.showValidation = false;
                this.formErrors = [];
            });
        }
    }

    @action.bound
    validateReadonlyPage(onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean): Promise<void> {
        const setShowValidation = (isShowValidation: boolean): void => {
            this.showValidation = isShowValidation;
        };
        const setFormErrors = (errors: ComponentWithValidationMessage[]): void => {
            this.formErrors = errors;
        };

        return validateReadonlyPageFormio({
            setFormErrors,
            setShowValidation,
            onSuccess,
            formApi: this.formApi,
            formioSidebarStore: this.formioSidebarStore,
            formName: this.formName,
            withoutSignatureValidate,
        });
    }

    @action.bound
    goToRequestPage(path?: string): void {
        const redirectPath = path || generatePath(clientRoute.request, { id: this.id });
        this.isFormReady = false; // меняем состояние перед переходом на другой роут
        this.history.push({
            pathname: redirectPath,
            search: '?prompt=false',
        });
    }

    saveRequestForm = (formId: string): (() => Promise<void>) => {
        const { formApi, id, requestStore, goToRequestPage } = this;

        return (): Promise<void> => {
            if (formApi && formId) {
                return formApi
                    .submit(false)
                    .then(() => {
                        return requestStore.saveRequestForm(formId, formApi.getSubmission());
                    })
                    .then(() => {
                        goToRequestPage(generatePath(clientRoute.requestForm, { id, formId }));
                    })
                    .catch((error) => {
                        this.notificationStore.onError(error.response.data);
                    });
            }
            return Promise.reject();
        };
    };

    @action.bound
    deleteRequest(): Promise<void> {
        const { id, requestStore, campaign, history } = this;

        return requestStore.deleteRequest(id).then(() => {
            this.isFormReady = false; // меняем состояние перед переходом на другой роут
            history.push(generatePath(clientRoute.campaign, { id: campaign.id }));
        });
    }

    @action.bound
    transitionLifeCycle(transitionId: string, requestId: string): Promise<void> {
        const onSuccess = () => this.requestStore.transitionToNextLifeCycleStep(transitionId, requestId);
        return onSuccess();
    }

    @action.bound
    goToRequestEditPageAndValidate(path: string): () => void {
        return (): void => {
            this.isFormReady = false; // меняем состояние перед переходом на другой роут
            this.history.push(path);
        };
    }

    @action.bound
    loadDeadlines(): void {
        this.requestStore.getDeadlinesDTO(this.id).then((deadlinesDTO) => {
            const entityDeadlineDate = deadlinesDTO.entityDeadline?.date;
            const stateDeadlineDate = deadlinesDTO.stateDeadline?.date;

            if (entityDeadlineDate) {
                (deadlinesDTO.entityDeadline as DeadlineDTO).date = new Date(entityDeadlineDate);
            }

            if (stateDeadlineDate) {
                (deadlinesDTO.stateDeadline as DeadlineDTO).date = new Date(stateDeadlineDate);
            }

            this.deadlines = deadlinesDTO;
        });
    }
}
