import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CampaignRequestPage as CampaignRequestPageInj } from './campaign-request-page/CampaignRequestPage';
import { CampaignRequestListPage as CampaignRequestListPageInj } from './campaign-request-list-page/CampaignRequestListPage';
import { Authentication as AuthenticationInj } from '../../components/Authentication';
import { CampaignRequestListPageNew as CampaignRequestListPageNewInj } from './campaign-request-list-page/CampaignRequestListPageNew';
import { di } from 'react-magnetic-di';

export const CampaignRequestPages = (): JSX.Element => {
    const [CampaignRequestListPageNew] = di([CampaignRequestListPageNewInj], CampaignRequestPages);
    const [CampaignRequestPage] = di([CampaignRequestPageInj], CampaignRequestPages);
    const [CampaignRequestListPage] = di([CampaignRequestListPageInj], CampaignRequestPages);
    const [Authentication] = di([AuthenticationInj], CampaignRequestPages);

    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.requestsNew}>
                    <CampaignRequestListPageNew />
                </Route>
                <Route path={clientRoute.request}>
                    <CampaignRequestPage />
                </Route>
                <Route path={clientRoute.requests}>
                    <CampaignRequestListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
