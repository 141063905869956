import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { withConfirmDialog } from '../../../../../hocs';
import { di } from 'react-magnetic-di';

export type CampaignCancelButtonDialogProps = {
    onCancel: () => void;
};

const CancelButton = (props: CampaignCancelButtonDialogProps): JSX.Element => {
    const { onCancel } = props;

    return (
        <Button onClick={onCancel} color="secondary" size="small" variant="contained">
            <FormattedMessage id="common.discardChanges" />
        </Button>
    );
};

export const CancelButtonButtonWithDialogInj = withConfirmDialog(
    ({ openConfirmDialog }): JSX.Element => <CancelButton onCancel={openConfirmDialog} />,
);

export type CancelButtonProps = {
    onConfirm: () => void;
};

export const CampaignCancelButtonWithDialog = (props: CancelButtonProps): JSX.Element => {
    const { onConfirm } = props;
    const [CancelButtonButtonWithDialog] = di([CancelButtonButtonWithDialogInj], CampaignCancelButtonWithDialog);

    return (
        <CancelButtonButtonWithDialog
            id="delete-request"
            title={<FormattedMessage id="campaignRequest.dialog.title" />}
            message={<FormattedMessage id="campaignRequest.dialog.closeMessage" />}
            onConfirm={onConfirm}
        />
    );
};
