import {
    Button,
    Grid,
    IconButton,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useStore } from '../../../../../hooks';
import { clientRoute } from '../../../../../clientRoute';
import { observer } from 'mobx-react';
import { RequestFormSettingListDTO } from '../../../../../store';
import { Edit } from '@material-ui/icons';
import { RequestFormTabSettingsDTO, RequestFormTabsSettingDialog as RequestFormTabsSettingDialogInj } from './dialogs';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { reorderList } from '../../../../../utils';
import { ReactComponent as MoveIcon } from '../../../../../resources/images/icons/move.svg';
import { CampaignSettingsDeleteButton as CampaignSettingsDeleteButtonInj } from '../../CampaignSettingsDeleteButton';
import { di } from 'react-magnetic-di';
import { CampaignRegFormRouteParams } from '../types';

export const RequestFormTabsSettings = observer(
    (): JSX.Element => {
        const [CampaignSettingsDeleteButton] = di([CampaignSettingsDeleteButtonInj], RequestFormTabsSettings);
        const [RequestFormTabsSettingDialog] = di([RequestFormTabsSettingDialogInj], RequestFormTabsSettings);

        const { campaignsStore } = useStore();
        const { id, rfId } = useParams<CampaignRegFormRouteParams>();

        const history = useHistory();
        const [settings, setSettings] = useState<RequestFormSettingListDTO[]>([]);

        useEffect(() => {
            campaignsStore.requestFormSettings(rfId).then(setSettings);
        }, [id, rfId, campaignsStore]);

        const goToSettingDialog = useCallback(async (): Promise<void> => {
            history.push(
                generatePath(clientRoute.campaignRequestFormTabSettingCreate, {
                    id,
                    rfId,
                    settingId: '-',
                }),
            );
        }, [id, rfId, history]);

        const createSetting = useCallback(
            async (dto: RequestFormTabSettingsDTO): Promise<void> => {
                await campaignsStore.createRequestFormSetting(rfId, dto);
                await campaignsStore.requestFormSettings(rfId).then(setSettings);
            },
            [id, rfId, campaignsStore],
        );

        const updateSetting = useCallback(
            async (settingId: string, dto: RequestFormTabSettingsDTO): Promise<void | number> => {
                const response = await campaignsStore.saveRequestFormSetting(settingId, dto);
                await campaignsStore.requestFormSettings(rfId).then(setSettings);
                return response;
            },
            [rfId, campaignsStore],
        );

        const deleteSetting = useCallback(
            (settingId: string) => async (): Promise<void> => {
                await campaignsStore.deleteRequestFormSetting(settingId).then((data) => {
                    if (data) {
                        setSettings((prevState) => prevState.filter((setting) => setting.id !== settingId));
                    }
                });
            },
            [campaignsStore, setSettings],
        );

        const setReordered = (sourceIndex: number, destinationIndex: number): void => {
            const reordered = reorderList<RequestFormSettingListDTO>(settings, sourceIndex, destinationIndex);
            setSettings(reordered);
        };

        const onDragEnd = (result: DropResult): void => {
            if (!result.destination) {
                return;
            }
            const destinationIndex = result.destination.index;
            const settingsBefore = JSON.parse(JSON.stringify(settings));
            campaignsStore.changePositionRequestFormSetting(result.draggableId, destinationIndex + 1).catch(() => {
                setSettings(settingsBefore);
            });
            setReordered(result.source.index, destinationIndex);
        };

        return (
            <React.Fragment>
                <Switch>
                    <Route path={clientRoute.campaignRequestFormTabSetting}>
                        <RequestFormTabsSettingDialog updateSetting={updateSetting} />
                    </Route>
                    <Route path={clientRoute.campaignRequestFormTabSettingCreate}>
                        <RequestFormTabsSettingDialog createSetting={createSetting} />
                    </Route>
                </Switch>

                <Grid container direction="column" spacing={5}>
                    <Grid item container direction="row" justify="space-between">
                        <Grid item xs={6}>
                            <Typography variant="h6">
                                <FormattedMessage id="campaign.requestFormSettingsTitle" />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={goToSettingDialog}>
                                    <FormattedMessage id="campaign.createRequestFormSettings" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="campaign.requestFormSettings.title" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="campaign.requestFormSettings.formTitle" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId={'droppable'}>
                                        {(droppableProvided) => (
                                            <TableBody
                                                {...droppableProvided.droppableProps}
                                                ref={droppableProvided.innerRef}
                                            >
                                                {settings.map((s, index) => {
                                                    const { id: settingId, title: settingTitle } = s;
                                                    return (
                                                        <Draggable
                                                            draggableId={settingId}
                                                            index={index}
                                                            key={settingId}
                                                        >
                                                            {(provided) => (
                                                                <TableRow
                                                                    key={settingId}
                                                                    hover
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {settingTitle}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '58%' }}>
                                                                        {s.formTitle}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        style={{ width: '96px', minWidth: '96px' }}
                                                                    >
                                                                        <IconButton {...provided.dragHandleProps}>
                                                                            <SvgIcon viewBox="0 0 20 20">
                                                                                <MoveIcon />
                                                                            </SvgIcon>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            component={NavLink}
                                                                            to={generatePath(
                                                                                clientRoute.campaignRequestFormTabSetting,
                                                                                {
                                                                                    id,
                                                                                    rfId,
                                                                                    settingId,
                                                                                },
                                                                            )}
                                                                            size="small"
                                                                        >
                                                                            <Edit />
                                                                        </IconButton>
                                                                        <CampaignSettingsDeleteButton
                                                                            id="confirm-delete"
                                                                            title={
                                                                                <FormattedMessage id="common.confirmDeletion" />
                                                                            }
                                                                            message={
                                                                                <FormattedMessage
                                                                                    id="campaign.confirmDeletionRequestFormSettingInfoText"
                                                                                    values={{ title: settingTitle }}
                                                                                />
                                                                            }
                                                                            onConfirm={deleteSetting(settingId)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {droppableProvided.placeholder}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    },
);
