import React from 'react';
import { StickyWrapper as StickyWrapperInj } from '../../components/sticky/StickyWrapper';
import { Button, Container, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TotObjectActionsBar as TotObjectActionsBarInj } from '../../components/tot-object/TotObjectActionsBar';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { observer } from 'mobx-react';
import { UserPersonModel } from '../../models/users/UserPersonModel';
import { di } from 'react-magnetic-di';

export type UserControlPanelProps = {
    userPersonModel: UserPersonModel;
    saveForm: () => void;
};

export const UserControlPanel = observer(
    (props: UserControlPanelProps): JSX.Element => {
        const { userPersonModel, saveForm } = props;
        const { id } = userPersonModel;

        const [StickyWrapper] = di([StickyWrapperInj], UserControlPanel);
        const [TotObjectActionsBar] = di([TotObjectActionsBarInj], UserControlPanel);

        return (
            <StickyWrapper mode="bottom">
                <TotObjectActionsBar>
                    <Container maxWidth="lg">
                        <Grid alignItems="center" container>
                            <Grid item>
                                <Grid spacing={3} alignItems="center" justify="center" container>
                                    <Switch>
                                        <Route exact path={clientRoute.user}>
                                            <Grid item>
                                                <Button
                                                    component={NavLink}
                                                    to={generatePath(clientRoute.userEdit, { id })}
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    <FormattedMessage id="common.edit" />
                                                </Button>
                                            </Grid>
                                        </Route>
                                    </Switch>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid spacing={3} alignItems="center" justify="center" container>
                                    <Switch>
                                        <Route exact path={clientRoute.userEdit}>
                                            <Grid item>
                                                <Button color="primary" variant="contained" onClick={saveForm}>
                                                    <FormattedMessage id="common.save" />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    component={NavLink}
                                                    to={generatePath(clientRoute.user, { id })}
                                                >
                                                    <FormattedMessage id="common.discardChanges" />
                                                </Button>
                                            </Grid>
                                        </Route>
                                    </Switch>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </TotObjectActionsBar>
            </StickyWrapper>
        );
    },
);
