import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Autocomplete, Value } from '@material-ui/lab';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../../components/renderAutoCompleteInput';
import { useStore } from '../../../hooks/useStore';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { RequestModel } from '../../../models/RequestModel';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { clientRoute } from '../../../clientRoute';
import { EmployeeDTO } from '../../../types/stores/requestStore';
import { CampaignRequestRow } from '../../../models';
import { di } from 'react-magnetic-di';

export type ExecutorEditDialogProps = {
    onClose: () => void;
    open: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    requestModel?: RequestModel;
    requestRowModel?: CampaignRequestRow;
    title?: string;
    reloadModel?: () => void;
};

type RouteParams = {
    id: string;
};

const useStyles = makeStyles(() =>
    createStyles({
        typography: {
            fontWeight: 'bold',
        },
    }),
);

export const ExecutorEditDialog = observer(
    (props: ExecutorEditDialogProps): JSX.Element => {
        const { onClose, open, requestRowModel, title, requestModel, setIsConfirm } = props;

        const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], ExecutorEditDialog);

        const { intlStore: intl, requestStore } = useStore();
        const classes = useStyles();

        const [employeesList, setEmployeesList] = useState<EmployeeDTO[]>([]);
        const [selectedExecutor, setSelectedExecutor] = useState<EmployeeDTO | null>();
        const [errorText, setErrorText] = useState<string>('');

        const { id } = useParams<RouteParams>();
        const requestId = requestRowModel ? requestRowModel.id : id;

        useEffect(() => {
            requestStore.getEmployeesList().then(setEmployeesList);
        }, [requestStore, setEmployeesList]);

        useEffect(() => {
            requestId && requestStore.getExecutorSettings(requestId).then(setSelectedExecutor);
        }, [requestStore, setSelectedExecutor, requestId]);

        const handleExecutorChange = (
            event: ChangeEvent<{}>,
            value: Value<EmployeeDTO, undefined, undefined, undefined>,
        ): void => {
            setSelectedExecutor(value);
            setErrorText('');
        };

        const handleConfirm = (): void => {
            if (!selectedExecutor) {
                setErrorText(intl.formatMessage('validation.required'));
                return;
            }

            requestStore.editExecutorSettings(requestId, selectedExecutor?.userId).then(() => {
                requestModel && requestModel.updateExecutors();

                if (requestRowModel) {
                    requestRowModel.executors = [selectedExecutor];
                }

                if (setIsConfirm) {
                    setIsConfirm(true);
                }

                handleClose();
            });
        };

        const handleClose = (): void => {
            onClose();
            setErrorText('');
            setSelectedExecutor(null);
        };

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    <FormattedMessage id={title ? title : 'campaignRequest.dialog.editExecutorTitle'} />
                </DialogTitle>
                <DialogContent>
                    {requestRowModel && (
                        <Box pb={4}>
                            <Grid container spacing={3}>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.typography} variant="body2">
                                            <FormattedMessage id="campaignRequest.registryColumns.number" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Link
                                                component={NavLink}
                                                underline="none"
                                                to={generatePath(clientRoute.request, { id: requestRowModel.id })}
                                            >
                                                {requestRowModel.number || (
                                                    <FormattedMessage id="campaignRequest.withoutNumber" />
                                                )}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.typography} variant="body2">
                                            <FormattedMessage id="campaignRequest.registryColumns.campaign" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Link
                                                component={NavLink}
                                                underline="none"
                                                to={generatePath(clientRoute.campaign, {
                                                    id: requestRowModel.campaignId,
                                                })}
                                            >
                                                {requestRowModel.campaignTitle}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.typography} variant="body2">
                                            <FormattedMessage id="campaignRequest.registryColumns.author" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{requestRowModel.authorName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Autocomplete
                        key={selectedExecutor?.userId}
                        onChange={handleExecutorChange}
                        getOptionSelected={(option, value) => option.userId === value.userId}
                        getOptionLabel={(option) => `${option.person.lastName} ${option.person.firstName}`}
                        options={employeesList}
                        defaultValue={selectedExecutor}
                        loading={false}
                        renderInput={renderAutoCompleteInput(
                            intl.formatMessage('campaign.campaignRegFormExecutorResponsibleExecutor'),
                            true,
                            errorText,
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Button onClick={handleConfirm} size="large" color="primary" variant="contained" fullWidth>
                                <FormattedMessage id="common.save" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClose} size="large" color="primary" fullWidth>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    },
);
