import React, { ChangeEvent, useEffect, FormEvent, useMemo, useState } from 'react';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../../components/renderAutoCompleteInput';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { CodeTitle } from '../../../models/CodeTitle';
import { di } from 'react-magnetic-di';

export type CampaignCreateDialogProps = {
    onClose: () => void;
    createCampaign: (activeLifecycle: string) => void;
};

export const CampaignCreateDialog = observer(
    (props: CampaignCreateDialogProps): JSX.Element => {
        const { onClose, createCampaign } = props;

        const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], CampaignCreateDialog);

        const [activeLifecycleList, setActiveLifecycleList] = useState<CodeTitle[]>([]);
        const [isLoadingActiveCampaigns, setIsLoadingActiveCampaigns] = useState<boolean>(true);
        const [activeLifecycle, setActiveLifecycle] = useState<string>('');
        const [validationStarted, setValidationStarted] = useState<boolean>(false);

        const { campaignsStore, intlStore: intl } = useStore();

        useEffect(() => {
            campaignsStore.getLifecycleList().then((lifecycleList) => {
                setIsLoadingActiveCampaigns(false);
                setActiveLifecycleList(lifecycleList);
            });
        }, [campaignsStore, setIsLoadingActiveCampaigns, setActiveLifecycleList]);

        const error = useMemo(() => {
            if (validationStarted && !activeLifecycle) {
                return intl.formatMessage('validation.required');
            }
            return '';
        }, [activeLifecycle, intl, validationStarted]);

        const onChangeLifecycle = (e: ChangeEvent<{}>, value: CodeTitle | null): void => {
            setValidationStarted(true);
            setActiveLifecycle(value ? value.code : '');
        };

        const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
            event.preventDefault();

            createCampaign(activeLifecycle);
        };

        return (
            <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="campaign.newCampaign" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <form noValidate onSubmit={onSubmit}>
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item>
                                    <Autocomplete
                                        onChange={onChangeLifecycle}
                                        getOptionSelected={(option, value) => option.code === value.code}
                                        getOptionLabel={(option) => option.title}
                                        options={activeLifecycleList}
                                        loading={isLoadingActiveCampaigns}
                                        renderInput={renderAutoCompleteInput(
                                            intl.formatMessage('campaign.lifecycle'),
                                            true,
                                            error,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <Button
                                                disabled={!activeLifecycle}
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                            >
                                                <FormattedMessage id="campaign.createCampaign" />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={onClose} fullWidth size="large" variant="contained">
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);
