import { recordToLang } from '../utils';
import authentication from './authentication';
import validation from './validation';
import startPage from './startPage';
import homePage from './homePage';
import campaign from './campaign';
import common from './common';
import campaignRequest from './campaignRequest';
import users from './users';
import sidebar from './sidebar';
import breadcrumbs from './breadcrumbs';
import notifications from './notifications';
import digitalSignature from './digitalSignature';
import { ttable_en } from '@platform/ttable';

const lang: Record<string, string> = recordToLang({
    productTitle: 'TOT X',
    authentication,
    validation,
    startPage,
    homePage,
    campaign,
    campaignRequest,
    users,
    common,
    sidebar,
    breadcrumbs,
    notifications,
    digitalSignature,
    ttable: ttable_en,
});

export default lang;
