import { Button, Container, Grid, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import {
    ConfirmationDialog as ConfirmationDialogInj,
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
    TotObjectTransitions as TotObjectTransitionsInj,
} from '../../../../components';
import { useError, useModal, useReload, useStore } from '../../../../hooks';
import { CampaignInfoModel } from '../../../../models';
import { di } from 'react-magnetic-di';

type RouteParams = {
    id: string;
};

export const CampaignManagement = observer(
    (): JSX.Element => {
        const [ConfirmationDialog] = di([ConfirmationDialogInj], CampaignManagement);
        const [ErrorDialog] = di([ErrorDialogInj], CampaignManagement);
        const [ErrorMessage] = di([ErrorMessageInj], CampaignManagement);
        const [TotObjectTransitions] = di([TotObjectTransitionsInj], CampaignManagement);

        const [open, setModalIsOpen, setModalIsClosed] = useModal();
        const history = useHistory();
        const { id } = useParams<RouteParams>();
        const rootStore = useStore();
        const { campaignsStore } = rootStore;
        const [isError, errorText, enableError, resetError] = useError();

        const [model] = useState<CampaignInfoModel>(new CampaignInfoModel(rootStore.env.apiUrl));

        useEffect(() => {
            campaignsStore.loadCampaignInfo(id).then(model.load);
        }, [campaignsStore, id, model]);

        const handleErrorDeletion = (error: AxiosError): void => {
            const errorText = ErrorMessage(error);
            enableError(errorText);
        };

        const onConfirmDelete = async (): Promise<void> => {
            try {
                await campaignsStore.deleteCampaign(id);
                history.push(clientRoute.campaigns);
            } catch (error) {
                handleErrorDeletion(error);
            }
            setModalIsClosed();
        };

        const onDeleteLinkClick = (): void => {
            setModalIsOpen();
        };

        const lifeCycleTransition = async (transitionId: string, requestId: string): Promise<void> => {
            await campaignsStore.transitionToNextLifeCycleStepCampaign(transitionId, requestId);
            campaignsStore.loadCampaignInfo(id).then(model.load);
        };

        const [reloadKey, reloadIncrement] = useReload();
        return (
            <React.Fragment>
                <ErrorDialog message={errorText} open={isError} onClose={resetError} />
                <Container maxWidth="lg" key={reloadKey}>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="campaign.campaignTitle" values={{ campaignName: model.title }} />
                            </Typography>
                        </Grid>
                        <Grid item container alignItems="center" spacing={10}>
                            <Grid item>
                                <TotObjectTransitions
                                    objectId={id}
                                    getTransitions={campaignsStore.getTransitionCampaign}
                                    updateObjectPage={reloadIncrement}
                                    lifeCycleTransition={lifeCycleTransition}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="text"
                                    color="primary"
                                    startIcon={<Delete />}
                                    onClick={onDeleteLinkClick}
                                >
                                    <FormattedMessage id="campaign.deleteCampaign" />
                                </Button>
                                <ConfirmationDialog
                                    id="delete-request"
                                    keepMounted
                                    open={open}
                                    onConfirm={onConfirmDelete}
                                    onCancel={setModalIsClosed}
                                    title={<FormattedMessage id="common.confirmDeletion" />}
                                    message={
                                        <FormattedMessage
                                            id="campaign.campaignManagementDelete"
                                            values={{ campaignName: model.title }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    },
);
