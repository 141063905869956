import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Redirect, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { RegistrationConfirmModel } from '../../models/RegistrationConfirmModel';
import { TotBackground as TotBackgroundInj } from '../../TotBackground';
import { useStore } from '../../hooks';
import { di } from 'react-magnetic-di';

type RouteParams = {
    token: string | undefined;
};

export const RegistrationConfirmPage = observer(
    (): JSX.Element => {
        const [TotBackground] = di([TotBackgroundInj], RegistrationConfirmPage);

        const { token } = useParams<RouteParams>();
        const { personStore, authenticationStore } = useStore();
        const [registrationConfirmModel] = useState<RegistrationConfirmModel>(
            authenticationStore.registrationConfirmModel,
        );

        useEffect(() => {
            registrationConfirmModel.confirmEmail(token);
        }, [registrationConfirmModel, token]);

        const renderByStatus = (): JSX.Element | null => {
            const { status } = registrationConfirmModel;

            switch (status) {
                case 'pending':
                    return <CircularProgress />;
                case 'success':
                    return <Redirect to={personStore.redirectPath} />;
                case 'expired':
                    return <Redirect to={clientRoute.registrationInfoExpired} />;
                case 'not-found':
                    return <Redirect to={clientRoute.registrationInfoNotFound} />;
                default:
                    return null;
            }
        };

        return (
            <TotBackground>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Box>{renderByStatus()} </Box>
                </Grid>
            </TotBackground>
        );
    },
);
