const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Заявки',
    withoutNumber: 'Без номера',
    RequestN: 'Заявка № {number}',
    registryColumns: {
        number: 'Номер заявки',
        author: 'Автор',
        campaign: 'Категория',
        regForm: 'Форма заявки',
        state: 'Состояние',
        created: 'Дата создания',
        sent: 'Дата подачи',
        executor: 'Ответственный исполнитель',
    },
    modalTitle: 'Изменение статуса',
    modalInfoText: 'Статус будет изменен на "{toState}"',
    registryPagingInfo:
        'Всего {count, number} заяв{count, plural, one {ка} few {ки} other {ок}}. ' +
        'Отображены c {from, number} по {to, number}',
    invalidDateMessage: 'Недопустимый формат даты',
    maxDateMessage: 'Дата не может быть позже текущей даты',
    confirmDeletionInfoText: 'Вы действительно хотите удалить заявку № {number}?',
    resultsOption: 'Результаты не найдены',
    readonlyRequestValidationMsg:
        'Форма заявки заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    printFormsTable: {
        loading: 'Пожалуйста подождите, идет подготовка к генерации печатных форм документов',
        title: 'Печатные формы документов',
        fileColumnName: 'Файл',
        authorColumnName: 'ФИО',
        dateColumnName: 'Дата',
        generateAction: 'Сгенерировать',
        updateAction: 'Обновить',
        cannotGenerateError: 'Невозможно сгенерировать печатную форму: форма заявки заполнена некорректно',
        cannotGenerateErrorWithLink:
            'Невозможно сгенерировать печатную форму: форма заявки заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
        generationError: 'При генерации печатной формы произошла ошибка',
        downloadAllButton: 'Скачать все',
        taskStatus: 'Статус генерации',
    },
    taskStatus: {
        generating: 'Идет генерация',
        completed: 'Сгенерирован',
        failed: 'Ошибка генерации',
    },
    regFormCard: {
        description: 'Описание',
        registrationStartDate: 'Начало регистрации',
        registrationEndDate: 'Окончание регистрации',
        afterDeadline: 'Прием после дедлайна',
        reFeed: 'Повторная подача',
        instruction: 'Инструкция',
    },
    regFormsPageTitle: 'Выбор формы участия заявки',
    createRequest: 'Создать заявку',
    newRequest: 'Новая заявка',
    dialog: {
        closeMessage: 'Вы действительно хотите закрыть режим редактирования без сохранения изменений?',
        leaveMessage: 'Вы действительно хотите покинуть текущую заявку без сохранения изменений?',
        tooltipMessage: ' Для сохранения внесенных изменений нажмите кнопку "Сохранить" внизу страницы',
        title: 'Подтверждение закрытия режима редактирования',
        confirm: 'Подтвердить',
        cancel: 'Отмена',
        executorsTitle: 'Подтверждение выбора ответственного исполнителя',
        executorsMessage: 'Вы будете назначены ответственным исполнителем по заявке',
        editExecutorTitle: 'Назначить ответственного исполнителя',
        changeExecutorTitle: 'Изменить ответственного исполнителя',
    },
    registryPagingInfoIfNull: 'Всего 0 заявок. Отображено 0 заявок',
    allFiles: 'Все документы по {title}',
    takeToWork: 'Взять в работу',
    deleteRequest: 'Удалить заявку',
    openEditMode: 'Редактировать',
    saveChanges: 'Сохранить',
    chooseExecutor: 'Назначить ответственного',
    actions: {
        appointExecutor: 'Назначить ответственного',
        changeExecutor: 'Изменить ответственного',
        requestToWork: 'Взять заявку в работу',
    },
    expandWorkArea: 'Увеличить рабочую область',
    reduceWorkArea: 'Уменьшить рабочую область',
    deadlines: {
        entity: 'Общий срок завершения',
        state: 'Контрольный срок',
        error: 'Ошибка. Не удалось рассчитать срок',
        notRelevant: 'Не актуален в этом статусе',
        notRelevantTooltip:
            'Время нахождения в этом статусе не учитывается в общей длительности работ. Срок завершения будет пересчитан при переходе в другой статус',
    },
};

export default campaignRequest;
