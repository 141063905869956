import React from 'react';
import { clientRoute } from '../../../clientRoute';
import { generatePath, NavLink } from 'react-router-dom';
import { RoutedTabs as RoutedTabsInj, OuterTabsProps } from '../../../components/tabs/RoutedTabs';
import { Tab, TabsActions, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { transitionDefault } from '../../../themes/pages/app/vars/transitions';
import { RequestModel } from '../../../models/RequestModel';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di';
import { ApprovalTabLabel } from '@platform/approval-ui';
import { useStore } from '../../../hooks';

export const CampaignRequestStyledTab = withTheme(styled(Tab)`
    background-color: ${({ theme }) => `${theme.variables.palette.tab.backgroundColor} !important`};
    color: ${({ theme }) => `${theme.variables.palette.tab.color} !important`};
    margin-right: 12px !important;
    min-height: 36px !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    opacity: 1 !important;
    padding: 7px 12px 7px !important;
    transition: ${transitionDefault};
    min-width: 150px !important;

    &:hover,
    &.MuiTab-textColorInherit.Mui-selected {
        background-color: ${({ theme }) => `${theme.variables.palette.tab.hoverBackgroundColor} !important`};
        color: ${({ theme }) => `${theme.variables.palette.tab.hoverColor} !important`};
        transition: ${transitionDefault};
    }

    & > .MuiTab-wrapper {
        display: block;

        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        & > svg {
            margin-bottom: 0 !important;
            margin-right: 10px !important;
        }
    }
`);

export const useTabStylesInj = makeStyles(() => ({
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const tabsProps: OuterTabsProps = {
    TabIndicatorProps: {
        style: { display: 'none' },
    },
    variant: 'scrollable',
    scrollButtons: 'auto',
};

export type CampaignRequestTabsProps = {
    model: RequestModel;
    tabsActions: React.Ref<TabsActions>;
};

export const CampaignRequestTabs = observer(
    (props: CampaignRequestTabsProps): JSX.Element => {
        const { model, tabsActions } = props;
        const { id } = model;

        const [RoutedTabs] = di([RoutedTabsInj], CampaignRequestTabs);
        const [StyledTab] = di([CampaignRequestStyledTab], CampaignRequestTabs);
        const [useTabStyles] = di([useTabStylesInj], CampaignRequestTabs);

        const classes = useTabStyles();

        const settings = model.formsTabs
            .map((f, index) => {
                const formId = f.id;

                return [
                    {
                        tab: index,
                        path: generatePath(clientRoute.requestForm, { id, formId }),
                        exact: true,
                    },
                    {
                        tab: index,
                        path: generatePath(clientRoute.requestFormEdit, { id, formId }),
                        exact: true,
                    },
                ];
            })
            .reduce((a, b) => a.concat(b), []);

        const settingsLength = settings.length;
        const approvalListPath = generatePath(clientRoute.requestApprovalSheets, { id });

        const { approvalStore, intlStore } = useStore();
        const { hasApprovalTab } = approvalStore;
        if (hasApprovalTab) {
            settings.push({
                tab: settingsLength,
                path: approvalListPath,
                exact: true,
            });
        }
        const approvalTabLabel = <ApprovalTabLabel locale={intlStore.locale} />;

        return (
            <RoutedTabs customTabsActions={tabsActions} tabsProps={{ ...tabsProps, classes }} settings={settings}>
                {model.formsTabs.map((formInfo, index) => {
                    const formId = formInfo.id;
                    const formTitle = formInfo.title;

                    return (
                        <StyledTab
                            key={formId}
                            value={index}
                            title={formTitle}
                            label={formTitle}
                            component={NavLink}
                            to={generatePath(clientRoute.requestForm, { id, formId })}
                        />
                    );
                })}
                {hasApprovalTab && (
                    <StyledTab
                        key="approvalTab"
                        value={settingsLength}
                        label={approvalTabLabel}
                        component={NavLink}
                        to={approvalListPath}
                    />
                )}
            </RoutedTabs>
        );
    },
);
