const common: Record<string, string> = {
    edit: 'Редактировать',
    save: 'Сохранить',
    delete: 'Удалить',
    cancel: 'Отмена',
    discardChanges: 'Отменить изменения',
    close: 'Закрыть',
    error: 'Ошибка',
    errorText: 'Что-то пошло не так',
    confirm: 'Подтвердить',
    noData: 'Нет данных',
    from: 'c',
    to: 'до',
    until: 'по',
    State: 'Состояние',
    rowsPerPage: 'Отображать по:',
    pagingInfo: 'Отображено c {from, number} по {to, number}. Всего элементов {count, number}',
    resetFilters: 'Сбросить',
    filters: 'Фильтры',
    all: 'Все',
    actions: 'Действия',
    export: 'Выгрузить',
    confirmDeletion: 'Подтвердите удаление',
    dropzoneText: 'Перетащите или выберите файл',
    pageNotFound: 'Страница не найдена',
    actionNotAllowed: 'У вас недостаточно прав для совершения данного действия',
    downloadFile: 'Скачать {fileName}',
    defaultErrorMessage: 'Что-то пошло не так',
    yes: 'Да',
    no: 'Нет',
    lifeCycleConfirmTitle: 'Изменение статуса',
    lifeCycleConfirmText: 'Статус будет изменен на "{toState}"',
    tabExecutor: 'Ответственный исполнитель: {name}',
    responsibleExecutor: 'Ответственный исполнитель:',
};

export default common;
