const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Categories',
    noCategoriesText: 'There are currently no active categories to apply for',
    newCampaign: 'New category',
    titleCreateCampaignPage: 'Create category',
    create: 'Create',
    createCampaign: 'Create category',
    editCampaign: 'Edit category',
    sections: 'Sections',
    description: 'Description: ',
    files: 'Files',
    campaignPeriod: 'Campaign period',
    createRequest: 'Create request',
    campaignRequests: 'Campaign requests',
    campaignSettings: 'Campaign settings',
    tabsSettings: 'Tabs settings',
    pfTemplates: 'PF templates',
    noTemplates: 'No templates configured',

    campaignDescription: 'Campaign description',
    campaignDescriptionEdit: 'Campaign editing',

    campaignRegForms: 'Request forms',
    campaignRegForm: 'Request {count, plural, one {form} other {forms}}',
    addCampaignRegFormButton: 'Add request form',
    newCampaignRegForm: 'New request form',
    editCampaignRegForm: 'Editing the "{formTitle}" request form',
    deleteCampaignRegForm: 'Delete request form',
    confirmDeleteCampaignRegForm: 'Are you sure you want to delete the request form "{formTitle}"?',
    noRegForms: 'There is no registration forms',
    regFormInfo: 'Primary information',
    regFormExecutors: 'Responsible executors',

    templatesAndExport: 'Templates and export',
    campaignManagement: 'Category management',
    export: 'Export',
    templates: 'Templates',
    addTemplate: 'Add template',
    editTemplate: 'Edit template',
    editPermission: 'Edit permission',
    generate: 'Generate',
    accessSetting: 'Access setting',
    confirmDeletionTemplateInfoText: 'Are you sure you want to delete the template "{title}"?',
    lifecycle: 'Lifecycle',
    tabSettingForm: {
        title: "Tab's title",
        identifier: 'Identifier',
        formCode: "Form's code",
        fileSize: 'The size of the package of uploaded documents in MB',
        executor: 'Responsible executor',
        access: 'Access',
        edit: 'Edit',
        view: 'View',
    },
    tabSettingFormTable: {
        role: 'Role',
        status: 'Lifecycle status',
        access: 'Access',
        allStatuses: 'All statuses',
    },
    templateForm: {
        title: 'Title',
        description: 'Description',
        regForms: 'Request forms',
        file: 'Template file',
        templateIsRequired: 'Template is required for generation',
    },
    templateFormErrors: {
        notUniqueTemplateName: 'Template name must be unique',
        executorsNotAllowed:
            'This method of appointment is not available, since the method of assigning persons in charge has been selected in fact',
    },
    documentCategory: 'Document category',
    tabs: 'Tabs',

    campaignManagementDelete: 'Are you sure you want to delete the category "{campaignName}"?',
    deleteCampaign: 'Delete category',
    campaignTitle: 'Category management {campaignName}',
    generalSettings: 'General Settings',

    requestFormSettingsTitle: 'Setting for request tabs',
    requestFormSetting: 'Tab setting',
    createRequestFormSettings: 'Create tab',
    confirmDeletionRequestFormSettingInfoText: 'Are you sure you want to delete the tab "{title}"?',
    requestFormSettings: {
        title: 'Title',
        formTitle: 'Form',
    },

    fileFormat: 'Format',
    campaignRegFormExecutorEmployee: 'Employee',

    campaignRegFormExecutorResponsibleExecutor: 'Responsible executor',
    campaignRegFormExecutorRequestTab: 'Application tab',

    campaignRegFormExecutorAdd: 'Add',

    confirmDeletionInfoText: 'Are you sure you want to remove the responsible executive {executorName}?',

    employeeLabel: 'Employee',
    requestTabLabel: 'Request tab',
    editFormDialogTitle: 'Change responsible executor',

    collectionCompleted: 'Collection completed',
    createRequestError: 'Creation of requests by category is not available',
};

export default campaign;
