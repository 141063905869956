import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CampaignPage as CampaignPageInj } from './campaign-page/CampaignPage';
import { CampaignListPage as CampaignListPageInj } from './campaign-list-page/CampaignListPage';
import { CampaignSettingsPage as CampaignSettingsPageInj } from './campaign-settings/CampaignSettingsPage';
import { CreateCampaignPage as CreateCampaignPageInj } from './campaign-create/CreateCampaignPage';
import { RegFormSelectPage as RegFormSelectPageInj } from '../campaign-request/regform-select-page/RegFormSelectPage';
import { Authentication as AuthenticationInj } from '../../components/Authentication';
import { di } from 'react-magnetic-di';

export const AdditionalCampaignPagesInj = (): JSX.Element | null => null;

export const CampaignPages = (): JSX.Element => {
    const [RegFormSelectPage] = di([RegFormSelectPageInj], CampaignPages);
    const [CampaignPage] = di([CampaignPageInj], CampaignPages);
    const [CampaignListPage] = di([CampaignListPageInj], CampaignPages);
    const [CampaignSettingsPage] = di([CampaignSettingsPageInj], CampaignPages);
    const [CreateCampaignPage] = di([CreateCampaignPageInj], CampaignPages);
    const [Authentication] = di([AuthenticationInj], CampaignPages);
    const [AdditionalCampaignPages] = di([AdditionalCampaignPagesInj], CampaignPages);

    return (
        <Authentication>
            <Switch>
                {AdditionalCampaignPages()}
                <Route path={clientRoute.regFormSelect}>
                    <RegFormSelectPage />
                </Route>
                <Route path={clientRoute.campaignSettings}>
                    <CampaignSettingsPage />
                </Route>
                <Route exact path={clientRoute.campaign}>
                    <CampaignPage />
                </Route>
                <Route exact path={clientRoute.createCampaign}>
                    <CreateCampaignPage />
                </Route>
                <Route exact route={clientRoute.campaigns}>
                    <CampaignListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
