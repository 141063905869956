import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { generatePath, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Form, FormApi, FormModel } from '@platform/formiojs-react';
import {
    ConfirmationDialog as ConfirmationDialogInj,
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
} from '../../../../components';
import { clientRoute } from '../../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { useModal, useStore, useFormStyles, useError } from '../../../../hooks';
import { CampaignRegFormTabs as CampaignRegFormTabsInj } from './components';
import { di } from 'react-magnetic-di';
import { CampaignRegFormRouteParams } from './types';
import { getAdditionalCampaignRegFormTabsRoutes } from '../../../../utils/AdditionalLinks';

export const CampaignRegForm = observer(
    (): JSX.Element => {
        const [ConfirmationDialog] = di([ConfirmationDialogInj], CampaignRegForm);
        const [ErrorDialog] = di([ErrorDialogInj], CampaignRegForm);
        const [ErrorMessage] = di([ErrorMessageInj], CampaignRegForm);
        const [CampaignRegFormTabs] = di([CampaignRegFormTabsInj], CampaignRegForm);

        const classes = useFormStyles();
        const history = useHistory();
        const { id, rfId } = useParams<CampaignRegFormRouteParams>();
        const [open, setModalIsOpen, setModalIsClosed] = useModal();
        const { campaignsStore, intlStore, notificationStore } = useStore();
        const { locale } = intlStore;

        const [form, setForm] = useState<FormApi>();
        const [model] = useState<FormModel>(new FormModel(rfId));
        const { form: formFromModel, getLangSubmission } = model;

        useEffect(() => {
            campaignsStore.loadRegForm(rfId).then(model.load);
        }, [campaignsStore, rfId, model, form]);

        const onFormReady = (form: FormApi): void => {
            setForm(form);
        };

        const onSubmit = (): void => {
            if (form && form.validate()) {
                form.submit(false)
                    .then(() => {
                        return campaignsStore.saveRegForm(rfId, form.getSubmission());
                    })
                    .then(() => {
                        history.push(generatePath(clientRoute.campaignRegForm, { id, rfId }));
                    })
                    .catch((err) => {
                        notificationStore.onError(err.response.data);
                    });
            }
        };

        const onDeleteLinkClick = (): void => {
            setModalIsOpen();
        };

        const [isError, errorText, enableError, resetError] = useError();

        const handleErrorDeletion = (error: AxiosError): void => {
            const errorText = ErrorMessage(error);
            enableError(errorText);
        };

        const onConfirmDelete = async (): Promise<void> => {
            try {
                await campaignsStore.deleteRegForm(rfId);
                history.push(generatePath(clientRoute.campaignRegForms, { id, rfId }));
            } catch (error) {
                handleErrorDeletion(error);
            }
            setModalIsClosed();
        };

        const titleSubmission = useMemo((): string => {
            const submission = getLangSubmission(locale);
            return formFromModel && submission && submission.data && (submission.data as any).title;
        }, [formFromModel, getLangSubmission, locale]);

        return (
            <React.Fragment>
                <ErrorDialog message={errorText} open={isError} onClose={resetError} />
                <ConfirmationDialog
                    id="confirm-delete-rf"
                    keepMounted
                    open={open}
                    onConfirm={onConfirmDelete}
                    onCancel={setModalIsClosed}
                    title={<FormattedMessage id="campaign.deleteCampaignRegForm" />}
                    message={
                        <FormattedMessage
                            id="campaign.confirmDeleteCampaignRegForm"
                            values={{ formTitle: titleSubmission }}
                        />
                    }
                />

                <Switch>
                    <Route
                        path={[
                            clientRoute.campaignRegForm,
                            clientRoute.campaignRegFormExecutors,
                            clientRoute.campaignRegFormTabsSettings,
                            clientRoute.campaignRequestFormTabSetting,
                            clientRoute.campaignRequestFormTabSettingCreate,
                            clientRoute.campaignRegFormTemplates,
                            clientRoute.campaignRegFormTemplatesCreate,
                            clientRoute.campaignRegFormTemplatesEdit,
                            clientRoute.campaignRegFormTemplatesPermissionSettings,
                            ...getAdditionalCampaignRegFormTabsRoutes(),
                        ]}
                        exact
                    >
                        <Grid container direction="column" spacing={5}>
                            <Grid item container spacing={5}>
                                <Grid item>
                                    <Typography variant="h1">{titleSubmission}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <CampaignRegFormTabs
                                    model={model}
                                    onFormReady={onFormReady}
                                    onDeleteLinkClick={onDeleteLinkClick}
                                />
                            </Grid>
                        </Grid>
                    </Route>

                    <Route
                        path={[clientRoute.campaignRegFormCreate, clientRoute.campaignRegFormEdit]}
                        exact
                        key={clientRoute.campaignRegFormEdit}
                    >
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Typography variant="h1">
                                    <Switch>
                                        <Route path={clientRoute.campaignRegFormCreate}>
                                            <FormattedMessage id="campaign.newCampaignRegForm" />
                                        </Route>
                                        <Route path={clientRoute.campaignRegFormEdit}>
                                            <FormattedMessage
                                                id="campaign.editCampaignRegForm"
                                                values={{ formTitle: titleSubmission }}
                                            />
                                        </Route>
                                    </Switch>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Form
                                    className={classes.formWithNoOffset}
                                    locale={locale}
                                    form={model}
                                    onFormReady={onFormReady}
                                />
                                <Grid container spacing={5}>
                                    <Grid item>
                                        <Button color="secondary" variant="contained" onClick={onSubmit}>
                                            <FormattedMessage id="common.save" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Switch>
                                            <Route path={clientRoute.campaignRegFormCreate}>
                                                <Button
                                                    component={NavLink}
                                                    variant="text"
                                                    to={generatePath(clientRoute.campaignRegForms, { id })}
                                                >
                                                    <FormattedMessage id="common.cancel" />
                                                </Button>
                                            </Route>
                                            <Route path={clientRoute.campaignRegFormEdit}>
                                                <Button
                                                    component={NavLink}
                                                    variant="text"
                                                    to={generatePath(clientRoute.campaignRegForm, { id, rfId })}
                                                >
                                                    <FormattedMessage id="common.cancel" />
                                                </Button>
                                            </Route>
                                        </Switch>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Route>
                </Switch>
            </React.Fragment>
        );
    },
);
