import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { DateUtils } from '../../utils/DateUtils';
import { Api } from '../../store/Api';
import { FileDTO } from '../FileModel';

export type RegFormDTO = {
    id: string;
    title: string;
    procedureTitle: string;
    regStart: string; // date
    regEnd: string; // date
    allowAfterDeadline: boolean;
    reEnrollAllowed: boolean;
    fileInstruction: Array<FileDTO>;
};

export type RegForm = {
    id: string;
    title: string;
    procedureTitle: string;
    regStart?: Date; // date
    regEnd?: Date; // date
    allowAfterDeadline: boolean;
    reEnrollAllowed: boolean;
    fileInstruction: Array<FileDTO>;
};

export class RegFormListModel {
    @observable regFormsData: RegForm[] = [];

    private api: Api;

    constructor(api: Api, campaignId: string) {
        this.api = api;
        this.getRegFormsData(campaignId);
    }

    @action.bound
    getRegFormsData(campaignId: string): void {
        this.api
            .client(apiConfigs.regForms(campaignId, false))
            .then((r) => r.data)
            .then((data) => data.map(this.mapDTO))
            .then((data) => (this.regFormsData = data));
    }

    @action.bound
    mapDTO(dto: RegFormDTO): RegForm {
        const regStart = new Date(dto.regStart);
        const regEnd = new Date(dto.regEnd);
        return {
            id: dto.id,
            title: dto.title,
            procedureTitle: dto.procedureTitle,
            regStart: DateUtils.isValidDate(regStart) ? regStart : undefined,
            regEnd: DateUtils.isValidDate(regEnd) ? regEnd : undefined,
            allowAfterDeadline: dto.allowAfterDeadline,
            reEnrollAllowed: dto.reEnrollAllowed,
            fileInstruction: dto.fileInstruction,
        };
    }
}
