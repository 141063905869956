import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { RegFormCard as RegFormCardInj } from './RegFormCard';
import { RegFormListModel } from '../../../models/regform-list-model/RegFormListModel';
import { BreadcrumbsComponent as BreadcrumbsComponentInj } from '../../../components/breadcrumbs/BreadcrumbsComponent';
import { useStore } from '../../../hooks/useStore';
import { RegFormSelectPageRouteParams } from './types';
import { di } from 'react-magnetic-di';

export type RegFormSelectProps = {
    regFormListModel: RegFormListModel;
    createRequest: (id: string) => () => Promise<void>;
};

export const RegFormSelect = observer(
    (props: RegFormSelectProps): JSX.Element => {
        const { regFormListModel, createRequest } = props;

        const [RegFormCard] = di([RegFormCardInj], RegFormSelect);
        const [BreadcrumbsComponent] = di([BreadcrumbsComponentInj], RegFormSelect);

        const { campaignsStore, breadcrumbsStore } = useStore();
        const { campaignId } = useParams<RegFormSelectPageRouteParams>();

        const { breadCrumbsSettings } = breadcrumbsStore;

        const renderRegFormItems = (): JSX.Element[] => {
            return regFormListModel.regFormsData.map((regForm, index) => {
                return (
                    <RegFormCard
                        key={regForm.id}
                        index={index}
                        afterDeadline={regForm.allowAfterDeadline}
                        description={regForm.procedureTitle}
                        reEnrollAllowed={regForm.reEnrollAllowed}
                        registrationEndDate={regForm.regEnd}
                        registrationStartDate={regForm.regStart}
                        titleRegForm={regForm.title}
                        fileInstructions={regForm.fileInstruction}
                        onCreate={createRequest(regForm.id)}
                        onDownloadFile={campaignsStore.downloadRegFormFile}
                    />
                );
            });
        };

        const regFormsCount = regFormListModel.regFormsData.length;

        return (
            <Container maxWidth="lg">
                <Box pt={5.5} pb={5.5}>
                    <Box pb={5}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} objectId={campaignId} />
                    </Box>
                    <Box pb={7} width="100%">
                        <Typography variant="h1">
                            <FormattedMessage id="campaignRequest.regFormsPageTitle" />
                        </Typography>
                    </Box>
                    {!regFormsCount ? (
                        <Box pt={5}>
                            <Typography variant="body2">
                                <FormattedMessage id="campaign.noRegForms" />
                            </Typography>
                        </Box>
                    ) : (
                        <Box>{renderRegFormItems()}</Box>
                    )}
                </Box>
            </Container>
        );
    },
);
