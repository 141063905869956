import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { TotTablePagination as TotTablePaginationInj } from '../../../components/pagination/TotTablePagination';
import { clientRoute } from '../../../clientRoute';
import { UserListModel, UserRowDTO, UserStatusCode } from '../../../models/users/UserListModel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { UserListFilterPanel as UserListFilterPanelInj } from './UserListFilterPanel';
import { ErrorDialog as ErrorDialogInj } from '../../../components/ErrorDialog';
import { AxiosError } from 'axios';
import { ErrorMessage as ErrorMessageInj } from '../../../components/ErrorMessage';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { useError } from '../../../hooks/useError';
import { BreadcrumbsComponent as BreadcrumbsComponentInj } from '../../../components/breadcrumbs/BreadcrumbsComponent';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../authSchemeConfig';
import { UserListBlockButton as UserListBlockButtonInj } from './UserListBlockButton';
import { UserListDeleteButton as UserListDeleteButtonInj } from './UserListDeleteButton';
import { di } from 'react-magnetic-di';

export const UserListPage = observer(
    (): JSX.Element => {
        const [UserListBlockButton] = di([UserListBlockButtonInj], UserListPage);
        const [UserListDeleteButton] = di([UserListDeleteButtonInj], UserListPage);
        const [TotTablePagination] = di([TotTablePaginationInj], UserListPage);
        const [UserListFilterPanel] = di([UserListFilterPanelInj], UserListPage);
        const [ErrorDialog] = di([ErrorDialogInj], UserListPage);
        const [ErrorMessage] = di([ErrorMessageInj], UserListPage);
        const [BreadcrumbsComponent] = di([BreadcrumbsComponentInj], UserListPage);
        const [AuthorizationCheck] = di([AuthorizationCheckInj], UserListPage);

        const [isError, errorText, enableError, resetError] = useError();
        const { userStore, breadcrumbsStore } = useStore();
        const { breadCrumbsSettings } = breadcrumbsStore;
        const [model] = useState<UserListModel>(() => new UserListModel(userStore));

        const handleErrorDeletion = (error: AxiosError): void => {
            const errorText = ErrorMessage(error);
            enableError(errorText);
        };

        const blockUser = (id: string): (() => Promise<void>) => {
            return () => model.blockUser(id)().catch(handleErrorDeletion);
        };

        const unblockUser = (id: string): (() => Promise<void>) => {
            return () => model.unblockUser(id)().catch(handleErrorDeletion);
        };

        const deleteUser = (id: string): (() => Promise<void>) => {
            return () => model.deleteUser(id)().catch(handleErrorDeletion);
        };

        const renderBlockUnblock = (user: UserRowDTO, allowed: boolean): JSX.Element => {
            const { id, title } = user.login;
            return user.status === 'blocked' ? (
                <Tooltip
                    title={
                        allowed ? (
                            <FormattedMessage id="users.actions.unblock" />
                        ) : (
                            <FormattedMessage id="users.notAllowed" />
                        )
                    }
                >
                    <span>
                        <IconButton disabled={!allowed} onClick={unblockUser(id)}>
                            <PersonAddIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            ) : (
                <UserListBlockButton
                    id="block-user"
                    title={<FormattedMessage id="users.confirmBlock" />}
                    message={<FormattedMessage id="users.confirmBlockInfoText" values={{ login: title }} />}
                    onConfirm={blockUser(id)}
                    wrappedComponentProps={{ allowed }}
                />
            );
        };

        const renderBody = (): JSX.Element[] => {
            return model.rows.map((user) => {
                const { id, title } = user.login;
                return (
                    <TableRow key={id} hover>
                        <TableCell>
                            <Link component={NavLink} underline="always" to={generatePath(clientRoute.user, { id })}>
                                {title}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id={statusTextId(user.status)} />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.roles}</TableCell>
                        <TableCell>
                            <AuthorizationCheck
                                entityId={id}
                                entityCode={entities.User}
                                permCode={permissions.User.Update}
                            >
                                {(allowed) => (
                                    <Box style={{ whiteSpace: 'nowrap' }}>
                                        {renderBlockUnblock(user, allowed)}
                                        <UserListDeleteButton
                                            id="delete-user"
                                            title={<FormattedMessage id="users.deleteUser" />}
                                            message={
                                                <FormattedMessage
                                                    id="users.deleteUserConfirmText"
                                                    values={{ login: title }}
                                                />
                                            }
                                            onConfirm={deleteUser(id)}
                                            wrappedComponentProps={{ allowed }}
                                        />
                                    </Box>
                                )}
                            </AuthorizationCheck>
                        </TableCell>
                    </TableRow>
                );
            });
        };

        const statusTextId = (statusCode: UserStatusCode): string => {
            switch (statusCode) {
                case 'active':
                    return 'users.status.active';
                case 'blocked':
                    return 'users.status.blocked';
                case 'inactive':
                    return 'users.status.inactive';
            }
        };

        useEffect(() => {
            return model.dispose;
        }, [model]);

        return (
            <React.Fragment>
                <ErrorDialog message={errorText} open={isError} onClose={resetError} />
                <Container maxWidth="lg">
                    <Box pt={5.5} pb={15}>
                        <Box pb={5}>
                            <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                        </Box>
                        <Box pb={10}>
                            <Typography variant="h1">
                                <FormattedMessage id="users.listTitle" />
                            </Typography>
                        </Box>
                        <Box pb={10}>
                            <UserListFilterPanel model={model} />
                        </Box>
                        <Box>
                            <TableContainer component={Paper}>
                                {model.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.login" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.status" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.name" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                <Typography>
                                                    <FormattedMessage id="users.fields.roles" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderBody()}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={100}>
                                                <TotTablePagination
                                                    count={model.rowsCount}
                                                    page={model.pageNumber}
                                                    onChangePage={model.onChangePage}
                                                    onChangeRowsPerPage={model.onChangePageSize}
                                                    rowsPerPage={model.pageSize}
                                                    rowsPerPageOptions={model.pageSizeOptions}
                                                    pagingInfoMessageId="users.registryPagingInfo"
                                                    pagingInfoMessageIdIfNull="users.registryPagingInfoIfNull"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Container>
            </React.Fragment>
        );
    },
);
