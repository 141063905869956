import { action, observable } from 'mobx';
import { History } from 'history';
import { FormioSidebarStore } from '@platform/formiojs-react';
import { IntlStore, getIntlStore, Locales } from './IntlStore';
import { AuthenticationStore, getAuthenticationStore } from './AuthenticationStore';
import { Api, getApi } from './Api';
import { PersonStore, getPersonStore } from './PersonStore';
import { FormioStore, getFormioStore } from './FormioStore';
import { CampaignsStore, getCampaignsStore } from './CampaignsStore';
import { RequestStore, getRequestStore } from './RequestStore';
import { AuthorizationStore, getAuthorizationStore } from './AuthorizationStore';
import { CatalogStore, getCatalogStore } from './CatalogStore';
import { NotificationStore, getNotificationStore } from './NotificationStore';
import { UserStore, getUserStore } from './UserStore';
import { PresetStore, getPresetStore } from './PresetStore';
import { LkNotificationStore, getLkNotificationStore } from './LkNotificationStore';
import { ModulesStore, getModulesStore } from './ModulesStore';
import { ApprovalStore, getApprovalStore } from './ApprovalStore';
import { AppTheme, ThemeOverrides } from '../themes/types/themeTypes';
import ru from '../resources/locales/ru';
import en from '../resources/locales/en';
import { Env, RootStoreProps } from '../types/stores/rootStore';
import { getSignatureStore, SignatureStore } from './SignatureStore';
import { FormioSignatureStore, getFormioSignatureStore } from './FormioSignatureStore';
import { HeaderHeightStore, getHeaderHeightStore } from './HeaderHeightStore';
import { HeaderLinksStore, getHeaderLinksStore } from './HeaderLinksStore';
import { BreadcrumbsStore, getBreadcrumbsStore } from './BreadcrumbsStore';

export class RootStore {
    @observable history: History;

    @observable api: Api;
    @observable authenticationStore: AuthenticationStore;
    @observable authorizationStore: AuthorizationStore;
    @observable intlStore: IntlStore;
    @observable userStore: UserStore;
    @observable personStore: PersonStore;
    @observable formioStore: FormioStore;
    @observable campaignsStore: CampaignsStore;
    @observable requestStore: RequestStore;
    @observable formioSidebarStore: FormioSidebarStore;
    @observable catalogStore: CatalogStore;
    @observable notificationStore: NotificationStore;
    @observable presetStore: PresetStore;
    @observable lkNotificationStore: LkNotificationStore;
    @observable modulesStore: ModulesStore;
    @observable signatureStore: SignatureStore;
    @observable formioSignatureStore: FormioSignatureStore;
    @observable approvalStore: ApprovalStore;
    @observable breadcrumbsStore: BreadcrumbsStore;

    @observable features: RootStoreProps['features'] = {};
    @observable env: Env = {} as Env;
    @observable themeOverrides: ThemeOverrides = {} as ThemeOverrides;
    @observable appTheme?: AppTheme;

    @observable headerHeightStore: HeaderHeightStore;
    @observable headerLinksStore: HeaderLinksStore;

    constructor(props: RootStoreProps) {
        const Api = getApi();
        const AuthenticationStore = getAuthenticationStore();
        const AuthorizationStore = getAuthorizationStore();
        const IntlStore = getIntlStore();
        const UserStore = getUserStore();
        const PersonStore = getPersonStore();
        const FormioStore = getFormioStore();
        const CampaignsStore = getCampaignsStore();
        const RequestStore = getRequestStore();
        const CatalogStore = getCatalogStore();
        const NotificationStore = getNotificationStore();
        const PresetStore = getPresetStore();
        const LkNotificationStore = getLkNotificationStore();
        const ModulesStore = getModulesStore();
        const SignatureStore = getSignatureStore();
        const FormioSignatureStore = getFormioSignatureStore();
        const HeaderHeightStore = getHeaderHeightStore();
        const HeaderLinksStore = getHeaderLinksStore();
        const ApprovalStore = getApprovalStore();
        const BreadcrumbsStore = getBreadcrumbsStore();

        this.setObservables(props);
        this.api = new Api(this);
        this.authenticationStore = new AuthenticationStore(this);
        this.authorizationStore = new AuthorizationStore(this);

        const { locales, history } = props;
        const messages: Locales = {
            ru: { ...ru, ...locales.ru },
            en: { ...en, ...locales.en },
        };
        this.intlStore = new IntlStore(this, messages);
        this.history = history;
        this.userStore = new UserStore(this);
        this.personStore = new PersonStore(this);
        this.formioStore = new FormioStore(this);
        this.campaignsStore = new CampaignsStore(this);
        this.requestStore = new RequestStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.catalogStore = new CatalogStore(this);
        this.notificationStore = new NotificationStore();
        this.presetStore = new PresetStore(this);
        this.lkNotificationStore = new LkNotificationStore(this);
        this.modulesStore = new ModulesStore(this);
        this.signatureStore = new SignatureStore(this);
        this.formioSignatureStore = new FormioSignatureStore(this);
        this.headerHeightStore = new HeaderHeightStore();
        this.headerLinksStore = new HeaderLinksStore();
        this.approvalStore = new ApprovalStore(this);
        this.breadcrumbsStore = new BreadcrumbsStore(this);
    }

    @action.bound
    setObservables(props: RootStoreProps) {
        const { env, features, themeOverrides } = props;
        this.env = env;
        this.features = features;
        this.themeOverrides = themeOverrides;
    }

    @action.bound
    setAppTheme(appTheme: AppTheme) {
        this.appTheme = appTheme;
        this.formioStore.addComponentsWithMaterialTheme();
    }
}
