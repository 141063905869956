const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Requests',
    withoutNumber: 'Without a number',
    RequestN: 'Request № {number}',
    registryColumns: {
        number: 'Number',
        author: 'Author',
        campaign: 'Category',
        regForm: 'Registration form',
        state: 'State',
        created: 'Created',
        sent: 'Sent',
        executor: 'Responsible executor',
    },
    modalTitle: 'Status change',
    modalInfoText: 'Status will be changed to "{toState}"',
    registryPagingInfo: 'Total {count, number} requests. Displayed from {from, number} to {to, number}.',
    invalidDateMessage: 'Invalid date format',
    maxDateMessage: 'Date should not be after than the current date',
    confirmDeletionInfoText: 'Are you sure you want to delete the request № {number}?',
    resultsOption: 'Results not found',
    readonlyRequestValidationMsg: 'The request form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    printFormsTable: {
        loading: 'Please, wait, preparation for the generation of printed forms of documents is in progress',
        title: 'Printed forms',
        fileColumnName: 'File',
        authorColumnName: 'Author',
        dateColumnName: 'Date',
        generateAction: 'Generate',
        updateAction: 'Update',
        cannotGenerateError: 'Printed form cannot be generated: request form is filled out incorrectly',
        cannotGenerateErrorWithLink:
            'Printed form cannot be generated: request form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
        generationError: 'An error occurred while generating the print form',
        downloadAllButton: 'Download all',
        taskStatus: 'Generation status',
    },
    taskStatus: {
        generating: 'Generation',
        completed: 'Generated',
        failed: 'Generation failed',
    },
    regFormCard: {
        description: 'Description',
        registrationStartDate: 'Registration start',
        registrationEndDate: 'Registration end',
        afterDeadline: 'Reception after deadline',
        reFeed: 'Re-feed',
        instruction: 'Instruction',
    },
    regFormsPageTitle: 'Selection of the application form',
    createRequest: 'Create request',
    newRequest: 'New request',
    dialog: {
        closeMessage: 'Are you sure you want to close edit mode without saving your changes?',
        leaveMessage: 'Are you sure you want to leave the current ticket without saving your changes?',
        tooltipMessage: 'To save the changes made, click the "Save" button on the page below.',
        title: 'Confirmation of closing edit mode',
        confirm: 'Confirm',
        cancel: 'Cancel',
        executorsTitle: 'Confirmation of the choice of the responsible executor',
        executorsMessage: 'You will be appointed as the responsible executor for the application',
        editExecutorTitle: 'Appoint a responsible executor',
        changeExecutorTitle: 'Change a responsible executor',
    },
    registryPagingInfoIfNull: 'Total 0 requests. Displayed 0 requests',
    allFiles: 'All documents for {title}',
    takeToWork: 'Take to Work',
    deleteRequest: 'Delete request',
    openEditMode: 'Edit',
    saveChanges: 'Save',
    chooseExecutor: 'Appoint a responsible executor',
    actions: {
        appointExecutor: 'Appoint a responsible executor',
        changeExecutor: 'Change a responsible executor',
        requestToWork: 'Take request to work',
    },
    expandWorkArea: 'Expand work area',
    reduceWorkArea: 'Reduce work area',
    deadlines: {
        entity: 'Total deadline',
        state: 'Deadline',
        error: 'Error. Failed to calculate deadline',
        notRelevant: 'Not relevant in this status',
        notRelevantTooltip:
            'The time spent in this status is not taken into account in the total duration of the work. Completion date will be recalculated upon transition to a different status',
    },
};

export default campaignRequest;
