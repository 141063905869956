import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { generatePath, NavLink, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Edit } from '@material-ui/icons';
import { Form, FormApi, FormModel } from '@platform/formiojs-react';
import { clientRoute } from '../../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../../hooks';

type RouteParams = {
    id: string;
};

export const CampaignForm = observer(
    (): JSX.Element => {
        const { id } = useParams<RouteParams>();
        const [form, setForm] = useState<FormApi>();
        const [model] = useState<FormModel>(new FormModel(id));
        const { campaignsStore, notificationStore } = useStore();
        const history = useHistory();
        const location = useLocation();

        useEffect(() => {
            campaignsStore.loadCampaignForm(id).then(model.load);
        }, [campaignsStore, id, model, location.pathname]);

        const onFormReady = (form: FormApi): void => {
            setForm(form);
        };

        const onSubmit = (): void => {
            if (form && form.validate()) {
                form.submit(false)
                    .then(() => {
                        return campaignsStore.saveCampaignForm(id, form.getSubmission());
                    })
                    .then(() => {
                        history.push(generatePath(clientRoute.campaignSettings, { id }));
                    })
                    .catch((err) => {
                        notificationStore.onError(err.response.data);
                    });
            }
        };

        return (
            <Switch>
                <Route path={clientRoute.campaignSettings} exact key={clientRoute.campaignSettings}>
                    <Grid container direction="column" spacing={5}>
                        <Grid item container spacing={5}>
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="campaign.campaignDescription" />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    startIcon={<Edit />}
                                    component={NavLink}
                                    to={generatePath(clientRoute.campaignSettingsEdit, { id })}
                                >
                                    <FormattedMessage id="common.edit" />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Form form={model} readOnly onFormReady={onFormReady} />
                        </Grid>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaignSettingsEdit} key={clientRoute.campaignSettingsEdit}>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="campaign.campaignDescriptionEdit" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Form form={model} onFormReady={onFormReady} />
                        </Grid>
                        <Grid item container spacing={5}>
                            <Grid item>
                                <Button color="secondary" variant="contained" onClick={onSubmit}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    component={NavLink}
                                    variant="text"
                                    to={generatePath(clientRoute.campaignSettings, { id })}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Route>
            </Switch>
        );
    },
);
