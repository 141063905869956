import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { FooterTypography as FooterTypographyInj } from './FooterTypography';
import { FooterContactInformation as FooterContactInformationInj } from './FooterContactInformation';
import { FooterLogo as FooterLogoInj } from './FooterLogo';
import { di } from 'react-magnetic-di';

export const FooterWrapperInj = withTheme(
    styled.footer`
        position: relative;
        padding: ${({ theme }) => `${theme.spacing(theme.variables.palette.footer.padding)}px 0`};
        background-color: ${({ theme }) => `${theme.variables.palette.appBar.mainContrast}`};
        bottom: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    `,
);

export const Footer = observer(
    (): JSX.Element => {
        const [FooterWrapper] = di([FooterWrapperInj], Footer);
        const [FooterTypography] = di([FooterTypographyInj], Footer);
        const [FooterLogo] = di([FooterLogoInj], Footer);
        const [FooterContactInformation] = di([FooterContactInformationInj], Footer);

        const { env } = useStore();
        const { totCopyright, totTel, totMail } = env;

        return (
            <FooterWrapper>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        alignItems="center"
                        justify="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <FooterTypography component="small" color="primary">
                                {totCopyright}
                            </FooterTypography>
                        </Grid>
                        <FooterContactInformation phoneNumberText={totTel} emailText={totMail} />
                        <FooterLogo />
                    </Grid>
                </Container>
            </FooterWrapper>
        );
    },
);
