const homePage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        requests: 'Мои заявки',
        requestsNew: 'Заявки',
        campaigns: 'Категории',
        users: 'Пользователи',
    },
};

export default homePage;
