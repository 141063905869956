import React from 'react';
import { observer, Provider } from 'mobx-react';
import { RootStore as RootStoreInj } from './store/RootStore';
import { StoreContext } from './StoreContext';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { App } from './App';
import { TotIntlProvider } from './components/TotIntlProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
//TODO: интегрировать в intlStore
import { ru } from 'date-fns/locale';
import { createAppTheme } from './createThemes';
import { RootStoreProps } from './types/stores/rootStore';
import { di, DiProvider } from 'react-magnetic-di';
import { useStore } from './hooks';
import { FeaturesProvider, useFeature } from 'feature-toggle-jsx';
import { YMetrika } from './components/YMetrika';
import { AppTheme } from './themes';

const RootUnwrapped = observer(
    (): JSX.Element => {
        const { appTheme } = useStore();
        const [yaMetrika] = useFeature('yaMetrika');
        return (
            <React.Fragment>
                {yaMetrika && <YMetrika />}
                <ThemeProvider theme={appTheme as AppTheme}>
                    <TotIntlProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                            <App />
                        </MuiPickersUtilsProvider>
                    </TotIntlProvider>
                </ThemeProvider>
            </React.Fragment>
        );
    },
);

const RootWithFeatures = observer(
    (): JSX.Element => {
        const { features } = useStore();
        return (
            <FeaturesProvider features={features as any}>
                <RootUnwrapped />
            </FeaturesProvider>
        );
    },
);

const RootWithStore = (props: RootStoreProps): JSX.Element => {
    const [RootStore] = di([RootStoreInj], RootWithStore);
    const store = new RootStore(props);
    const theme = createAppTheme(store, props.createAppThemeOptions);
    store.setAppTheme(theme);
    return (
        <Router history={store.history}>
            <Provider {...store}>
                <StoreContext.Provider value={store}>
                    <RootWithFeatures />
                </StoreContext.Provider>
            </Provider>
        </Router>
    );
};

export const Root = (props: RootStoreProps): JSX.Element => {
    return (
        <DiProvider use={props.use || []}>
            <RootWithStore {...props} />
        </DiProvider>
    );
};
