import { Box, Button, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useModal, useStore } from '../../../../../../hooks';
import { observer } from 'mobx-react';
import { Close } from '@material-ui/icons';
import { AccessPermission, RequestFormSettingModel } from '../../../../../../models';
import { clientRoute } from '../../../../../../clientRoute';
import { AutocompleteProps, textFieldProps } from '../../../../../campaign-request';
import { Select as SelectInj } from '../../../../../../components';
import { RequestFormPermissionSettingsTable as RequestFormPermissionSettingsTableInj } from '../RequestFormPermissionSettingsTable';
import { EditPermissionDialog as EditPermissionDialogInj } from './EditPermissionDialog';
import { di } from 'react-magnetic-di';

export type RequestFormTabSettingsDTO = {
    title: string;
    identifier: string;
    formCode: string;
    permissions: AccessPermission[];
    fileSize?: number;
    executorId?: string;
};

type RouteParams = {
    id: string;
    rfId: string;
    settingId: string;
};

export type RequestFormSettingDialogProps = {
    updateSetting?: (settingId: string, dto: RequestFormTabSettingsDTO) => Promise<void | number>;
    createSetting?: (dto: RequestFormTabSettingsDTO) => Promise<void>;
};

export const RequestFormTabsSettingDialog = observer(
    (props: RequestFormSettingDialogProps): JSX.Element => {
        const { updateSetting, createSetting } = props;

        const [Select] = di([SelectInj], RequestFormTabsSettingDialog);
        const [RequestFormTabSettingTable] = di([RequestFormPermissionSettingsTableInj], RequestFormTabsSettingDialog);
        const [EditPermissionDialog] = di([EditPermissionDialogInj], RequestFormTabsSettingDialog);

        const { campaignsStore, notificationStore, intlStore: intl } = useStore();
        const { id, settingId, rfId } = useParams<RouteParams>();
        const history = useHistory();
        const [settings] = useState<RequestFormSettingModel>(
            new RequestFormSettingModel(settingId, campaignsStore, intl),
        );

        const [currentPermission, setCurrentPermission] = useState<number>();
        const [editPermissionDialogOpen, setEditPermissionDialogOpen, setEditPermissionDialogClosed] = useModal();

        useEffect(() => {
            settings.loadData(rfId).finally(() => campaignsStore.getRequestFormSetting(settings, settingId));
        }, [campaignsStore, settings, settingId, rfId]);

        const closeDialog = useCallback(async (): Promise<void> => {
            history.push(generatePath(clientRoute.campaignRegFormTabsSettings, { id, rfId }));
        }, [id, rfId, history]);

        const submit = useCallback((): void => {
            settings.formValidationStarted = true;

            if (!settings.isValid) {
                return;
            }

            updateSetting &&
                updateSetting(settingId, settings.requestFormTabSettingsData)
                    .then(closeDialog)
                    .catch((err) => {
                        notificationStore.onError(err.response.data);
                    });

            createSetting &&
                createSetting(settings.requestFormTabSettingsData)
                    .then(closeDialog)
                    .catch((err: any) => {
                        notificationStore.onError(err.response.data);
                    });
            settings.formValidationStarted = false;
        }, [closeDialog, settingId, updateSetting, settings, notificationStore, createSetting]);

        const handleEditPermissionDialogOpen = useCallback(
            (index: number) => {
                setCurrentPermission(index);
                setEditPermissionDialogOpen();
            },
            [setCurrentPermission, setEditPermissionDialogOpen],
        );

        return (
            <React.Fragment>
                {currentPermission !== undefined && editPermissionDialogOpen && (
                    <EditPermissionDialog
                        open={editPermissionDialogOpen}
                        closeDialog={setEditPermissionDialogClosed}
                        currentPermission={currentPermission}
                        model={settings}
                    />
                )}
                <Dialog maxWidth="md" fullWidth open={true} scroll="body">
                    <Box pt={4} pr={4}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <IconButton onClick={closeDialog}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box pl={12} pr={12} pb={12}>
                        <Grid container justify="center">
                            <Grid item>
                                <Typography variant="h5">
                                    <Box fontWeight="fontWeightBold">
                                        <FormattedMessage id="campaign.requestFormSetting" />
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box pt={8}>
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item container spacing={4} direction="column" justify="center">
                                    <Grid item>
                                        <TextField
                                            {...textFieldProps}
                                            label={<FormattedMessage id="campaign.tabSettingForm.title" />}
                                            value={settings.title}
                                            onChange={settings.onChangeTitle}
                                            required
                                            error={!!settings.titleMessage}
                                            helperText={settings.titleMessage}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            {...textFieldProps}
                                            label={<FormattedMessage id="campaign.tabSettingForm.identifier" />}
                                            value={settings.identifier}
                                            onChange={settings.onChangeIdentifier}
                                            required
                                            error={!!settings.identifierMessage}
                                            helperText={settings.identifierMessage}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            multiple={false}
                                            values={settings.formCode}
                                            label={<FormattedMessage id="campaign.tabSettingForm.formCode" />}
                                            selectData={settings.formCodeList}
                                            onChange={settings.onChangeFormCode}
                                            textFieldProps={{
                                                ...textFieldProps,
                                                required: true,
                                                error: !!settings.formCodeMessage,
                                                helperText: settings.formCodeMessage,
                                            }}
                                            autoCompleteProps={{ ...AutocompleteProps }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            {...textFieldProps}
                                            label={<FormattedMessage id="campaign.tabSettingForm.fileSize" />}
                                            value={settings.fileSize}
                                            onChange={settings.onChangeFileSize}
                                            error={!!settings.fileSizeMessage}
                                            helperText={settings.fileSizeMessage}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            multiple={false}
                                            values={settings.executorId}
                                            label={<FormattedMessage id="campaign.tabSettingForm.executor" />}
                                            selectData={settings.executorsList}
                                            onChange={settings.onChangeExecutor}
                                            textFieldProps={{ ...textFieldProps }}
                                            autoCompleteProps={{ ...AutocompleteProps }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <RequestFormTabSettingTable
                                            model={settings}
                                            handleEditPermissionDialogOpen={handleEditPermissionDialogOpen}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Button color="primary" fullWidth size="large" variant="contained" onClick={submit}>
                                        <FormattedMessage id="common.save" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={closeDialog} fullWidth size="large" variant="contained">
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Dialog>
            </React.Fragment>
        );
    },
);
