import { di } from 'react-magnetic-di';
import { BreadcrumbsSettings, mainRoutes, names, routesWithParams } from '../components';
import { RootStore } from './RootStore';
import { computed, observable } from 'mobx';
import { Api } from './Api';

export class BreadcrumbsStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get mainPageRoute(): string {
        return '/';
    }

    @computed
    get names() {
        return names;
    }

    @computed
    get mainRoutes() {
        return mainRoutes;
    }

    @computed
    get routesWithParams() {
        return routesWithParams;
    }

    @computed
    get breadCrumbsSettings(): BreadcrumbsSettings {
        const { names, mainRoutes, routesWithParams } = this;

        return {
            names,
            mainRoutes,
            routesWithParams,
        };
    }
}

export const getBreadcrumbsStore = (): any => {
    const [_BreadcrumbsStore] = di([BreadcrumbsStore], getBreadcrumbsStore);
    return _BreadcrumbsStore;
};
