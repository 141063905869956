import React from 'react';
import { AuthorizationCheckQuery } from './AuthorizationStore';
import { clientRoute } from '../clientRoute';
import { entities, permissions } from '../authSchemeConfig';
import { match as Match } from 'react-router-dom';
import { Location } from 'history';
import { FormatListNumbered } from '@material-ui/icons';
import { ReactComponent as FileIcon } from '../resources/images/icons/file-document-box-multiple-outline.svg';
import { ReactComponent as FolderIcon } from '../resources/images/icons/folder.svg';
import PeopleIcon from '@material-ui/icons/People';
import { di } from 'react-magnetic-di';
import { computed } from 'mobx';

export type AppHeaderLinkData = {
    key: string;
    to: string;
    icon?: JSX.Element;
    messageID: string;
    query?: AuthorizationCheckQuery;
    isActiveCallback?: (match: Match, location: Location) => boolean;
};

export type AllowedLink = {
    link: AppHeaderLinkData;
    allowed: boolean;
};

export class HeaderLinksStore {
    @computed
    get mainLinks(): AppHeaderLinkData[] {
        return [
            {
                key: 'requests',
                to: clientRoute.requests,
                icon: <FormatListNumbered style={{ fontSize: '24px' }} />,
                isActiveCallback: (match: Match, location: Location): boolean => {
                    if (!match) {
                        return false;
                    }
                    return location.pathname !== clientRoute.requestsNew;
                },
                messageID: 'homePage.headerLinks.requests',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewCampRequestList,
                },
            },
            {
                key: 'requestsNew',
                to: clientRoute.requestsNew,
                icon: <FileIcon />,
                messageID: 'homePage.headerLinks.requestsNew',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewCampRequestRegistry,
                },
            },
            {
                key: 'campaigns',
                to: clientRoute.campaigns,
                icon: <FolderIcon />,
                messageID: 'homePage.headerLinks.campaigns',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewCampaignList,
                },
            },
            {
                key: 'users',
                to: clientRoute.users,
                icon: <PeopleIcon />,
                messageID: 'homePage.headerLinks.users',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewUserList,
                },
            },
        ];
    }

    @computed
    get additionalLinks(): AppHeaderLinkData[] {
        return [];
    }

    // На случай если будут особенно длинные ссылки
    // добавляем возможность переопределить для того, чтобы отображение не ломалось
    @computed
    get avgLinkWidth(): number {
        return 155;
    }
}

export const getHeaderLinksStore = (): any => {
    const [_HeaderLinksStore] = di([HeaderLinksStore], getHeaderLinksStore);
    return _HeaderLinksStore;
};
