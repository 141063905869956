import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { TotBackgroundWithAvailableHeader as TotBackgroundWithAvailableHeaderInj } from '../../TotBackground';
import { EmailField as EmailFieldInj } from '../../components/fields/EmailField';
import { clientRoute } from '../../clientRoute';
import { RecoveryPasswordModel } from '../../models/RecoveryPasswordModel';
import { useStore } from '../../hooks';
import { di } from 'react-magnetic-di';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useRecoveryPasswordPage = makeStyles(() =>
    createStyles({
        container: {
            padding: 0,
        },
    }),
);

export const RecoveryPasswordPage = observer(
    (): JSX.Element => {
        const [EmailField] = di([EmailFieldInj], RecoveryPasswordPage);
        const [TotBackgroundWithAvailableHeader] = di([TotBackgroundWithAvailableHeaderInj], RecoveryPasswordPage);

        const { authenticationStore } = useStore();
        const recoveryPasswordModel = useMemo<RecoveryPasswordModel>(() => authenticationStore.recoveryPasswordModel, [
            authenticationStore,
        ]);

        const classes = useRecoveryPasswordPage();

        return (
            <TotBackgroundWithAvailableHeader>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <Container className={classNames('t-recovery-password-page', classes.container)} maxWidth="xs">
                        <Paper elevation={0}>
                            <Box p={12}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Typography variant="h5" className="t-recovery-title">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="authentication.recoveryTitle" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box pt={8}>
                                    <form noValidate onSubmit={recoveryPasswordModel.sendToken}>
                                        <Grid container spacing={6} direction="column">
                                            <Grid item>
                                                <EmailField model={recoveryPasswordModel} />
                                            </Grid>
                                            <Grid item>
                                                <Typography className="t-recovery-info">
                                                    <FormattedMessage id="authentication.recoveryInfo" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="t-recovery"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="authentication.sendToken" />
                                                </Button>
                                            </Grid>
                                            <Grid item container justify="center">
                                                <Link
                                                    className="t-login"
                                                    component={RouterLink}
                                                    to={clientRoute.login}
                                                    underline="none"
                                                >
                                                    <FormattedMessage id="authentication.goBackToSignIn" />
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Paper>
                        {recoveryPasswordModel.sentSucceed && <Redirect to={clientRoute.recoveryPasswordInfoSuccess} />}
                    </Container>
                </Grid>
            </TotBackgroundWithAvailableHeader>
        );
    },
);
