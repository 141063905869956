import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { di } from 'react-magnetic-di';
import { SignatureSettings } from '@platform/crypto-ui';
import { observer } from 'mobx-react';

export const useDigitalSignatureCheckboxStylesInj = makeStyles(() => ({
    checkbox: {
        padding: '5px',
    },
}));

export type DigitalSignaturePfFormFieldsCheckboxProps = {
    signatureSettings: SignatureSettings;
    fieldName: keyof SignatureSettings;
    onChange: (e: ChangeEvent<HTMLInputElement>, fieldKey: keyof SignatureSettings) => void;
};

export const DigitalSignaturePfFormFieldsCheckbox = observer(
    (props: DigitalSignaturePfFormFieldsCheckboxProps): JSX.Element => {
        const [useDigitalSignatureCheckboxStyles] = di(
            [useDigitalSignatureCheckboxStylesInj],
            DigitalSignaturePfFormFieldsCheckbox,
        );

        const { fieldName, signatureSettings, onChange } = props;
        const classes = useDigitalSignatureCheckboxStyles();

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        className={classes.checkbox}
                        checked={signatureSettings[fieldName]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event, fieldName)}
                    />
                }
                label={<FormattedMessage id={`digitalSignature.form.${fieldName}`} />}
            />
        );
    },
);
