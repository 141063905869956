import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Transition } from '../../models/tot-object/TotObjectTransitions';
import { LifeCycleTransitionConfirmationDialog } from './LifeCycleTransitionConfirmationDialog';
import { ErrorDialog } from '../ErrorDialog';

export interface TransitionDialogProps {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
    validateFunction: () => Promise<void>;
    errorMessage?: JSX.Element | string;
}

export const TransitionDialog = (props: TransitionDialogProps): JSX.Element => {
    const { isModalOpen, setModalIsClosed, validateFunction, transition, errorMessage } = props;
    const [isTransitionAllowed, setIsTransitionAllowed] = useState<boolean>(false);
    const { dialogComponentCode, validate } = transition.params;

    useEffect(() => {
        if (validate) {
            validateFunction().then(() => {
                setIsTransitionAllowed(true);
            });
            return;
        }

        setIsTransitionAllowed(true);
    }, [validateFunction, setIsTransitionAllowed, validate]);

    if (isTransitionAllowed) {
        switch (dialogComponentCode) {
            default:
                return <LifeCycleTransitionConfirmationDialog {...props} />;
        }
    }

    return <ErrorDialog message={errorMessage} open={isModalOpen} onClose={setModalIsClosed} />;
};
