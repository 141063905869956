import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientRoute } from './clientRoute';
import { TotAppBar as TotAppBarInj } from './components/app-bar/TotAppBar';
import { AppContentContainer as AppContentContainerInj } from './components/AppContentContainer';
import { StartPage as StartPageInj } from './pages/start/StartPage';
import { LoginDialog as LoginDialogInj } from './pages/authentication/LoginDialog';
import { RecoveryPasswordPage as RecoveryPasswordPageInj } from './pages/authentication/RecoveryPasswordPage';
import { RegistrationPage as RegistrationPageInj } from './pages/authentication/RegistrationPage';
import { AuthenticationInfoPage as AuthenticationInfoPageInj } from './pages/authentication/AuthenticationInfoPage';
import { RegistrationConfirmPage as RegistrationConfirmPageInj } from './pages/authentication/RegistrationConfirmPage';
import { NewPasswordPage as NewPasswordPageInj } from './pages/authentication/NewPasswordPage';
import { CampaignPages as CampaignPagesInj } from './pages/campaigns/CampaignPages';
import { Footer as FooterInj } from './components/footer';
import { CampaignRequestPages as CampaignRequestPagesInj } from './pages/campaign-request/CampaignRequestPages';
import { EsiaPages as EsiaPagesInj } from './pages/authentication/esia/EsiaPages';
import { SamlPages as SamlPagesInj } from './pages/authentication/saml/SamlPages';
import { NotFound as NotFoundInj } from './pages/NotFound';
import { NotAllowed as NotAllowedInj } from './pages/NotAllowed';
import { NotificationComponent as NotificationComponentInj } from './components/notification-component/NotificationComponent';
import { UserPages as UserPagesInj } from './pages/users/UserPages';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import { Authentication as AuthenticationInj } from './components';
import { useStore } from './hooks';
import { di } from 'react-magnetic-di';
import {
    TotBackground as TotBackgroundInj,
    TotBackgroundWithAvailableHeader as TotBackgroundWithAvailableHeaderInj,
} from './TotBackground';
import { Logout as LogoutInj } from './pages/Logout';

export const AdditionalPagesInj = (): JSX.Element => <React.Fragment />;

export const App = observer(
    (): JSX.Element => {
        const { personStore } = useStore();
        const [startPage] = useFeature('startPage');
        const [TotAppBar] = di([TotAppBarInj], App);
        const [AppContentContainer] = di([AppContentContainerInj], App);
        const [StartPage] = di([StartPageInj], App);
        const [LoginDialog] = di([LoginDialogInj], App);
        const [RecoveryPasswordPage] = di([RecoveryPasswordPageInj], App);
        const [RegistrationPage] = di([RegistrationPageInj], App);
        const [AuthenticationInfoPage] = di([AuthenticationInfoPageInj], App);
        const [RegistrationConfirmPage] = di([RegistrationConfirmPageInj], App);
        const [NewPasswordPage] = di([NewPasswordPageInj], App);
        const [CampaignPages] = di([CampaignPagesInj], App);
        const [CampaignRequestPages] = di([CampaignRequestPagesInj], App);
        const [EsiaPages] = di([EsiaPagesInj], App);
        const [SamlPages] = di([SamlPagesInj], App);
        const [NotFound] = di([NotFoundInj], App);
        const [NotAllowed] = di([NotAllowedInj], App);
        const [NotificationComponent] = di([NotificationComponentInj], App);
        const [UserPages] = di([UserPagesInj], App);
        const [AdditionalPages] = di([AdditionalPagesInj], App);
        const [Footer] = di([FooterInj], App);
        const [Authentication] = di([AuthenticationInj], App);
        const [TotBackground] = di([TotBackgroundInj], App);
        const [TotBackgroundWithAvailableHeader] = di([TotBackgroundWithAvailableHeaderInj], App);
        const [Logout] = di([LogoutInj], App);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <TotAppBar />
                <AppContentContainer>
                    <Switch>
                        <Route path={clientRoute.campaigns}>
                            <CampaignPages />
                        </Route>
                        <Route path={clientRoute.requests}>
                            <CampaignRequestPages />
                        </Route>
                        <Route path={clientRoute.users}>
                            <UserPages />
                        </Route>
                        <Route path={clientRoute.esia}>
                            <EsiaPages />
                        </Route>
                        <Route path={clientRoute.saml}>
                            <SamlPages />
                        </Route>
                        <Route path={clientRoute.newPassword}>
                            <NewPasswordPage />
                        </Route>
                        <Route path={clientRoute.recoveryPassword}>
                            <RecoveryPasswordPage />
                        </Route>
                        <Route path={clientRoute.authenticationInfo}>
                            <AuthenticationInfoPage />
                        </Route>
                        <Route path={clientRoute.registrationConfirm}>
                            <RegistrationConfirmPage />
                        </Route>
                        <Route path={clientRoute.registration}>
                            <RegistrationPage />
                        </Route>
                        <Route path={clientRoute.login}>
                            <TotBackgroundWithAvailableHeader>
                                <LoginDialog />
                            </TotBackgroundWithAvailableHeader>
                        </Route>
                        <Route path={clientRoute.root}>
                            <TotBackground>
                                {startPage && <StartPage />}
                                {!startPage && (
                                    <Authentication redirectUrl={clientRoute.login}>
                                        <Redirect to={personStore.redirectPath} />
                                    </Authentication>
                                )}
                            </TotBackground>
                        </Route>
                        <Route path={clientRoute.notFound}>
                            <NotFound />
                        </Route>
                        <Route path={clientRoute.notAllowed}>
                            <NotAllowed />
                        </Route>
                        <Route path={clientRoute.logout}>
                            <Logout />
                        </Route>
                        <AdditionalPages />
                        <Route>
                            <Redirect to={clientRoute.notFound} />
                        </Route>
                    </Switch>
                    <NotificationComponent />
                </AppContentContainer>
                <Footer />
            </div>
        );
    },
);
