import fonts from './fonts';
import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../../themes/types/themeTypes';

type ColorName =
    | 'black'
    | 'white'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'green'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'blue'
    | 'blueDark'
    | 'blueMiddle'
    | 'blueLight'
    | 'blueLighter'
    | 'blueWhite'
    | 'disabled';

const rosatomColors: Record<ColorName, string> = {
    black: '#000000',
    white: '#ffffff',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    red: '#fb4d3d',
    green: '#008000',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',

    blue: '#003274',
    blueDark: '#025EA1',

    blueMiddle: '#D6E3EC',
    blueLight: '#6CACE4',
    blueLighter: '#AEC7D9',
    blueWhite: '#E7EFF4',

    disabled: 'rgba(0, 0, 0, 0.12)',
};

const rosatomFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

const rosatomIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const rosatomPalette: AppPalette = {
    main: rosatomColors.blue,
    textMain: rosatomColors.greyMain,
    textDark: rosatomColors.black,
    mainMiddleLight: rosatomColors.greyMiddleLight,
    mainLight: rosatomColors.blueLighter,
    mainDark: rosatomColors.greyDark,
    mainContrast: rosatomColors.white,
    mainContrastDarker: rosatomColors.greyLighter,
    accent: rosatomColors.blueLight,
    hover: rosatomColors.blueLight,
    accentDark: rosatomColors.blueDark,
    accentContrast: rosatomColors.black,
    accentBackgroundGradient: `linear-gradient(-30deg, ${rosatomColors.blue} 0%, ${rosatomColors.blueDark} 100%)`,
    hoverInLists: rosatomColors.greyWhite,
    red: rosatomColors.red,
    green: rosatomColors.green,
    campaignList: {
        backgroundCard: rosatomColors.lighterBlue,
        selectedPaginationButton: rosatomColors.lighterGrey,
    },
    table: {
        row: {
            even: rosatomColors.white,
            odd: rosatomColors.greyLighter,
        },
    },
    card: {
        header: {
            main: rosatomColors.blueDark,
        },
    },
    appBar: {
        mainContrast: rosatomColors.blue,
    },
    tooltip: {
        backgroundColor: rosatomColors.blue,
    },
    button: {
        accentContrast: rosatomColors.white,
        disabled: rosatomColors.disabled,
    },
    iconButton: {
        color: rosatomColors.blue,
        hoverColor: rosatomColors.white,
        hoverBackgroundColor: rosatomColors.blue,
    },
    background: {
        textMain: rosatomColors.white,
    },
    footer: {
        textMain: rosatomColors.blueMiddle,
        padding: 3.75,
    },
    tab: {
        backgroundColor: '#ececec',
        color: rosatomColors.blue,
        hoverBackgroundColor: rosatomColors.blue,
        hoverColor: rosatomColors.white,
    },
    panel: {
        markerBackgroundColor: rosatomColors.blueWhite,
        markerColor: rosatomColors.black,
    },

    accentContrastBackgroundGradient: rosatomColors.white,
    accentContrastBackgroundGradientLight: rosatomColors.lighterBlue,
};

export default {
    fonts: rosatomFonts,
    palette: rosatomPalette,
    icons: rosatomIcons,
} as ThemeOverrides;
