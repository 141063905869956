import { RootStoreProps } from '../types/stores/rootStore';
import { createBrowserHistory } from 'history';

const projectName = process.env.REACT_APP_PROJECT_NAME;

const env: RootStoreProps['env'] = {
    apiUrl: process.env.REACT_APP_API_URL,
    projectName,
    totCopyright: process.env.REACT_APP_TOT_COPYRIGHT,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    startPageText: process.env.REACT_APP_START_PAGE_TEXT,
    termsOfServiceLink: process.env.REACT_APP_TERMS_OF_SERVICE,
    pfDelay: Number(process.env.REACT_APP_PF_DELAY) || 30000,
};

let features: RootStoreProps['features'];
let ru = {};
let en = {};
let themeOverrides: RootStoreProps['themeOverrides'];
if (projectName) {
    features = require(`./features/${projectName}`).default;
    try {
        ru = require(`./resources/locales/${projectName}/ru`).default;
        en = require(`./resources/locales/${projectName}/en`).default;
    } catch (e) {}
    themeOverrides = require(`./themes/${projectName}/${projectName}`).default;
}

const locales: RootStoreProps['locales'] = { ru, en };

const history = createBrowserHistory();

export { env, features, locales, themeOverrides, history };
