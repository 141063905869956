import { Tab, TabProps } from '@material-ui/core';
import { LinkProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AuthorizationCheck as AuthorizationCheckInj, AuthorizationCheckProps } from '../AuthorizationCheck';
import React from 'react';
import { di } from 'react-magnetic-di';

export type AuthorizedTabProps = TabProps & LinkProps & AuthorizationCheckProps;

export const AuthorizedTab = observer(
    (props: AuthorizedTabProps): JSX.Element => {
        const { permCode, entityCode, entityId, pendingElement, onAllowed, errorElement, ...restProps } = props;
        const [AuthorizationCheck] = di([AuthorizationCheckInj], AuthorizedTab);

        return (
            <AuthorizationCheck
                entityCode={entityCode}
                permCode={permCode}
                entityId={entityId}
                pendingElement={pendingElement}
                onAllowed={onAllowed}
                errorElement={errorElement}
            >
                <Tab {...restProps} />
            </AuthorizationCheck>
        );
    },
);
