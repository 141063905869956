import React from 'react';
import { generatePath, NavLink, Route, Switch, useParams } from 'react-router-dom';
import { Grid, Tab } from '@material-ui/core';
import { RoutedTabs as RoutedTabsInj } from '../../../../../components';
import { clientRoute } from '../../../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { CampaignRegFormCard as CampaignRegFormCardInj } from './CampaignRegFormCard';
import { FormModel, FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import { RequestFormTabsSettings as RequestFormTabsSettingsInj } from './RequestFormTabsSettings';
import { CampaignRegFormRouteParams } from '../types';
import { di } from 'react-magnetic-di';
import { CampaignPfTemplateTab as CampaignPfTemplateTabInj } from './CampaignPfTemplateTab';

export type CampaignRegFormTabsProps = {
    model: FormModel;
    onFormReady: (form: FormApi) => void;
    onDeleteLinkClick: () => void;
};

export type RegFormTab = {
    title: string;
    path: string;
    additionalTabLinks: string[];
};

export const AdditionalCampaignRegFormPagesInj = (): JSX.Element => <React.Fragment />;
export const getAdditionalCampaignRegFormTabsRoutesInj = (): RegFormTab[] => [];

export const CampaignRegFormTabs = observer(
    (props: CampaignRegFormTabsProps): JSX.Element => {
        const { model, onFormReady, onDeleteLinkClick } = props;

        const [RoutedTabs] = di([RoutedTabsInj], CampaignRegFormTabs);
        const [CampaignPfTemplateTab] = di([CampaignPfTemplateTabInj], CampaignRegFormTabs);
        const [CampaignRegFormCard] = di([CampaignRegFormCardInj], CampaignRegFormTabs);
        const [RequestFormTabsSettings] = di([RequestFormTabsSettingsInj], CampaignRegFormTabs);
        const [AdditionalCampaignRegFormPages] = di([AdditionalCampaignRegFormPagesInj], CampaignRegFormTabs);
        const [getAdditionalCampaignRegFormTabsRoutes] = di(
            [getAdditionalCampaignRegFormTabsRoutesInj],
            CampaignRegFormTabs,
        );

        const { id, rfId } = useParams<CampaignRegFormRouteParams>();

        const campaignRegFormTabs = [
            {
                title: <FormattedMessage id="campaign.regFormInfo" />,
                path: clientRoute.campaignRegForm,
            },
            {
                title: <FormattedMessage id="campaign.tabsSettings" />,
                path: clientRoute.campaignRegFormTabsSettings,
            },
            {
                title: <FormattedMessage id="campaign.pfTemplates" />,
                path: clientRoute.campaignRegFormTemplates,
            },
            ...getAdditionalCampaignRegFormTabsRoutes(),
        ];

        return (
            <React.Fragment>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <RoutedTabs
                            tabsProps={{ indicatorColor: 'primary' }}
                            settings={campaignRegFormTabs.map((t, index) => ({
                                tab: index,
                                path: t.path,
                            }))}
                        >
                            {campaignRegFormTabs.map((p, index) => (
                                <Tab
                                    key={index}
                                    tabIndex={index}
                                    value={index}
                                    component={NavLink}
                                    to={generatePath(p.path, {
                                        id,
                                        rfId,
                                    })}
                                    label={p.title}
                                />
                            ))}
                        </RoutedTabs>
                    </Grid>
                    <Grid item>
                        <Switch>
                            <Route path={clientRoute.campaignRegForm} exact>
                                <CampaignRegFormCard
                                    model={model}
                                    onFormReady={onFormReady}
                                    onDeleteLinkClick={onDeleteLinkClick}
                                />
                            </Route>
                            <Route
                                path={[
                                    clientRoute.campaignRegFormTabsSettings,
                                    clientRoute.campaignRequestFormTabSetting,
                                    clientRoute.campaignRequestFormTabSettingCreate,
                                ]}
                                exact
                            >
                                <RequestFormTabsSettings />
                            </Route>
                            <Route
                                path={[
                                    clientRoute.campaignRegFormTemplates,
                                    clientRoute.campaignRegFormTemplatesCreate,
                                    clientRoute.campaignRegFormTemplatesEdit,
                                    clientRoute.campaignRegFormTemplatesPermissionSettings,
                                ]}
                                exact
                            >
                                <CampaignPfTemplateTab />
                            </Route>
                            <AdditionalCampaignRegFormPages />
                        </Switch>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    },
);
