import {
    ActionMenuItem,
    AuthorizationCheck,
    AuthorizationCheckAll,
    DeleteActionMenuItem,
    MenuButton,
} from '../../../components';
import { ConfirmationDialog as ConfirmationDialogInj } from '../../../components/ConfirmationDialog';
import React, { ReactNode, useState, useCallback, useEffect, useMemo } from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as DotMenu } from '../../../resources/images/icons/dot-menu.svg';
import { CampaignRequestRow } from '../../../models';
import { entities, permissions, permissionsConfig } from '../../../authSchemeConfig';
import { ActionItemWithDialog as ActionItemWithDialogInj } from './CampaignRequestListPage';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';
import { di } from 'react-magnetic-di';
import { AuthorizationCheckQuery } from '../../../store';

export type CampaignRequestActionsBtnsProps = {
    campaignRow: CampaignRequestRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditExecutorDialogOpen: (campaignRow: CampaignRequestRow, title?: string) => void;
};

export const CampaignRequestActionsBtns = observer(
    (props: CampaignRequestActionsBtnsProps): JSX.Element => {
        const { campaignRow, reloadData, isConfirm, setIsConfirm, handleEditExecutorDialogOpen } = props;
        const { id } = campaignRow;

        const [ActionItemWithDialog] = di([ActionItemWithDialogInj], CampaignRequestActionsBtns);
        const [ConfirmationDialog] = di([ConfirmationDialogInj], CampaignRequestActionsBtns);

        const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);
        const [permissionsData, setPermissionsData] = useState<boolean[]>([]);
        const [
            addActualAppointment,
            updateActualAppointment,
            editCampaignRequest,
            deleteCampaignRequest,
        ] = permissionsData;

        const { requestStore, authorizationStore } = useStore();

        useEffect(() => {
            if (setIsConfirm && isConfirm) {
                setIsConfirm(false);
                reloadData();
            }
        }, [isConfirm, setIsConfirm]);

        const deleteCampaign = useCallback((): void => {
            try {
                requestStore.deleteRequest(id).finally(reloadData);
            } catch (error) {
                console.error(error);
            }
        }, [reloadData, id, requestStore]);

        const openDeleteConfirmationDialog = (): void => {
            setIsDeleteConfirmationDialogOpen(true);
        };

        const closeDeleteConfirmationDialog = (): void => {
            setIsDeleteConfirmationDialogOpen(false);
        };

        const onDeleteConfirm = async (): Promise<void> => {
            await deleteCampaign();
            closeDeleteConfirmationDialog();
        };

        const onDeleteCancel = (): void => {
            closeDeleteConfirmationDialog();
        };

        const handleConfirmAddExecutor = useCallback((): void => {
            requestStore.setCurrentUserAsExecutor(id).finally(reloadData);
        }, [reloadData, id]);

        const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
            return [
                permissionsConfig.addActualAppointment(id),
                permissionsConfig.updateActualAppointment(id),
                permissionsConfig.editCampaignRequest(id),
                permissionsConfig.deleteCampaignRequest(id),
            ];
        }, [id]);

        useEffect(() => {
            authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
        }, [allPermissionsQueries]);

        const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
            const { id } = campaignRow;

            return (hideMenu): ReactNode[] => {
                const onDeleteClick = (): void => {
                    hideMenu && hideMenu();
                    openDeleteConfirmationDialog();
                };

                return [
                    <React.Fragment key="actionBtns">
                        {addActualAppointment && updateActualAppointment && (
                            <ActionMenuItem
                                messageId="campaignRequest.actions.appointExecutor"
                                onClick={() => handleEditExecutorDialogOpen(campaignRow)}
                            />
                        )}
                        {!addActualAppointment && updateActualAppointment && (
                            <ActionMenuItem
                                messageId="campaignRequest.actions.changeExecutor"
                                onClick={() =>
                                    handleEditExecutorDialogOpen(
                                        campaignRow,
                                        'campaignRequest.dialog.changeExecutorTitle',
                                    )
                                }
                            />
                        )}
                        {addActualAppointment && (
                            <ActionItemWithDialog
                                title={<FormattedMessage id="campaignRequest.dialog.executorsTitle" />}
                                message={<FormattedMessage id="campaignRequest.dialog.executorsMessage" />}
                                id="addExecutor"
                                onConfirm={handleConfirmAddExecutor}
                            />
                        )}
                        {editCampaignRequest && (
                            <ActionMenuItem
                                messageId="common.edit"
                                path={generatePath(clientRoute.requestEdit, { id })}
                            />
                        )}
                        {deleteCampaignRequest && <DeleteActionMenuItem onClick={onDeleteClick} />}
                    </React.Fragment>,
                ];
            };
        }, [permissionsData, campaignRow, handleEditExecutorDialogOpen]);

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            const atLeastOneAllowed = permissionsData.some((isPermissionAllowed) => isPermissionAllowed);
            return atLeastOneAllowed ? (
                <IconButton onClick={onClick}>
                    <SvgIcon>
                        <DotMenu />
                    </SvgIcon>
                </IconButton>
            ) : (
                <React.Fragment />
            );
        };

        return (
            <React.Fragment>
                <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems()} />
                <ConfirmationDialog
                    id="delete"
                    open={isDeleteConfirmationDialogOpen}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="campaignRequest.confirmDeletionInfoText"
                            values={{ number: campaignRow.number }}
                        />
                    }
                    onConfirm={onDeleteConfirm}
                    onCancel={onDeleteCancel}
                    keepMounted
                />
            </React.Fragment>
        );
    },
);
