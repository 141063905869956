import { RootStore } from './RootStore';
import { action, observable } from 'mobx';
import Components from 'formiojs/components/Components';
import AllComponents from 'formiojs/components';
import FormioExt from '@platform/formiojs-ext/dist';
import Widgets from 'formiojs/widgets';
import { di } from 'react-magnetic-di';

type Formio = {
    fetch<T>(url: string, options?: object): Promise<T>;
    // ... other
};

export class FormioStore {
    @observable private rootStore: RootStore;
    @observable private apiUrl?: string;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.apiUrl = rootStore.env.apiUrl;

        Components.setComponents(AllComponents);
        Components.setComponents(FormioExt.ExtComponents);
        Components.addComponent('select', FormioExt.ExtSelect({ baseUrl: this.apiUrl, catalogsUrl: this.apiUrl }));
        Widgets.calendar = FormioExt.RuCalendarWidget;

        this.hackFormioFetch();
    }

    @action.bound
    addComponentsWithMaterialTheme() {
        const fileProps = {
            baseUrl: this.apiUrl,
            catalogsUrl: this.apiUrl,
            materialUiTheme: this.rootStore.appTheme,
            intlStore: this.rootStore.intlStore,
            signatureStore: this.rootStore.formioSignatureStore,
        };

        Components.addComponent('file', FormioExt.StyledFile(fileProps));
        Components.addComponent('categoryFile', FormioExt.CategoryFile(fileProps));
    }

    @action.bound
    hackFormioFetch(): void {
        const globalWithFormio = (global as unknown) as { Formio: Formio };
        const formio = globalWithFormio.Formio;
        if (formio && formio.fetch) {
            const formioFetch = formio.fetch;

            globalWithFormio.Formio.fetch = <T>(url: string, options?: object): Promise<T> => {
                let fetchOptions = { credentials: 'include' };
                if (!!options) {
                    fetchOptions = Object.assign(fetchOptions, options);
                }
                return formioFetch(url, fetchOptions);
            };
        }
    }
}

export const getFormioStore = (): any => {
    const [_FormioStore] = di([FormioStore], getFormioStore);
    return _FormioStore;
};
