import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RegFormListModel } from '../../../models/regform-list-model/RegFormListModel';
import { useStore } from '../../../hooks/useStore';
import { RegFormSelect as RegFormSelectInj } from './RegFormSelect';
import { clientRoute } from '../../../clientRoute';
import { di } from 'react-magnetic-di';
import { RegFormSelectPageRouteParams } from './types';

export const RegFormSelectPage = observer(
    (): JSX.Element => {
        const [RegFormSelect] = di([RegFormSelectInj], RegFormSelectPage);

        const { requestStore, api } = useStore();
        const { campaignId } = useParams<RegFormSelectPageRouteParams>();
        const [regFormListModel, setRegFormListModel] = useState<RegFormListModel | null>();
        const history = useHistory();

        useEffect(() => {
            const model = new RegFormListModel(api, campaignId);
            setRegFormListModel(model);
        }, [api, campaignId]);

        const createRequest = (regFormId: string): (() => Promise<void>) => {
            return (): Promise<void> => {
                return requestStore.createRequest(regFormId).then((id: string) => {
                    history.push(generatePath(clientRoute.requestEdit, { campaignId, regFormId, id }));
                });
            };
        };

        if (!regFormListModel) {
            return <React.Fragment></React.Fragment>;
        }

        return <RegFormSelect regFormListModel={regFormListModel} createRequest={createRequest} />;
    },
);
