import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const FooterTypography = withTheme(
    styled(Typography)`
        color: ${({ theme }) => theme?.variables.palette.footer.textMain};
        text-transform: none;
        font-size: 15px;
        line-height: 1.47;
        letter-spacing: 0.3px;
    `,
);
