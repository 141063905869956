import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, Link, Tooltip, Typography } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useTheme, withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { RequestModel } from '../../../models';
import { NavLink, generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { observer } from 'mobx-react';
import { CampaignEditButton as CampaignEditButtonInj } from './campaign-request-control-panel';
import { entities, permissions } from '../../../authSchemeConfig';
import { withConfirmDialog } from '../../../hocs';
import { useStore } from '../../../hooks';
import { AuthorizationCheckQuery } from '../../../store';
import { TotObjectHeader as TotObjectHeaderInj } from '../../../components';
import { di } from 'react-magnetic-di';
import { RequestDeadline as RequestDeadlineInj } from './RequestDeadline';

export const RequestStateBoxInj = withTheme(
    styled(Box)`
        background-color: rgba(53, 167, 255, 0.09);
        border-radius: 4px;
        padding: ${({ theme }) =>
            `${theme.spacing(3.25)}px ${theme.spacing(4.25)}px ${theme.spacing(3.25)}px ${theme.spacing(4)}px`};
        display: inline-block;
    `,
);

export type RequestHeaderProps = {
    onModalOpen: (title?: string) => void;
    requestModel: RequestModel;
    reloadIncrement: () => void;
};

export const RequestHeaderButtonWithDialogInj = withConfirmDialog(({ openConfirmDialog }) => (
    <Button onClick={openConfirmDialog} color="secondary" size="small" variant="contained">
        <FormattedMessage id="campaignRequest.takeToWork" />
    </Button>
));

const queries = (id: string): AuthorizationCheckQuery[] => {
    return [
        {
            entityCode: entities.CampaignRequest,
            entityId: id,
            permCode: permissions.CampaignRequest.UpdateActualAppointment,
        },
        {
            entityCode: entities.CampaignRequest,
            entityId: id,
            permCode: permissions.CampaignRequest.AddActualAppointment,
        },
    ];
};

export type AdditionalTitleLinkProps = {
    requestId: string;
};

export const AdditionalTitleLinkInj = (props: AdditionalTitleLinkProps): JSX.Element => <React.Fragment />;

export const RequestHeader = observer(
    (props: RequestHeaderProps): JSX.Element => {
        const [AdditionalTitleLink] = di([AdditionalTitleLinkInj], RequestHeader);
        const [RequestHeaderButtonWithDialog] = di([RequestHeaderButtonWithDialogInj], RequestHeader);
        const [RequestStateBox] = di([RequestStateBoxInj], RequestHeader);
        const [RequestDeadline] = di([RequestDeadlineInj], RequestHeader);
        const [CampaignEditButton] = di([CampaignEditButtonInj], RequestHeader);
        const [TotObjectHeader] = di([TotObjectHeaderInj], RequestHeader);

        const { requestModel, onModalOpen, reloadIncrement } = props;
        const theme = useTheme();
        const { requestStore, intlStore: intl, authorizationStore } = useStore();
        const { number, campaign, regFormTitle, state, id, currentTab, deadlines } = requestModel;
        const { stateDeadline, entityDeadline } = deadlines;
        const [checkedPermissions, setCheckedPermissions] = useState<boolean[]>([]);

        const [updateActualAppointment, addActualAppointment] = checkedPermissions;

        const currentTabExecutor = currentTab?.tabExecutor;

        const tabExecutor = useMemo(() => {
            if (currentTabExecutor) {
                return currentTabExecutor;
            } else if (requestModel.executorsList) {
                return requestModel.executorsList;
            }
            return '';
        }, [currentTabExecutor, requestModel.executorsList]);

        useEffect(() => {
            authorizationStore.checkAll(queries(id)).then(setCheckedPermissions);
        }, [authorizationStore, setCheckedPermissions, id, currentTabExecutor, requestModel.executors]);

        const handleConfirm = (): void => {
            requestStore.setCurrentUserAsExecutor(id).then(() => {
                requestModel.updateExecutors();
                reloadIncrement();
            });
        };

        return (
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={10} alignItems="center">
                        <Grid item xs={8}>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="campaignRequest.RequestN" values={{ number }} />
                                    </Typography>
                                </Grid>
                                <AdditionalTitleLink requestId={id} />
                                <Grid item>
                                    {campaign.id && (
                                        <Link
                                            component={NavLink}
                                            underline="none"
                                            to={generatePath(clientRoute.campaign, {
                                                id: campaign.id,
                                            })}
                                        >
                                            <Typography variant="h3">{campaign.title}</Typography>
                                        </Link>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Box color={theme?.variables.palette.textMain}>
                                        <Typography variant="body2">{regFormTitle}</Typography>
                                    </Box>
                                </Grid>
                                <RequestDeadline
                                    deadline={entityDeadline}
                                    title={<FormattedMessage id="campaignRequest.deadlines.entity" />}
                                    withBlock={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid
                                container
                                spacing={3}
                                direction="column"
                                wrap="nowrap"
                                justify="center"
                                style={{ height: '100%' }}
                            >
                                <Grid item>
                                    <Box>
                                        <Typography variant="body2">
                                            <FormattedMessage id="common.State" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <RequestStateBox>
                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                            {state}
                                        </Typography>
                                    </RequestStateBox>
                                </Grid>
                                <RequestDeadline
                                    deadline={stateDeadline}
                                    title={<FormattedMessage id="campaignRequest.deadlines.state" />}
                                />
                                <Grid item>
                                    {tabExecutor && (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography variant="body2" noWrap>
                                                    <FormattedMessage id="common.responsibleExecutor" />
                                                </Typography>
                                            </Grid>
                                            <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body2">{tabExecutor}</Typography>
                                                </Grid>

                                                {!addActualAppointment && updateActualAppointment && (
                                                    <Grid item>
                                                        <CampaignEditButton
                                                            onEdit={() =>
                                                                onModalOpen(
                                                                    'campaignRequest.dialog.changeExecutorTitle',
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item container wrap="nowrap" spacing={1}>
                                    {addActualAppointment && (
                                        <Grid item>
                                            <RequestHeaderButtonWithDialog
                                                title={intl.formatMessage('campaignRequest.dialog.executorsTitle')}
                                                message={intl.formatMessage('campaignRequest.dialog.executorsMessage')}
                                                id="takeToWork"
                                                onConfirm={handleConfirm}
                                            />
                                        </Grid>
                                    )}

                                    {addActualAppointment && updateActualAppointment && (
                                        <Grid item>
                                            <Button
                                                onClick={() => onModalOpen()}
                                                color="secondary"
                                                size="small"
                                                variant="contained"
                                            >
                                                <FormattedMessage id="campaignRequest.chooseExecutor" />
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        );
    },
);
