import { action, computed, observable } from 'mobx';
import { CampaignRequestPfDTO, CampaignRequestPfModel } from './CampaignRequestPfModel';
import { IntlStore, RequestStore, RootStore } from '../../store';
import { FormApi } from '@platform/formiojs-react';
import downloadFile from 'js-file-download';
import { ErrorMessage } from '../../components';
import { ReactNode } from 'react';
import { asyncAction } from 'mobx-utils';
import { AxiosError } from 'axios';

export class CampaignRequestPfListModel {
    formId = '';
    @observable printForms: CampaignRequestPfModel[] = [];
    @observable errorDownloadFiles: string | ReactNode = '';
    @observable isFilesLoading = false;
    @observable rootStore: RootStore;
    @observable requestStore: RequestStore;
    @observable intlStore: IntlStore;
    @observable formApi?: FormApi;

    constructor(rootStore: RootStore, formId: string, formApi?: FormApi) {
        this.formId = formId;
        this.rootStore = rootStore;
        this.requestStore = rootStore.requestStore;
        this.intlStore = rootStore.intlStore;
        this.formApi = formApi;
    }

    @computed
    get isFilesGenerating() {
        return this.printForms.some(({ delayedTask }) => {
            return delayedTask?.taskStatus === 'NEW' || delayedTask?.taskStatus === 'ATTEMPT_FAILED';
        });
    }

    @action.bound
    load(dto: CampaignRequestPfDTO[]): void {
        this.printForms = dto.map((dto) => {
            return new CampaignRequestPfModel(this.rootStore, this.formId, this.formApi).load(dto);
        });
    }

    @computed
    get generatedPF(): CampaignRequestPfModel[] {
        return this.printForms.filter((form: CampaignRequestPfModel) => !!form.pf);
    }

    @asyncAction
    @action.bound
    async downloadAllPf(requestNumber: string): Promise<void> {
        try {
            this.isFilesLoading = true;
            const { data } = await this.requestStore.downloadAllPrintFormFiles(this.formId);
            const fileName = `${this.intlStore.formatMessage('campaignRequest.allFiles', {
                title: requestNumber,
            })}.zip`;
            downloadFile(data, fileName);
        } catch (error) {
            if (error.request.responseType === 'blob' && error.response?.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    error.response && (error.response.data = reader.result);
                    this.setErrorText(error);
                };

                reader.readAsText(error.response.data);
            } else {
                this.setErrorText(error);
            }
        }
        this.isFilesLoading = false;
    }

    @action.bound
    setErrorText(error: AxiosError) {
        const errorText = ErrorMessage(error);

        this.errorDownloadFiles = errorText;
    }
}
