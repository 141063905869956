import React from 'react';
import { Grid } from '@material-ui/core';
import { CampaignSaveButton as CampaignSaveButtonInj } from './CampaignSaveButton';
import { CampaignCancelButton as CampaignCancelButtonInj } from './CampaignCancelButton';
import { useHistory } from 'react-router-dom';
import { CampaignCancelButtonWithDialog as CampaignCancelButtonWithDialogInj } from './CampaignCancelButtonWithDialog';
import { di } from 'react-magnetic-di';

export type CampaignEditModeButtonsWithDialogProps = {
    onSubmit: () => Promise<void>;
    path: string;
    formIsChanged: boolean;
};

export const CampaignEditModeButtonsWithDialog = (props: CampaignEditModeButtonsWithDialogProps): JSX.Element => {
    const [CampaignSaveButton] = di([CampaignSaveButtonInj], CampaignEditModeButtonsWithDialog);
    const [CampaignCancelButton] = di([CampaignCancelButtonInj], CampaignEditModeButtonsWithDialog);
    const [CampaignCancelButtonWithDialog] = di([CampaignCancelButtonWithDialogInj], CampaignEditModeButtonsWithDialog);

    const history = useHistory();

    const { onSubmit, path, formIsChanged } = props;
    const confirm = () => {
        history.push({
            pathname: path,
            search: '?prompt=false',
        });
    };

    return (
        <React.Fragment>
            <Grid spacing={3} alignItems="center" justify="center" container>
                <Grid item>
                    <CampaignSaveButton onSubmit={onSubmit} />
                </Grid>
                <Grid item>
                    {formIsChanged ? (
                        <CampaignCancelButtonWithDialog onConfirm={confirm} />
                    ) : (
                        <CampaignCancelButton path={path} />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
