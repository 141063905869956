export default {
    startPageLinks: false,
    startPage: false,
    loginWithoutRegister: false,
    customLogo: {
        isEnabled: true,
        src: require(`../resources/images/tszd-logo.svg`),
        style: { minHeight: 70 },
    },
    customBackground: {
        isEnabled: true,
        src: require(`../resources/images/tszd-background.jpg`),
    },
    helpIcon: false,
    notificationIcon: true,
    phoneNumber: false,
    startPageDefaultText: true,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsLamp: true,
    loginDialogClose: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    samlAuth: false,
    showCampaignPeriod: true,
};
