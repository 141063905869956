import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';
import { Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { Form, FormView } from '@platform/formiojs-react';
import { CampaignRequestPrintForms as CampaignRequestPrintFormsInj } from './campaign-request-page-print-forms/CampaignRequestPrintForms';
import { RequestFormParams } from './CampaignRequestRoutes';
import { RequestModel } from '../../../models';
import { di } from 'react-magnetic-di';

export type CampaignRequestFormProps = {
    requestModel: RequestModel;
    setFormIsChanged: (formIsChanged: boolean) => void;
    requestNumber: string;
};

export const CampaignRequestForm = observer(
    (props: CampaignRequestFormProps): JSX.Element => {
        const { requestModel, setFormIsChanged, requestNumber } = props;
        const {
            onFormReady,
            onFormChange,
            currentTab: model,
            isFormReady,
            formApi,
            validateEditPage,
            validateReadonlyPage,
        } = requestModel;

        const [CampaignRequestPrintForms] = di([CampaignRequestPrintFormsInj], CampaignRequestForm);

        const { requestStore, intlStore } = useStore();
        const { locale } = intlStore;
        const { formId } = useParams<RequestFormParams>();

        const [canGenerate, setCanGenerate] = useState<boolean>(false);

        useEffect(() => {
            requestModel.setCurrentTab(formId);
            requestStore
                .checkEditablePf(formId)
                .then(() => setCanGenerate(true))
                .catch(() => setCanGenerate(false));

            return () => {
                requestModel.currentTab = null;
            };
        }, [requestStore, formId, requestModel]);

        if (!model) {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                <Route path={clientRoute.requestFormEdit} exact>
                    <Form
                        form={model.formModel}
                        onFormReady={onFormReady}
                        onFormChange={onFormChange}
                        setFormIsChanged={setFormIsChanged}
                    />
                </Route>
                <Route path={clientRoute.requestForm} exact>
                    <FormView locale={locale} form={model.formModel} onFormReady={onFormReady} className="print-form" />
                    <CampaignRequestPrintForms
                        isShown={isFormReady}
                        formApi={formApi}
                        canGenerate={canGenerate}
                        validateEditPage={validateEditPage}
                        validateReadonlyPage={validateReadonlyPage}
                        requestNumber={requestNumber}
                    />
                </Route>
            </React.Fragment>
        );
    },
);
