import { RootStore } from './RootStore';
import { action, observable } from 'mobx';
import axios, { AxiosInstance } from 'axios';
import { apiConfigs } from '../apiConfigs';
import { di } from 'react-magnetic-di';

export class Api {
    @observable private rootStore: RootStore;
    @observable authStatus: 'ok' | 'pending' | 'unauthorized' = 'pending';

    client: AxiosInstance;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.client = axios.create({
            baseURL: this.rootStore.env.apiUrl,
            withCredentials: true,
        });
        this.client.interceptors.response.use((_) => _, this.authInterceptor);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authInterceptor(error: any): void {
        const response = error.response || {};
        if (response.status === 401) {
            this.authStatus = 'unauthorized';
        }
        throw error;
    }

    @action.bound
    async authVerify(): Promise<void> {
        this.authStatus = 'pending';
        await this.client(apiConfigs.authorizationCheck([])).then((r) => r);
        this.authStatus = 'ok';
    }
}

export const getApi = (): any => {
    const [_Api] = di([Api], getApi);
    return _Api;
};
