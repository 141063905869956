import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { clientRoute } from '../../../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    AuthorizationCheckAll as AuthorizationCheckAllInj,
} from '../../../../../components';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';
import { CampaignEditButton as CampaignEditButtonInj } from './CampaignEditButton';
import { withConfirmDialog } from '../../../../../hocs';
import { AuthorizationCheckQuery } from '../../../../../store';
import { useStore } from '../../../../../hooks';
import { di } from 'react-magnetic-di';

export type CampaignViewModeButtonsProps = {
    onEdit?: () => void;
    deleteRequest: () => Promise<void>;
    editCheckQueries?: AuthorizationCheckQuery[];
    requestNumber: string;
    id: string;
    formId?: string;
};

export const DeleteRequestButtonInj = withConfirmDialog(
    ({ openConfirmDialog }): JSX.Element => {
        return (
            <Button onClick={openConfirmDialog} color="secondary" size="small" variant="contained">
                <FormattedMessage id="campaignRequest.deleteRequest" />
            </Button>
        );
    },
);

export const CampaignViewModeButtons = (props: CampaignViewModeButtonsProps): JSX.Element => {
    const { onEdit, deleteRequest, id, requestNumber, editCheckQueries, formId } = props;

    const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignViewModeButtons);
    const [AuthorizationCheckAll] = di([AuthorizationCheckAllInj], CampaignViewModeButtons);
    const [DeleteRequestButton] = di([DeleteRequestButtonInj], CampaignViewModeButtons);
    const [CampaignEditButton] = di([CampaignEditButtonInj], CampaignViewModeButtons);

    const [isEditAllowed, setIsEditAllowed] = useState<boolean>(false);
    const { requestStore } = useStore();

    useEffect(() => {
        if (!formId) {
            return;
        }

        requestStore
            .requestFormCheckEditable(formId)
            .then(() => setIsEditAllowed(true))
            .catch(() => setIsEditAllowed(false));
    }, [requestStore, setIsEditAllowed, formId]);

    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            {editCheckQueries && onEdit && (
                <AuthorizationCheckAll or queries={editCheckQueries}>
                    <Grid item>
                        <CampaignEditButton onEdit={onEdit} />
                    </Grid>
                </AuthorizationCheckAll>
            )}
            {formId && isEditAllowed && onEdit && (
                <Grid item>
                    <CampaignEditButton onEdit={onEdit} />
                </Grid>
            )}
            <Route path={[clientRoute.request, clientRoute.requestForm]} exact>
                <AuthorizationCheck
                    entityCode={entities.CampaignRequest}
                    entityId={id}
                    permCode={permissions.CampaignRequest.Delete}
                >
                    <Grid item>
                        <DeleteRequestButton
                            id="delete-request"
                            title={<FormattedMessage id="common.confirmDeletion" />}
                            message={
                                <FormattedMessage
                                    id="campaignRequest.confirmDeletionInfoText"
                                    values={{ number: requestNumber }}
                                />
                            }
                            onConfirm={deleteRequest}
                        />
                    </Grid>
                </AuthorizationCheck>
            </Route>
        </Grid>
    );
};
