import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { FormApi } from '@platform/formiojs-react';
import { TableToolbar as TableToolbarInj } from './CampaignRequestPrintForms.styled';
import { CampaignRequestPrintFormsList as CampaignRequestPrintFormsListInj } from './CampaignRequestPrintFormsList';
import { CampaignRequestPrintFormsTable as CampaignRequestPrintFormsTableInj } from './capmpaign-request-page-print-forms-table/CampaignRequestPrintFormsTable';
import { useStore } from '../../../../hooks/useStore';
import { ErrorDialog as ErrorDialogInj } from '../../../../components/ErrorDialog';
import { di } from 'react-magnetic-di';
import { CampaignRequestPfListModel } from '../../../../models/campaign-request-pf/CampaignRequestPfListModel';
import { CampaignRequestPfModel } from '../../../../models';

export type CampaignRequestPrintFormsProps = {
    isShown: boolean; // пф показываются на onFormReady,
    // т.к. если отрендерить их раньше, выглядит не очень приятно, когда перед ними появляется форма
    canGenerate: boolean;
    formApi?: FormApi;
    validateEditPage(onSuccess?: () => Promise<void>): Promise<void>;
    validateReadonlyPage(onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean): Promise<void>;
    requestNumber: string;
};

interface RouteParams {
    id: string;
    formId: string;
}

const StyledProgress = withTheme(styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -${({ theme }) => theme.spacing(3)}px;
    margin-left: -${({ theme }) => theme.spacing(3)}px;
`);

export const CampaignRequestPrintForms = observer(
    (props: CampaignRequestPrintFormsProps): JSX.Element => {
        const [CampaignRequestPrintFormsList] = di([CampaignRequestPrintFormsListInj], CampaignRequestPrintForms);
        const [CampaignRequestPrintFormsTable] = di([CampaignRequestPrintFormsTableInj], CampaignRequestPrintForms);
        const [ErrorDialog] = di([ErrorDialogInj], CampaignRequestPrintForms);
        const [TableToolbar] = di([TableToolbarInj], CampaignRequestPrintForms);

        const { isShown, validateEditPage, validateReadonlyPage, formApi, requestNumber, canGenerate } = props;
        const { formId } = useParams<RouteParams>();
        const rootStore = useStore();

        const [model] = useState(new CampaignRequestPfListModel(rootStore, formId, formApi));
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const { requestStore, env } = rootStore;
        const [errorDownloadFiles, setErrorDownloadFiles] = useState<string | ReactNode>('');
        const [isFilesGenerating, setIsFilesGenerating] = useState<boolean>(false);
        const [intervalId, setIntervalId] = useState<number>();
        const { pfDelay } = env;

        const fetchPrintForms = useCallback((): Promise<void> => {
            return requestStore
                .loadPrintForms(formId)
                .then(model.load)
                .finally(() => {
                    setIsLoading(false);
                });
        }, [requestStore, formId]);

        useEffect(() => {
            fetchPrintForms();
        }, [fetchPrintForms]);

        useEffect(() => {
            setIsFilesGenerating(model.isFilesGenerating);
        }, [model.isFilesGenerating, model.printForms, setIsFilesGenerating]);

        useEffect(() => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (isFilesGenerating) {
                const updateDelayedTasksInterval = setInterval(fetchPrintForms, pfDelay);
                setIntervalId((updateDelayedTasksInterval as unknown) as number);

                return () => clearInterval(updateDelayedTasksInterval);
            }
        }, [fetchPrintForms, isFilesGenerating, pfDelay]);

        if (!isShown) {
            return <React.Fragment />;
        }

        let shouldRenderPrintForms = model.printForms.length > 0;
        let generatedPrintForms: CampaignRequestPfModel[] = [];
        if (!canGenerate) {
            generatedPrintForms = model.generatedPF;
            shouldRenderPrintForms = generatedPrintForms.length > 0;
        }

        if (isLoading) {
            return (
                <Box mb={8} mt={6}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={6}>
                        <Grid item>
                            <FormattedMessage id="campaignRequest.printFormsTable.loading" />
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Box>
            );
        }

        return (
            <React.Fragment>
                {shouldRenderPrintForms && (
                    <Box mt={5} mb={5}>
                        <Paper elevation={1}>
                            <TableToolbar>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h3">
                                            <FormattedMessage id="campaignRequest.printFormsTable.title" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={model.isFilesLoading}
                                            color="primary"
                                            variant="contained"
                                            onClick={() => model.downloadAllPf(requestNumber)}
                                            style={{ position: 'relative' }}
                                        >
                                            {model.isFilesLoading && <StyledProgress size={24} />}
                                            <FormattedMessage id="campaignRequest.printFormsTable.downloadAllButton" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TableToolbar>

                            {canGenerate ? (
                                <CampaignRequestPrintFormsTable
                                    formApi={formApi}
                                    validateEditPage={validateEditPage}
                                    validateReadonlyPage={validateReadonlyPage}
                                    printForms={model.printForms}
                                />
                            ) : (
                                <CampaignRequestPrintFormsList printForms={generatedPrintForms} />
                            )}
                        </Paper>

                        <ErrorDialog
                            message={errorDownloadFiles}
                            open={!!errorDownloadFiles}
                            onClose={() => setErrorDownloadFiles('')}
                        />
                    </Box>
                )}
            </React.Fragment>
        );
    },
);
