import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './Root';
import { env, features, locales, themeOverrides, history } from './module/initRootProps';
import './module/styles/index.scss';
import { rootElement } from './rootElement';

ReactDOM.render(
    <Root env={env} features={features} locales={locales} themeOverrides={themeOverrides} history={history} />,
    rootElement,
);
