import React, { useMemo } from 'react';
import { Box, Button, CardActions, CardMedia, Grid, Tooltip } from '@material-ui/core';
import { generatePath, NavLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { clientRoute } from '../../../../clientRoute';
import { ReactComponent as RequestsCount } from '../../../../resources/images/icons/requests-count.svg';
import {
    AccentText as AccentTextInj,
    CampaignCardContent as CampaignCardContentInj,
    CampaignCardWrapper as CampaignCardWrapperInj,
    CampaignRequestsButton as CampaignRequestsButtonInj,
    CardCampaignLink as CardCampaignLinkInj,
    NotAccentText as NotAccentTextInj,
} from './StyledComponents';
import { EllipsisBox as EllipsisBoxInj } from '../../../../components/EllipsisBox';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { CampaignCardDTO } from '../../../../models/campaign/CampaignListModel';
import { TitleWrapper as TitleWrapperInj } from './TitleWrapper';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../../authSchemeConfig';
import { isAfter } from 'date-fns';
import { useFeature } from 'feature-toggle-jsx';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick } from '../../../../hooks/useAntiDoubleClick';
import { useStore } from '../../../../hooks';

export type CampaignCardProps = {
    campaign: CampaignCardDTO;
};

export const CampaignCard = (props: CampaignCardProps): JSX.Element => {
    const [AccentText] = di([AccentTextInj], CampaignCard);
    const [CampaignCardContent] = di([CampaignCardContentInj], CampaignCard);
    const [CampaignCardWrapper] = di([CampaignCardWrapperInj], CampaignCard);
    const [CampaignRequestsButton] = di([CampaignRequestsButtonInj], CampaignCard);
    const [CardCampaignLink] = di([CardCampaignLinkInj], CampaignCard);
    const [NotAccentText] = di([NotAccentTextInj], CampaignCard);
    const [EllipsisBox] = di([EllipsisBoxInj], CampaignCard);
    const [TitleWrapper] = di([TitleWrapperInj], CampaignCard);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignCard);

    const { id, avatarUrl, requestCount, title, to } = props.campaign;
    const theme = useTheme();

    const { campaignsStore } = useStore();
    const { createRequest } = campaignsStore;

    const createFunc = (): Promise<void> => {
        return createRequest(id);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(createFunc);

    const isCollectionCompleted = useMemo(() => {
        if (!to) {
            return false;
        }
        return isAfter(new Date(), to);
    }, [to]);

    const [showCreateRequestButton] = useFeature('showCreateRequestButton');
    const [showRequestsLamp] = useFeature('showRequestsLamp');
    const [showCampaignPeriod] = useFeature('showCampaignPeriod');

    const isImage = !!avatarUrl;

    return (
        <CampaignCardWrapper isImage={isImage} showCreateRequestButton={showCreateRequestButton}>
            <CampaignCardContent isImage={isImage}>
                {isImage && <CardMedia component="img" height="204" image={avatarUrl} title={title} alt={title} />}
                <TitleWrapper isImage={isImage}>
                    <Tooltip title={title}>
                        <CardCampaignLink
                            component={NavLink}
                            to={generatePath(clientRoute.campaign, { id })}
                            isDarkBackground={isImage}
                        >
                            <EllipsisBox text={title} />
                        </CardCampaignLink>
                    </Tooltip>
                </TitleWrapper>
            </CampaignCardContent>
            <CardActions>
                <Grid container direction="column">
                    <Grid item>
                        <Box pb={3}>
                            <Grid container alignItems="center" justify="space-between">
                                {showCampaignPeriod && (
                                    <Grid item>
                                        <Box>
                                            {isCollectionCompleted ? (
                                                <AccentText>
                                                    <FormattedMessage id="campaign.collectionCompleted" />
                                                </AccentText>
                                            ) : (
                                                <React.Fragment>
                                                    <NotAccentText>
                                                        <FormattedMessage id="campaign.campaignPeriod" />
                                                        &nbsp;
                                                        <FormattedMessage id="common.to" />
                                                        &nbsp;
                                                    </NotAccentText>
                                                    <Box fontWeight="700">
                                                        <FormattedDate value={to} />
                                                    </Box>
                                                </React.Fragment>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                                {showRequestsLamp && (
                                    <Grid item>
                                        <Grid container alignItems="center" justify="center" wrap="nowrap">
                                            <Grid item>
                                                <CampaignRequestsButton requests={requestCount}>
                                                    <RequestsCount />
                                                </CampaignRequestsButton>
                                            </Grid>
                                            <Grid item>
                                                <Box color={theme?.variables.palette.campaignList.accentContrast}>
                                                    {requestCount}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                    {showCreateRequestButton && (
                        <Grid item>
                            <AuthorizationCheck
                                entityCode={entities.Campaign}
                                permCode={permissions.Campaign.AddCampRequest}
                                entityId={id}
                            >
                                {(allowed) => (
                                    <Tooltip
                                        title={
                                            !allowed && !isCollectionCompleted ? (
                                                <FormattedMessage id="campaign.createRequestError" />
                                            ) : (
                                                ''
                                            )
                                        }
                                    >
                                        <div>
                                            <Button
                                                disabled={!allowed || isSending}
                                                fullWidth={true}
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                onClick={createHandler}
                                                endIcon={endIcon}
                                            >
                                                <FormattedMessage id="campaign.createRequest" />
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )}
                            </AuthorizationCheck>
                        </Grid>
                    )}
                </Grid>
            </CardActions>
        </CampaignCardWrapper>
    );
};
