const breadcrumbs: Record<string, string | Record<string, string>> = {
    mainPage: 'Main page',
    campaigns: 'Campaigns',
    campaign: 'Campaign',
    requests: 'Register of requests',
    requestsNew: 'Register of requests',
    campaignSettings: 'Campaign settings',
    regFormSelect: 'Selection of the application form',
    users: 'Users',
};

export default breadcrumbs;
