export default {
    startPageLinks: false,
    startPage: true,
    loginWithoutRegister: false,
    customLogo: {
        isEnabled: true,
        src: require(`../resources/images/kpmg-logo.png`),
        style: { maxHeight: 60 },
    },
    customBackground: {
        isEnabled: true,
        src: require(`../resources/images/kpmg-background.jpeg`),
    },
    helpIcon: false,
    notificationIcon: false,
    phoneNumber: false,
    startPageDefaultText: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsLamp: true,
    loginDialogClose: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: false,
    samlAuth: false,
    showCampaignPeriod: true,
};
