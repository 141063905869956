import React, { ReactNode } from 'react';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { CampaignRequestPfModel } from '../../../../models/campaign-request-pf/CampaignRequestPfModel';
import { ReactComponent as FileIcon } from '../../../../resources/images/icons/file.svg';
import { IconLink as IconLinkInj } from '../../../../components/links/IconLink';
import { FileDTO } from '../../../../models/FileModel';
import { di } from 'react-magnetic-di';
import { CampaignRequestPrintFormsSignature as CampaignRequestPrintFormsSignatureInj } from './CampaignRequestPrintFormsSignature';

export type PfListTooltipFileProps = {
    downloadPf: () => void;
    file: FileDTO;
    templateTitle: string;
};

export const PfListTooltipFileInj = (props: PfListTooltipFileProps): JSX.Element => {
    const { downloadPf, file, templateTitle } = props;
    const [IconLink] = di([IconLinkInj], PfListTooltipFileInj);
    return (
        <Tooltip title={<FormattedMessage id="common.downloadFile" values={{ fileName: file.filename }} />}>
            <IconLink component="button" onClick={downloadPf} icon={<FileIcon />} text={templateTitle} />
        </Tooltip>
    );
};

export type CampaignRequestPrintFormsListProps = {
    printForms: CampaignRequestPfModel[];
};

export const CampaignRequestPrintFormsList = observer(
    (props: CampaignRequestPrintFormsListProps): JSX.Element => {
        const { printForms } = props;

        const [PfListTooltipFile] = di([PfListTooltipFileInj], CampaignRequestPrintFormsList);
        const [CampaignRequestPrintFormsSignature] = di(
            [CampaignRequestPrintFormsSignatureInj],
            CampaignRequestPrintFormsList,
        );

        const renderPfListRow = (printForm: CampaignRequestPfModel): ReactNode => {
            const { templateId, templateTitle, pf } = printForm;
            return (
                pf && (
                    <Grid container item alignItems="center" wrap="nowrap" spacing={2} key={templateId}>
                        <Grid item>
                            <PfListTooltipFile
                                downloadPf={printForm.downloadPf}
                                file={pf}
                                templateTitle={templateTitle}
                            />
                        </Grid>
                        <Grid item>
                            <CampaignRequestPrintFormsSignature printForm={printForm} isView={true} />
                        </Grid>
                    </Grid>
                )
            );
        };

        return (
            <Box p={6}>
                <Grid container direction="column" spacing={4}>
                    {printForms.map(renderPfListRow)}
                </Grid>
            </Box>
        );
    },
);
