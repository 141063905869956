import { TableModel, TableQueryData, RowsData } from './TableModel';
import { action, observable } from 'mobx';
import { Api } from '../../store/Api';
import { apiConfigs } from '../../apiConfigs';
import { Sort } from './Sort';
import { LikeFilter } from './filters/LikeFilter';
import { DateRangeFilter } from './filters/DateRangeFilter';
import { IdTitle } from '../IdTitle';
import { IdTitleParent } from '../IdTitleParent';
import { GroupedIdTitle } from '../GroupedIdTitle';
import { InSetFilter } from './filters/InSetFilter';
import { UserNameModel, UserNameDTO } from '../person/UserNameModel';
import { RequestRowModel } from '../RequestRowModel';
import { EmployeeDTO } from '../../types/stores/requestStore';

export interface CampaignRequestRowDTO {
    id: string;
    number: string;
    author: UserNameDTO;
    campaignId: string;
    campaignTitle: string;
    regFormTitle: string;
    state: string;
    created: string; //date
    sent: string; //date
    executors: EmployeeDTO[];
}

export interface CampaignRequestRow {
    id: string;
    number: string;
    authorUserId: string;
    authorName: string;
    campaignId: string;
    campaignTitle: string;
    regFormTitle: string;
    state: string;
    created: string; //date
    sent: string; //date
    executors: EmployeeDTO[];
}

export type CampaignRequestFilter = {
    number: LikeFilter;
    author: InSetFilter;
    campaign: InSetFilter;
    regForm: InSetFilter;
    state: InSetFilter;
    created: DateRangeFilter;
    sent: DateRangeFilter;
    executors: InSetFilter;
};

export type CampaignRequestSort = {
    number: Sort;
    campaign: Sort;
    regForm: Sort;
    created: Sort;
    sent: Sort;
};

export interface CampaignRequestListFilterData {
    filter: { campaigns: string } | {};
}

export class CampaignRequestListModel extends TableModel<RequestRowModel, CampaignRequestFilter, CampaignRequestSort> {
    @observable api: Api;

    @observable campaignFilterData: IdTitle[];
    @observable regFormFilterData: IdTitleParent[];
    @observable stateFilterData: GroupedIdTitle;
    @observable personsFilterData: IdTitle[];
    @observable employeeFilterData: IdTitle[];

    constructor(api: Api) {
        const filter = {
            number: new LikeFilter(),
            author: new InSetFilter(),
            campaign: new InSetFilter(),
            regForm: new InSetFilter(),
            state: new InSetFilter(),
            created: new DateRangeFilter(),
            sent: new DateRangeFilter(),
            executors: new InSetFilter(),
        };

        const sort = {
            number: new Sort('asc'),
            campaign: new Sort(),
            regForm: new Sort(),
            created: new Sort(),
            sent: new Sort(),
        };
        super(filter, sort);
        this.api = api;

        this.campaignFilterData = [];
        this.regFormFilterData = [];
        this.stateFilterData = {};
        this.personsFilterData = [];
        this.employeeFilterData = [];

        this.getPersonsFilterData();
        this.getEmployeesFilterData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<RequestRowModel>> {
        return this.api
            .client(apiConfigs.requestList(queryData))
            .then((r) => r.data)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    getFilterData(campaignId: string[] = []): void {
        const data = {
            filter: campaignId ? { campaigns: campaignId } : {},
        };

        this.api
            .client(apiConfigs.requestListFilterData(data))
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.campaignFilterData = data.campaigns;
                    this.regFormFilterData = data.regForms;
                    if (data.statesByProcess) {
                        this.stateFilterData = data.statesByProcess;
                    }
                }),
            );
    }

    @action.bound
    getPersonsFilterData(): void {
        this.api
            .client(apiConfigs.personsListFilterData)
            .then((r) => r.data)
            .then(
                action((data) => {
                    const author = new UserNameModel();
                    this.personsFilterData = data.map((o: UserNameDTO) => {
                        author.load(o);
                        return author.asIdTitle;
                    });
                }),
            );
    }

    @action.bound
    getEmployeesFilterData(): void {
        this.api
            .client(apiConfigs.getAllEmployees())
            .then((r) => r.data)
            .then((employees) => {
                const employeesIdTitle = employees.map((employee: EmployeeDTO) => ({
                    id: employee.userId,
                    title: `${employee.person.lastName} ${employee.person.firstName}`,
                }));
                this.employeeFilterData = employeesIdTitle;
            });
    }

    @action.bound
    mapDtoToRow(dto: CampaignRequestRowDTO): RequestRowModel {
        const author = new UserNameModel().load(dto.author);
        const rowModel = new RequestRowModel();
        const row = {
            id: dto.id,
            number: dto.number,
            authorName: author.name,
            authorUserId: author.userId,
            campaignId: dto.campaignId,
            campaignTitle: dto.campaignTitle,
            regFormTitle: dto.regFormTitle,
            created: dto.created,
            sent: dto.sent,
            state: dto.state,
            executors: dto.executors,
        };
        rowModel.load(row);
        return rowModel;
    }
}
