import { action, computed, observable } from 'mobx';
import { TemplateDTO } from '../../store/CampaignsStore';
import { IdTitle } from '../IdTitle';
import { IntlStore } from '../../store/IntlStore';
import { ChangeEvent } from 'react';
import { FileDTO } from '../FileModel';
import { CatalogStore, CatalogCodes } from '../../store/CatalogStore';
import { RootStore } from '../../store/RootStore';
import { apiConfigs } from '../../apiConfigs';
import { SignatureSettings } from '@platform/crypto-ui';

export class CampaignPfTemplateModel {
    @observable intl: IntlStore;
    @observable catalogStore: CatalogStore;

    campaignId: string;
    titleMaxLength = 100;
    @observable id = '';
    @observable title = '';
    @observable description = '';
    @observable settingsId = '';
    @observable required: boolean = false;

    @observable signatureSettings: SignatureSettings = {
        signatureEnabled: false,
        signatureRequired: false,
        signatureBrowserPlugin: false,
        signatureUpload: false,
    };

    @observable fileForUpload?: File;
    @observable fileDTO?: FileDTO;
    acceptedFiles = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    @observable regFormsCatalog: IdTitle[] = [];

    @observable docCategories: IdTitle[] = [];
    @observable docCategory: string = '';

    @observable fileFormats: IdTitle[] = [];
    @observable resultFileFormat = '';

    @observable validationStarted = false;
    @observable errorForm = false;
    @observable errorFormCode = '';

    @observable isRejectedFile = false;

    constructor(campaignId: string, settingsId: string, regFormsCatalog: IdTitle[], rootStore: RootStore) {
        this.intl = rootStore.intlStore;
        this.catalogStore = rootStore.catalogStore;
        this.regFormsCatalog = regFormsCatalog;
        this.campaignId = campaignId;
        this.settingsId = settingsId;
    }

    @action.bound
    getDocCategories(): Promise<void> {
        return this.catalogStore
            .getSelectData(apiConfigs.catalogItems(CatalogCodes.documentCategory, this.intl.locale))
            .then((r) => {
                this.docCategories = r;
            });
    }

    @action.bound
    getFileFormats(): void {
        this.catalogStore
            .getSelectData(apiConfigs.catalogItems(CatalogCodes.fileFormat, this.intl.locale))
            .then((r) => (this.fileFormats = r));
    }

    @action.bound
    enableErrorForm(errorFormCode: string): void {
        this.errorForm = true;
        this.errorFormCode = errorFormCode;
    }

    @action.bound
    disableErrorForm(): void {
        this.errorForm = false;
        this.errorFormCode = '';
    }

    @action.bound
    load(dto: TemplateDTO): CampaignPfTemplateModel {
        Object.assign(this, dto);
        return this;
    }

    @action.bound
    onChangeDocumentCategory(e: ChangeEvent<{}>, value: string | null): void {
        this.docCategory = value ? value : '';
    }

    @action.bound
    onChangeFileFormat(e: ChangeEvent<{}>, value: string): void {
        this.resultFileFormat = value ? value : '';
    }

    @action.bound
    onChangeSignatureSettings(e: ChangeEvent<HTMLInputElement>, fieldKey: keyof SignatureSettings): void {
        this.signatureSettings[fieldKey] = e.target.checked;
    }

    @action.bound
    setCorrectSignatureSettings(): void {
        const signatureFields = Object.keys(this.signatureSettings) as (keyof SignatureSettings)[];

        if (!this.signatureSettings.signatureEnabled) {
            signatureFields.forEach((signatureField) => {
                this.signatureSettings[signatureField] = false;
            });
        }
    }

    @action.bound
    onChangeRequired(): void {
        this.required = !this.required;
    }

    @action.bound
    onChangeFile([file]: File[]): void {
        if (!file) {
            return;
        }

        this.fileDTO = undefined;
        this.fileForUpload = file;
        this.isRejectedFile = false;
    }

    @action.bound
    onRejectedFile(): void {
        this.isRejectedFile = true;
    }

    @action.bound
    onDeleteFile(): void {
        this.fileDTO = undefined;
        this.fileForUpload = undefined;
    }

    @computed
    get templateFileName(): string | undefined {
        return this.fileForUpload?.name || this.fileDTO?.filename;
    }

    @computed
    get dropzoneInitialFiles(): string[] | undefined {
        if (this.templateFileName) {
            return [this.templateFileName];
        }
        return undefined;
    }

    @computed
    get formData(): FormData {
        const data = new FormData();
        const { signatureEnabled, signatureUpload, signatureRequired, signatureBrowserPlugin } = this.signatureSettings;

        data.append('title', this.title);
        data.append('description', this.description);
        data.append('docCategory', this.docCategory);
        data.append('fileFormat', this.resultFileFormat);
        data.append('required', this.required.toString());
        data.append('settings', this.settingsId);
        data.append('signatureEnabled', signatureEnabled.toString());
        data.append('signatureUpload', signatureUpload.toString());
        data.append('signatureRequired', signatureRequired.toString());
        data.append('signatureBrowserPlugin', signatureBrowserPlugin.toString());
        if (this.fileForUpload) {
            data.append('file', this.fileForUpload);
        }
        return data;
    }

    @computed
    get errorTitle(): string {
        if (this.validationStarted && !this.title) {
            return this.intl.formatMessage('validation.required');
        }
        if (this.title.length > this.titleMaxLength) {
            return this.intl.formatMessage('validation.maxLength', { length: this.titleMaxLength });
        }
        return '';
    }

    @computed
    get errorFileFormat(): string {
        if (this.validationStarted && !this.resultFileFormat) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get errorFile(): string {
        if (this.validationStarted && !(!!this.fileForUpload || !!this.fileDTO)) {
            return this.intl.formatMessage('validation.required');
        } else if (this.isRejectedFile) {
            return this.intl.formatMessage('validation.dropzoneError');
        }
        return '';
    }

    @computed
    get errorSignatureMethod(): string {
        if (
            this.validationStarted &&
            this.signatureSettings.signatureEnabled &&
            !this.signatureSettings.signatureUpload &&
            !this.signatureSettings.signatureBrowserPlugin
        ) {
            return this.intl.formatMessage('validation.withoutSignatureMethod');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorTitle && !this.errorFile && !this.errorFileFormat && !this.errorSignatureMethod;
    }
}
