import { action, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { apiConfigs } from '../apiConfigs';
import { NotificationDTO, NotificationsQueryData } from '@platform/notification-widget';
import { di } from 'react-magnetic-di';

export class LkNotificationStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    loadNotifications(queryData: NotificationsQueryData): Promise<NotificationDTO[]> {
        return this.api.client(apiConfigs.loadNotifications(queryData)).then((res) => res.data);
    }

    @action.bound
    loadCountNotViewedMessages(): Promise<number> {
        return this.api.client(apiConfigs.loadCountNotViewedMessages()).then((res) => res.data);
    }

    @action.bound
    changeViewed(id: string, viewed: boolean): Promise<void> {
        return this.api.client(apiConfigs.changeViewed(id, viewed)).then((res) => res.data);
    }

    @action.bound
    deleteNotification(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteNotification(id)).then((res) => res.data);
    }
}

export const getLkNotificationStore = (): any => {
    const [_LkNotificationStore] = di([LkNotificationStore], getLkNotificationStore);
    return _LkNotificationStore;
};
