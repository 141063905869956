import { getAdditionalCampaignRegFormTabsRoutesInj, CampaignSettingsPage } from '../pages';
import { di } from 'react-magnetic-di';

export const getAdditionalCampaignRegFormTabsRoutes = (): string[] => {
    const [getAdditionalCampaignRegFormTabsRoutes] = di(
        [getAdditionalCampaignRegFormTabsRoutesInj],
        CampaignSettingsPage,
    );
    const paths: string[] = [];

    getAdditionalCampaignRegFormTabsRoutes().forEach((route) => {
        paths.push(route.path);

        route.additionalTabLinks.forEach((additionalRoute) => {
            paths.push(additionalRoute);
        });
    });

    return paths;
};
