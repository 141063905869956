import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { AppBar, Box, Container, createStyles, Grid, Link, makeStyles, useMediaQuery } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { ReactComponent as Logo } from '../../resources/images/logo.svg';
import { LanguageButton } from './LanguageButton';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { clientRoute } from '../../clientRoute';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { observer } from 'mobx-react';
import { useFeature } from 'feature-toggle-jsx';
import { ModularNavigationWidget } from '@platform/modular-navigation-widget';
import { useStore } from '../../hooks';
import { RenderPropSticky } from 'react-sticky-el';
import { di } from 'react-magnetic-di';
import { AppTheme } from '../../themes';
import { StartPageHeaderLinks } from '../../pages';
import { AppHeaderLinks } from './app-header-links/AppHeaderLinks';

type RenderPropStickyChildFunctionProps = {
    // в библиотеке нет типизации для компоненты RenderPropSticky
    isFixed: boolean;
    wrapperStyles: React.CSSProperties;
    wrapperRef: React.RefObject<HTMLDivElement>;
    holderStyles: React.CSSProperties;
    holderRef: React.RefObject<HTMLDivElement>;
};

const TotXLogo = withTheme(
    styled.span`
        color: ${({ theme }) => theme.palette.primary.contrastText};
        font-size: 30px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.6px;
    `,
);

const useTotAppBarStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            position: 'relative',
            zIndex: 1000,
        },
    }),
);

export const AdditionalHeaderInj = (): JSX.Element => <React.Fragment />;

export const TotAppBar = observer(
    (props: PropsWithChildren<ThemedComponentProps>): JSX.Element => {
        const { intlStore, modulesStore, personStore, headerHeightStore } = useStore();
        const { loadModulesList } = modulesStore;
        const userId = personStore.user.id;

        const [AdditionalHeader] = di([AdditionalHeaderInj], TotAppBar);

        const [customLogo, customLogoConfig] = useFeature('customLogo');
        const [modularNavigation] = useFeature('modularNavigation');
        const [stickyHeader] = useFeature('stickyHeader');
        const [startPage] = useFeature('startPage');

        const intl = useIntl();
        const logoAlt = intl.formatMessage({ id: 'startPage.logoAlt' });
        const { setHeaderHeight } = headerHeightStore;

        const classes = useTotAppBarStyles();

        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const isSmSize = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('sm'));

        const headerObserverRef = useRef(
            new ResizeObserver((entries) => {
                const headerEntry = entries[0];
                if (headerEntry) {
                    setHeaderHeight(headerEntry.contentRect.height);
                }
            }),
        );
        const headerContentWrapperRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (headerContentWrapperRef.current) {
                headerObserverRef.current.observe(headerContentWrapperRef.current);
            }
            return () => {
                headerObserverRef.current.disconnect();
            };
        }, [headerContentWrapperRef]);

        const handleBurgerChange = (): void => {
            setIsMenuOpen((isOpenMenu) => !isOpenMenu);
        };

        return (
            <RenderPropSticky scrollElement="#root" disabled={!stickyHeader}>
                {({
                    wrapperStyles,
                    wrapperRef,
                    holderStyles,
                    holderRef,
                }: RenderPropStickyChildFunctionProps): JSX.Element => (
                    <div ref={holderRef} style={holderStyles}>
                        <div ref={wrapperRef} className={classes.wrapper} style={{ ...wrapperStyles }}>
                            <div ref={headerContentWrapperRef}>
                                <AdditionalHeader />
                                <AppBar color="default" position="static" elevation={0}>
                                    <Container maxWidth="lg">
                                        <Box pt={3.75} pb={3.75}>
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="flex-start"
                                                justify="space-between"
                                                wrap={isSmSize ? 'wrap' : 'nowrap'}
                                            >
                                                <Grid item style={{ flexShrink: 0 }}>
                                                    <Grid container direction="row" alignItems="center">
                                                        <Grid item>
                                                            <Link
                                                                component={RouterLink}
                                                                to={clientRoute.root}
                                                                underline="none"
                                                            >
                                                                <Grid container direction="row" alignItems="center">
                                                                    {customLogo ? (
                                                                        <img
                                                                            src={customLogoConfig.src}
                                                                            alt={logoAlt}
                                                                            style={customLogoConfig.style}
                                                                        />
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <Grid item style={{ paddingBottom: '0' }}>
                                                                                <Box pr={3}>
                                                                                    <Logo />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <TotXLogo>
                                                                                    <FormattedMessage id="productTitle" />
                                                                                </TotXLogo>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )}
                                                                </Grid>
                                                            </Link>
                                                        </Grid>
                                                        {modularNavigation && userId && (
                                                            <Grid item>
                                                                <ModularNavigationWidget
                                                                    loadModulesList={loadModulesList}
                                                                    locale={intlStore.locale}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ flexGrow: 1 }}>
                                                    <Box mr={3} ml={3} pt={2.5}>
                                                        <Switch>
                                                            <Route exact path={[clientRoute.root, clientRoute.login]}>
                                                                {startPage && <StartPageHeaderLinks />}
                                                            </Route>
                                                            <Route
                                                                path={[
                                                                    clientRoute.notAllowed,
                                                                    clientRoute.notFound,
                                                                    clientRoute.recoveryPassword,
                                                                    clientRoute.registration,
                                                                    clientRoute.authenticationInfo,
                                                                ]}
                                                            />
                                                            <Route>
                                                                <AppHeaderLinks
                                                                    isSmSize={isSmSize}
                                                                    isOpenMenu={isMenuOpen}
                                                                    handleBurgerChange={handleBurgerChange}
                                                                />
                                                            </Route>
                                                        </Switch>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box pt={3}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="center"
                                                            alignItems="center"
                                                            spacing={3}
                                                            wrap="nowrap"
                                                        >
                                                            <Grid item>
                                                                <LanguageButton />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </AppBar>
                            </div>
                        </div>
                    </div>
                )}
            </RenderPropSticky>
        );
    },
);
