import React, { useMemo } from 'react';
import { Box, Button, Grid, useTheme } from '@material-ui/core';
import { EllipsisBox as EllipsisBoxInj } from '../../../components/EllipsisBox';
import { ReactComponent as CreateRequest } from '../../../resources/images/icons/done-outlined-icon.svg';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { CardLabel as CardLabelInj, CardTitle as CardTitleInj, CardValue as CardValueInj } from './RegFormCard.styled';
import { observer } from 'mobx-react';
import { getOrElse } from '../../../utils/getOrElse';
import { FileListComponent as FileListComponentInj } from '../../../components/FileListComponent';
import { FileDTO, FileModel } from '../../../models/FileModel';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick } from '../../../hooks/useAntiDoubleClick';

export type RegFormCardProps = {
    index: number;
    titleRegForm: string;
    description: string;
    registrationStartDate?: Date;
    registrationEndDate?: Date;
    afterDeadline?: boolean;
    reEnrollAllowed?: boolean;
    fileInstructions: Array<FileDTO>;
    onCreate: () => Promise<void>;
    onDownloadFile: (file: FileModel) => Promise<void>;
};

export const RegFormCard = observer(
    (props: RegFormCardProps): JSX.Element => {
        const {
            titleRegForm,
            description,
            registrationStartDate,
            registrationEndDate,
            afterDeadline,
            reEnrollAllowed,
            fileInstructions,
            onDownloadFile,
            onCreate,
            index,
        } = props;

        const [EllipsisBox] = di([EllipsisBoxInj], RegFormCard);
        const [CardLabel] = di([CardLabelInj], RegFormCard);
        const [CardTitle] = di([CardTitleInj], RegFormCard);
        const [CardValue] = di([CardValueInj], RegFormCard);
        const [FileListComponent] = di([FileListComponentInj], RegFormCard);

        const theme = useTheme();
        const [isSending, endIcon, createHandler] = useAntiDoubleClick(onCreate);

        const backgroundColor = useMemo((): object => {
            return index % 2 === 0 ? { backgroundColor: theme?.variables.palette.hoverInLists } : {};
        }, [index, theme]);

        const files: FileModel[] = useMemo(() => {
            return fileInstructions.map((i) => {
                const { id, ...dto } = i;
                return {
                    fileId: id,
                    ...dto,
                };
            });
        }, [fileInstructions]);

        return (
            <Grid container alignItems="flex-start" style={backgroundColor}>
                <Grid item xs={2}>
                    <Box pt={10} pl={10} pr={5}>
                        <CardTitle variant="h2">
                            <EllipsisBox text={titleRegForm} />
                        </CardTitle>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box pt={10} pb={10}>
                        <Box pb={6}>
                            <CardValue>{getOrElse(description)}</CardValue>
                        </Box>
                        <Grid container justify="space-between">
                            <Grid item>
                                <CardLabel>
                                    <FormattedMessage id="campaignRequest.regFormCard.registrationStartDate" />
                                </CardLabel>
                                <CardValue>
                                    {getOrElse(
                                        registrationStartDate,
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <FormattedDate value={registrationStartDate} />
                                            </Grid>
                                            <Grid item>
                                                <FormattedTime value={registrationStartDate} />
                                            </Grid>
                                        </Grid>,
                                        '-',
                                    )}
                                </CardValue>
                            </Grid>
                            <Grid item>
                                <CardLabel>
                                    <FormattedMessage id="campaignRequest.regFormCard.registrationEndDate" />
                                </CardLabel>
                                <CardValue>
                                    {getOrElse(
                                        registrationEndDate,
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <FormattedDate value={registrationEndDate} />
                                            </Grid>
                                            <Grid item>
                                                <FormattedTime value={registrationEndDate} />
                                            </Grid>
                                        </Grid>,
                                        '-',
                                    )}
                                </CardValue>
                            </Grid>
                            <Grid item>
                                <CardLabel>
                                    <FormattedMessage id="campaignRequest.regFormCard.afterDeadline" />
                                </CardLabel>
                                <CardValue>
                                    {getOrElse(
                                        afterDeadline,
                                        <FormattedMessage id="common.yes" />,
                                        <FormattedMessage id="common.no" />,
                                    )}
                                </CardValue>
                            </Grid>
                            <Grid item>
                                <CardLabel>
                                    <FormattedMessage id="campaignRequest.regFormCard.reFeed" />
                                </CardLabel>
                                <CardValue>
                                    {getOrElse(
                                        reEnrollAllowed,
                                        <FormattedMessage id="common.yes" />,
                                        <FormattedMessage id="common.no" />,
                                    )}
                                </CardValue>
                            </Grid>
                        </Grid>
                        <Box pt={6}>
                            <CardLabel>
                                <FormattedMessage id="campaignRequest.regFormCard.instruction" />
                            </CardLabel>
                            <FileListComponent
                                files={files}
                                downloadFile={onDownloadFile}
                                noDataText={
                                    <CardValue>
                                        <FormattedMessage id="common.noData" />
                                    </CardValue>
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2} container justify="flex-end">
                    <Box pt={6} pr={6}>
                        <Button
                            variant="text"
                            color="primary"
                            startIcon={<CreateRequest />}
                            onClick={createHandler}
                            disabled={isSending}
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="campaignRequest.createRequest" />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    },
);
