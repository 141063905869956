import { action, computed, observable } from 'mobx';
import { Api } from '../store/Api';
import { asyncAction } from 'mobx-utils';
import { ChangeEvent, FormEvent } from 'react';
import { EmailModel } from './fields/EmailModel';
import { IntlStore } from '../store/IntlStore';
import { apiConfigs } from '../apiConfigs';

export interface EmailDTO {
    email: string;
    lang: string;
}

export class RecoveryPasswordModel implements EmailModel {
    @observable api: Api;
    @observable intl: IntlStore;

    @observable email = '';

    @observable validationStarted = false;
    @observable status?: 'success' | 'failed';

    constructor(api: Api, intlStore: IntlStore) {
        this.api = api;
        this.intl = intlStore;
    }

    @asyncAction
    @action.bound
    async sendToken(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        this.status = undefined;
        this.validationStarted = true;
        if (this.isValid) {
            try {
                await this.api.client(apiConfigs.forgotPassword(this.asJson));
                this.status = 'success';
            } catch (e) {
                this.status = 'failed';
            }
        }
    }

    @action.bound
    onChangeEmail(event: ChangeEvent<HTMLInputElement>): void {
        this.email = event.target.value;
    }

    @computed
    get asJson(): EmailDTO {
        return {
            email: this.email,
            lang: this.intl.locale,
        };
    }

    @computed
    get sentSucceed(): boolean {
        return this.status === 'success';
    }

    @computed
    get errorEmail(): string {
        if (this.validationStarted) {
            if (!this.email) {
                return this.intl.formatMessage('validation.required');
            }
            if (this.status === 'failed') {
                return this.intl.formatMessage('authentication.recoveryPasswordFailed');
            }
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorEmail;
    }
}
