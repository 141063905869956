import React, { useState } from 'react';
import { Box, Button, Container, Grid, SvgIcon, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { observer } from 'mobx-react';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components/AuthorizationCheck';
import { entities, permissions } from '../../../authSchemeConfig';
import { CampaignCard as CampaignCardInj } from './campaign-card/CampaignCard';
import { CampaignListModel } from '../../../models/campaign/CampaignListModel';
import { ReactComponent as AddCampaign } from '../../../resources/images/icons/add-icon.svg';
import Pagination from '@material-ui/lab/Pagination';
import { BreadcrumbsComponent as BreadcrumbsComponentInj } from '../../../components/breadcrumbs/BreadcrumbsComponent';
import { useModal } from '../../../hooks/useModal';
import { CampaignCreateDialog as CampaignCreateDialogInj } from '../campaign-create/CampaignCreateDialog';
import { useStore } from '../../../hooks/useStore';
import { di } from 'react-magnetic-di';

export const CampaignListPage = observer(
    (): JSX.Element => {
        const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignListPage);
        const [CampaignCard] = di([CampaignCardInj], CampaignListPage);
        const [BreadcrumbsComponent] = di([BreadcrumbsComponentInj], CampaignListPage);
        const [CampaignCreateDialog] = di([CampaignCreateDialogInj], CampaignListPage);

        const { campaignsStore, api, env, breadcrumbsStore } = useStore();
        const [campaignListModel] = useState<CampaignListModel>(new CampaignListModel(api, env.apiUrl));
        const [open, setModalIsOpen, setModalIsClosed] = useModal();
        const history = useHistory();
        const pagesCount = campaignListModel.pagesCount;

        const { breadCrumbsSettings } = breadcrumbsStore;

        const createCampaign = async (lifecycleCode: string): Promise<void> => {
            const id = await campaignsStore.createCampaign(lifecycleCode);
            history.push(generatePath(clientRoute.createCampaign, { id }));
        };

        const changePage = (event: object, page: number): void => {
            campaignListModel.setCurrentPage(page);
        };

        const renderCampaignList = (): JSX.Element[] => {
            return campaignListModel.renderItems.map((campaign) => {
                return (
                    <Grid key={campaign.id} item>
                        <CampaignCard campaign={campaign} />
                    </Grid>
                );
            });
        };

        return (
            <React.Fragment>
                {open && <CampaignCreateDialog createCampaign={createCampaign} onClose={setModalIsClosed} />}
                <Container maxWidth="lg">
                    <Box pt={5.5} pb={5.5}>
                        <Box pb={5}>
                            <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                        </Box>
                        <Grid item container alignItems="center">
                            <Grid item>
                                <Box pr={6.2}>
                                    <Typography variant="h3">
                                        <FormattedMessage id="campaign.titleCampaignListPage" />
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.AddCampaign}
                                >
                                    <Button
                                        startIcon={
                                            <SvgIcon>
                                                <AddCampaign />
                                            </SvgIcon>
                                        }
                                        size="small"
                                        variant="text"
                                        color="primary"
                                        onClick={setModalIsOpen}
                                    >
                                        <FormattedMessage id="campaign.create" />
                                    </Button>
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box pb={12}>
                        {campaignListModel.paginationData.length === 0 ? (
                            <Typography variant="subtitle1" color="primary">
                                <FormattedMessage id="campaign.noCategoriesText" />
                            </Typography>
                        ) : (
                            <Grid container alignItems="center" spacing={10}>
                                {renderCampaignList()}
                            </Grid>
                        )}
                    </Box>

                    {pagesCount > 1 && (
                        <Box pb={12}>
                            <Pagination
                                count={pagesCount}
                                hidePrevButton
                                hideNextButton
                                size="large"
                                color="secondary"
                                onChange={changePage}
                            />
                        </Box>
                    )}
                </Container>
            </React.Fragment>
        );
    },
);
