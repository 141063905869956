import React from 'react';
import styled from 'styled-components';
import { getEmailFromText as getEmailFromTextInj } from '../../utils';
import { getPhoneNumberFromText as getPhoneNumberFromTextInj } from '../../utils/getPhoneNumberFromText';
import { Box, Button, Grid } from '@material-ui/core';
import { ReactComponent as PhoneIcon } from '../../resources/images/icons/phone-alt.svg';
import { ReactComponent as MailIcon } from '../../resources/images/icons/envelope.svg';
import { FooterTypography as FooterTypographyInj } from './FooterTypography';
import { withTheme } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di';
import { useFeature } from 'feature-toggle-jsx';

export const FooterButtonInj = withTheme(
    styled(Button)`
        .MuiButton-label {
            align-items: center;
        }

        .MuiTypography-root {
            font-weight: bold;
        }
    `,
);

export type FooterContactInformationProps = {
    phoneNumberText?: string;
    emailText?: string;
};

export const FooterContactInformation = (props: FooterContactInformationProps): JSX.Element => {
    const [FooterButton] = di([FooterButtonInj], FooterContactInformation);
    const [FooterTypography] = di([FooterTypographyInj], FooterContactInformation);
    const [getEmailFromText] = di([getEmailFromTextInj], FooterContactInformation);
    const [getPhoneNumberFromText] = di([getPhoneNumberFromTextInj], FooterContactInformation);

    const [phoneNumber] = useFeature('phoneNumber');

    const { phoneNumberText = '', emailText = '' } = props;

    const hrefTel = getPhoneNumberFromText(phoneNumberText);

    const hrefEmail = getEmailFromText(emailText);

    return (
        <Grid item>
            <Grid container direction="row" alignItems="center" justify="flex-end">
                {phoneNumber && hrefTel && (
                    <Grid item>
                        <Box pr={3}>
                            <FooterButton
                                component="a"
                                href={`tel:${hrefTel}`}
                                startIcon={<PhoneIcon />}
                                variant="text"
                                color="secondary"
                            >
                                <FooterTypography component="span">{phoneNumberText}</FooterTypography>
                            </FooterButton>
                        </Box>
                    </Grid>
                )}
                {hrefEmail && (
                    <Grid item>
                        <FooterButton
                            component="a"
                            href={`mailto:${hrefEmail}`}
                            startIcon={<MailIcon />}
                            variant="text"
                            color="secondary"
                        >
                            <FooterTypography component="span">{emailText}</FooterTypography>
                        </FooterButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
