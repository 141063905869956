import { action, observable } from 'mobx';
import { di } from 'react-magnetic-di';

export class HeaderHeightStore {
    @observable headerHeight?: number;

    @action.bound
    setHeaderHeight(height: number): void {
        this.headerHeight = height;
    }
}

export const getHeaderHeightStore = (): any => {
    const [_HeaderHeightStore] = di([HeaderHeightStore], getHeaderHeightStore);
    return _HeaderHeightStore;
};
