import React, { useCallback, useMemo, useState } from 'react';
import { Box, Container, createStyles, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BreadcrumbsComponent as BreadcrumbsComponentInj } from '../../../components/breadcrumbs/BreadcrumbsComponent';
import { useStore } from '../../../hooks/useStore';
import { TTableRow } from '@platform/ttable';
import { IdTitle } from '../../../models/IdTitle';
import { useModal } from '../../../hooks';
import { ExecutorEditDialog as ExecutorEditDialogInj } from '../campaign-request-page/ExecutorEditDialog';
import { CampaignRequestActionsBtns as CampaignRequestActionsBtnsInj } from './CampaignRequestActionsBtns';
import { CampaignRequestRowNewDTO } from '../../../store';
import { CampaignRequestTable as CampaignRequestTableInj } from './CampaignRequestTable';
import { CampaignRequestRow } from '../../../models';
import { di } from 'react-magnetic-di';
import { makeStyles } from '@material-ui/core/styles';

export type RequestRow = TTableRow & {
    number: string;
    campaign: IdTitle;
};

const useCampaignRequestLisPageNewStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {
            width: '100%',
        },
    }),
);

export const CampaignRequestListPageNew = observer(
    (): JSX.Element => {
        const [BreadcrumbsComponent] = di([BreadcrumbsComponentInj], CampaignRequestListPageNew);
        const [ExecutorEditDialog] = di([ExecutorEditDialogInj], CampaignRequestListPageNew);
        const [CampaignRequestActionsBtns] = di([CampaignRequestActionsBtnsInj], CampaignRequestListPageNew);
        const [CampaignRequestTable] = di([CampaignRequestTableInj], CampaignRequestListPageNew);

        const { requestStore, breadcrumbsStore } = useStore();
        const [requestRowModel, setRequestRowModel] = useState<CampaignRequestRow>();

        const { breadCrumbsSettings } = breadcrumbsStore;

        const [editExecutorDialogOpen, setEditExecutorDialogOpen, setEditExecutorDialogClosed] = useModal();
        const [editExecutorDialogTitle, setEditExecutorDialogTitle] = useState<string>();
        const [isConfirm, setIsConfirm] = useState<boolean>(false);

        const classes = useCampaignRequestLisPageNewStyles();

        const handleEditExecutorDialogOpen = useCallback(
            (campaignRow: CampaignRequestRow, title?: string): void => {
                setEditExecutorDialogOpen();
                setRequestRowModel(campaignRow);
                title && setEditExecutorDialogTitle(title);
            },
            [setEditExecutorDialogOpen, setRequestRowModel, setEditExecutorDialogTitle],
        );

        const setRowActions = useMemo(
            () => (row: CampaignRequestRowNewDTO, reloadData: () => void): JSX.Element => {
                const correctRowData = requestStore.mapDtoToRow(row);

                return (
                    <CampaignRequestActionsBtns
                        campaignRow={correctRowData}
                        reloadData={reloadData}
                        handleEditExecutorDialogOpen={handleEditExecutorDialogOpen}
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                    />
                );
            },
            [isConfirm],
        );

        return (
            <React.Fragment>
                <ExecutorEditDialog
                    title={editExecutorDialogTitle}
                    onClose={setEditExecutorDialogClosed}
                    open={editExecutorDialogOpen}
                    requestRowModel={requestRowModel}
                    setIsConfirm={setIsConfirm}
                />

                <Container maxWidth="lg">
                    <Box pt={5.5} pb={15}>
                        <Box pb={5}>
                            <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                        </Box>
                        <Grid container direction="column">
                            <Grid item className={classes.tableWrapper}>
                                <CampaignRequestTable setRowActions={setRowActions} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </React.Fragment>
        );
    },
);
