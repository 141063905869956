import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { UserListPage as UserListPageInj } from './user-list/UserListPage';
import { UserPage as UserPageInj } from './UserPage';
import { Authentication as AuthenticationInj } from '../../components/Authentication';
import { di } from 'react-magnetic-di';

export const UserPages = (): JSX.Element => {
    const [Authentication] = di([AuthenticationInj], UserPages);
    const [UserPage] = di([UserPageInj], UserPages);
    const [UserListPage] = di([UserListPageInj], UserPages);

    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.user}>
                    <UserPage />
                </Route>
                <Route path={clientRoute.users}>
                    <UserListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
