import { action, computed, observable } from 'mobx';
import { History } from 'history';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { FormDTO, FormModel, FullSubmission } from '@platform/formiojs-react';
import downloadFile from 'js-file-download';
import { apiConfigs } from '../apiConfigs';
import {
    AccessPermission,
    CampaignInfoModel,
    CampaignPfTemplateModel,
    CampaignRegFormInfoModel,
    CodeTitle,
    FileDTO,
    FileModel,
    GroupedIdTitle,
    IdTitle,
    RequestFormSettingModel,
    TransitionsDTO,
    UserNameDTO,
    UserNameModel,
} from '../models';
import { SignatureSettings } from '@platform/crypto-ui';
import { AxiosPromise, AxiosResponse } from 'axios';
import { clientRoute } from '../clientRoute';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { NotificationStore } from './NotificationStore';
import { Executor, ExecutorSetting, ExecutorSettings, RequestFormTabSettingsDTO } from '../pages';
import { generatePath } from 'react-router-dom';
import { EmployeeDTO } from '../types';
import { di } from 'react-magnetic-di';

export interface CampaignInfoForCardDTO {
    id: string;
    title: string;
    avatarId?: string;
    from?: string;
    to?: string;
    requestCount: number;
}

export interface CampaignInfoDTO {
    id: string;
    title: string;
    avatarId?: string;
    annotation?: string;
    from?: string;
    to?: string;
    files: FileDTO[];
}

export interface CampaignRegFormDTO {
    id: string;
    campaignId: string;
    requestFormCode: string;
    title: string;
    procedureTitle?: string;
    registrationStart: string; // date
    registrationEnd: string; // date
    reEnrollmentForm?: boolean;
    allowAfterDeadline?: boolean;
}

export interface TemplateDTO {
    id: string;
    title: string;
    description?: string;
    documentCategories: IdTitle[];
    signatureSettings: SignatureSettings;
    required: boolean;
    file: FileDTO;
}

export interface TemplateTabDTO {
    settingsId: string;
    settingsTitle: string;
    pfs: TemplateDTO[];
}

export interface RequestFormSettingListDTO {
    id: string;
    title: string;
    formTitle?: string;
    position: number;
}

export class CampaignsStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @computed
    get notificationStore(): NotificationStore {
        return this.rootStore.notificationStore;
    }

    @action.bound
    getCampaignInfoModel(id: string): CampaignInfoModel {
        const model = new CampaignInfoModel(this.rootStore.env.apiUrl);
        this.loadCampaignInfo(id).then(model.load);
        return observable(model);
    }

    @action.bound
    createCampaign(lifecycleCode: string): Promise<string> {
        return this.api.client(apiConfigs.createCampaign(lifecycleCode)).then((r) => r.data.id);
    }

    @action.bound
    loadFile(id: string): Promise<string> {
        return this.api.client(apiConfigs.campaignFile(id)).then((r) => r.data);
    }

    @action.bound
    loadCampaignInfo(id: string): Promise<CampaignInfoDTO> {
        return this.api
            .client(apiConfigs.campaign(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadCampaignForm(id: string): Promise<FormDTO> {
        return this.api
            .client(apiConfigs.campaignForm(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    saveCampaignForm(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.editCampaign(id, submission)).then((r) => r.data);
    }

    @action.bound
    downloadFile(file: FileModel): Promise<void> {
        return this.api
            .client(apiConfigs.campaignFile(file.fileId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, file.filename, file.mimeType));
    }

    @action.bound
    downloadRegFormFile(file: FileModel): Promise<void> {
        return this.api
            .client(apiConfigs.regFormFile(file.fileId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, file.filename, file.mimeType));
    }

    @action.bound
    createRegForm(campaignId: string): Promise<string> {
        return this.api.client(apiConfigs.createRegForm(campaignId)).then((r) => r.data.id);
    }

    @action.bound
    loadRegForms(campaignId: string, minimal = false, edit?: boolean): Promise<CampaignRegFormDTO[]> {
        return this.api
            .client(apiConfigs.regForms(campaignId, minimal, edit))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    changeRegFormsPosition(id: string, newPosition: number): AxiosPromise<void> {
        return this.api.client(apiConfigs.changeRegFormsPosition(id, newPosition));
    }

    @action.bound
    regFormsByCampaignsForSelect(campaigns: string[]): Promise<GroupedIdTitle> {
        return this.api.client(apiConfigs.regFormsByCampaignsForSelect(campaigns)).then((r) => r.data);
    }

    @action.bound
    loadRegFormInfo(id: string): Promise<CampaignRegFormDTO> {
        return this.api
            .client(apiConfigs.regFormCard(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadRegForm(id: string): Promise<FormDTO> {
        return this.api
            .client(apiConfigs.regForm(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    saveRegForm(id: string, submission: FullSubmission): AxiosPromise<void> {
        return this.api.client(apiConfigs.editRegForm(id, submission)).then((r) => r.data);
    }

    @action.bound
    deleteRegForm(id: string): AxiosPromise<void> {
        return this.api.client(apiConfigs.deleteRegForm(id));
    }

    @action.bound
    deleteCampaign(id: string): AxiosPromise<void> {
        return this.api.client(apiConfigs.deleteCampaign(id));
    }

    @action.bound
    getRegFormModel(id: string): FormModel {
        const model = new FormModel(id);
        this.loadRegForm(id).then(model.load);
        return model;
    }

    @action.bound
    getRegFormInfoModel(id: string): CampaignRegFormInfoModel {
        const model = new CampaignRegFormInfoModel(id);
        this.loadRegFormInfo(id).then(model.load);
        return model;
    }

    @action.bound
    loadTemplates(regFormId: string): Promise<TemplateTabDTO[]> {
        return this.api
            .client(apiConfigs.templates(regFormId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadTemplate(id: string): Promise<TemplateDTO> {
        return this.api
            .client(apiConfigs.template(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    createTemplate(regFormId: string, model: CampaignPfTemplateModel): Promise<string> {
        return this.api.client(apiConfigs.createTemplate(regFormId, model.formData)).then((r) => r.data);
    }

    @action.bound
    saveTemplate(model: CampaignPfTemplateModel): Promise<void> {
        return this.api.client(apiConfigs.saveTemplate(model.id, model.formData)).then((r) => r.data);
    }

    @action.bound
    deleteTemplate(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteTemplate(id)).then((r) => r.data);
    }

    @action.bound
    changeTemplatePosition(templateId: string, newPosition: number): AxiosPromise<void> {
        return this.api.client(apiConfigs.changeTemplatePosition(templateId, newPosition));
    }

    @action.bound
    downloadTemplateFile(fileDTO: FileDTO): void {
        this.api
            .client(apiConfigs.downloadTemplateFile(fileDTO.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    @action.bound
    getLifecycleList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getLifecycleList).then((r) => r.data);
    }

    @action.bound
    getActualCampaignList(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.getActualCampaignList).then((r) => r.data);
    }

    @action.bound
    getTransitionCampaign(requestId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.transitionCampaign(requestId)).then((r) => r.data);
    }

    @action.bound
    transitionToNextLifeCycleStepCampaign(transitionId: string, requestId: string): Promise<void> {
        return this.api
            .client(apiConfigs.transitionToNextLifeCycleStepCampaign(transitionId, requestId))
            .then((r) => r.data);
    }

    @action.bound
    getCampaignDTO(campaignId: string): Promise<CampaignInfoDTO> {
        return this.api
            .client(apiConfigs.campaign(campaignId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    getFormForGeneralSettings(campaignId: string): FormModel {
        const model = new FormModel(campaignId);
        this.loadFormForGeneralSettings(campaignId).then(model.load);
        return model;
    }

    @action.bound
    loadFormForGeneralSettings(campaignId: string): Promise<FormDTO> {
        return this.api
            .client(apiConfigs.getFormForGeneralSettings(campaignId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    saveFormForGeneralSettings(id: string, submission: FullSubmission): AxiosPromise<void> {
        return this.api.client(apiConfigs.saveFormForGeneralSettings(id, submission));
    }

    @action.bound
    requestFormSettings(regFormId: string): Promise<RequestFormSettingListDTO[]> {
        return this.api.client(apiConfigs.requestFormSettings(regFormId)).then((r) => r.data);
    }

    @action.bound
    loadExecutors(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.loadExecutors()).then((r) => r.data);
    }

    @action.bound
    loadPermissions(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.loadPermissions()).then((r) => r.data);
    }

    @action.bound
    loadStates(id: string): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.loadStates(id)).then((r) => r.data);
    }

    @action.bound
    loadFormCode(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.loadFormCode()).then((r) => r.data);
    }

    @action.bound
    createRequestFormSetting(regFormId: string, dto: RequestFormTabSettingsDTO): Promise<string> {
        return this.api.client(apiConfigs.createRequestFormSetting(regFormId, dto)).then((r) => r.data.id);
    }

    @action.bound
    loadRequestFormSetting(id: string): Promise<RequestFormTabSettingsDTO> {
        return this.api.client(apiConfigs.loadRequestFormSetting(id)).then((r) => r.data);
    }

    @action.bound
    getRequestFormSetting(model: RequestFormSettingModel, id: string): void {
        this.loadRequestFormSetting(id).then(model.load);
    }

    @action.bound
    saveRequestFormSetting(id: string, dto: RequestFormTabSettingsDTO): Promise<void | number> {
        return this.api.client(apiConfigs.saveRequestFormSetting(id, dto)).then((r) => r.data);
    }

    @action.bound
    deleteRequestFormSetting(id: string): Promise<string> {
        return this.api
            .client(apiConfigs.deleteRequestFormSetting(id))
            .then((r) => r.data)
            .catch((error) => {
                this.notificationStore.onError(error.response.data);
            });
    }

    @action.bound
    changePositionRequestFormSetting(id: string, position: number): Promise<void> {
        return this.api.client(apiConfigs.changePositionRequestFormSetting(id, position)).then((r) => r.data);
    }

    @action.bound
    fetchExecutorList(regFormId: string): Promise<Executor[]> {
        return this.api.client(apiConfigs.fetchExecutorList(regFormId)).then((r) => r.data);
    }

    @action.bound
    fetchExecutorsSettings(regFormId: string): Promise<ExecutorSetting[]> {
        return this.api.client(apiConfigs.fetchExecutorsSettings(regFormId)).then((r) => r.data);
    }

    @action.bound
    async fetchUsers(): Promise<IdTitle[]> {
        const model = new UserNameModel();
        const { data }: AxiosResponse<UserNameDTO[]> = await this.api.client(apiConfigs.fetchUsers());
        return data.map((user: UserNameDTO) => {
            model.load(user);
            return model.asIdTitle;
        });
    }

    @action.bound
    async fetchAllEmployee(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.getAllActiveEmployees())
            .then((r) => r.data)
            .then((employees) => {
                return employees.map((employee: EmployeeDTO) => {
                    const author = new UserNameModel().load({ ...employee, name: employee.person.firstName });
                    return {
                        id: employee.userId,
                        title: author.name,
                    };
                });
            });
    }

    @action.bound
    addExecutor(regFormId: string, settings: ExecutorSettings): Promise<void> {
        return this.api.client(apiConfigs.addExecutor(regFormId, settings)).then((r) => r.data);
    }

    @action.bound
    deleteExecutor(regFormId: string, settingsExecId: string): Promise<void> {
        return this.api.client(apiConfigs.deleteExecutor(regFormId, settingsExecId)).then((r) => r.data);
    }

    @action.bound
    createRequest(campaignId: string): Promise<void> {
        const { requestStore } = this.rootStore;
        return this.loadRegForms(campaignId).then((regForms: IdTitle[]) => {
            if (regForms.length === 1) {
                const regFormId = regForms[0].id;
                requestStore.createRequest(regFormId).then((requestId: string) => {
                    this.history.push(generatePath(clientRoute.requestEdit, { id: requestId }));
                });
            } else {
                this.history.push(generatePath(clientRoute.regFormSelect, { campaignId }));
            }
        });
    }

    @action.bound
    loadTemplatePermissions(settingsId: string): Promise<AccessPermission[]> {
        return this.api.client(apiConfigs.loadTemplatePermissions(settingsId)).then((r) => r.data);
    }

    @action.bound
    saveTemplatePermissions(settingsId: string, permissions: AccessPermission[]): Promise<void> {
        return this.api.client(apiConfigs.savedTemplatePermissions(settingsId, permissions)).then((r) => r.data);
    }
}

export const getCampaignsStore = (): any => {
    const [_CampaignsStore] = di([CampaignsStore], getCampaignsStore);
    return _CampaignsStore;
};
