class ClientRoute {
    root = '/';
    notFound = '/404';
    notAllowed = '/403';
    logout = '/logout';

    campaigns = '/campaigns';
    campaign = '/campaigns/:id';
    createCampaign = '/campaigns/:id/create';
    campaignSettings = '/campaigns/:id/settings';
    campaignSettingsEdit = '/campaigns/:id/settings/edit';
    campaignRegForms = '/campaigns/:id/settings/rf';
    campaignRegForm = '/campaigns/:id/settings/rf/:rfId/info';
    campaignRegFormExecutors = '/campaigns/:id/settings/rf/:rfId/executors';
    campaignRegFormTabsSettings = '/campaigns/:id/settings/rf/:rfId/tabsSettings';
    campaignRequestFormTabSetting = '/campaigns/:id/settings/rf/:rfId/tabsSettings/:settingId/edit';
    campaignRequestFormTabSettingCreate = '/campaigns/:id/settings/rf/:rfId/tabsSettings/:settingId/create';
    campaignRegFormTemplates = '/campaigns/:id/settings/rf/:rfId/templates';
    campaignRegFormTemplatesEdit = '/campaigns/:id/settings/rf/:rfId/templates/:templateId/settings/:settingsId/edit';
    campaignRegFormTemplatesCreate = '/campaigns/:id/settings/rf/:rfId/templates/settings/:settingsId/create';
    campaignRegFormTemplatesPermissionSettings =
        '/campaigns/:id/settings/rf/:rfId/templates/settings/:settingsId/permissionSettings';
    campaignRegFormCreate = '/campaigns/:id/settings/rf/:rfId/create';
    campaignRegFormEdit = '/campaigns/:id/settings/rf/:rfId/edit';
    campaignDocs = '/campaigns/:id/settings/docs';
    campaignManagement = '/campaigns/:id/settings/management';
    generalSettings = '/campaigns/:id/settings/general';

    requests = '/requests';
    requestsNew = '/requests/new';
    regFormSelect = '/campaigns/:campaignId/regForms';

    request = '/requests/:id';
    requestForm = '/requests/:id/form/:formId';

    requestEdit = '/requests/:id/edit';
    requestFormEdit = '/requests/:id/form/:formId/edit';

    requestApprovalSheets = '/requests/:id/approval';

    users = '/users';
    user = '/users/:id';
    userEdit = '/users/:id/edit';

    login = '/login';
    recoveryPassword = '/recovery';
    newPassword = '/recovery/:token';
    registration = '/registration';
    authenticationInfo = '/info';
    registrationInfoSuccess = '/info/registration/success';
    registrationInfoExpired = '/info/registration/expired';
    registrationInfoNotFound = '/info/registration/404';
    recoveryPasswordInfoSuccess = '/info/recovery-password/success';
    registrationConfirm = '/registration/confirm/:token';

    esia = '/esia';
    esiaGetAuthUrl = '/esia/auth-url';
    esiaLogin = '/esia/login';

    saml = '/saml';
    samlGetAuthUrl = '/saml/:provider/auth-url';

    filteringWithCampaign = (campaignId: string): string => this.requests + `?campaignId=${campaignId}`;
}

export const clientRoute = new ClientRoute();
