import { action, computed, observable } from 'mobx';
import { Api } from '../store/Api';
import { asyncAction } from 'mobx-utils';
import { ChangeEvent, FormEvent } from 'react';
import { EmailModel } from './fields/EmailModel';
import { PasswordModel } from './fields/PasswordModel';
import { IntlStore } from '../store/IntlStore';
import { apiConfigs } from '../apiConfigs';
import { PersonStore } from '../store/PersonStore';

export interface LoginDTO {
    email: string;
    password: string;
    rememberMe: boolean;
}

export class LoginModel implements EmailModel, PasswordModel {
    @observable api: Api;
    @observable intl: IntlStore;
    @observable personStore: PersonStore;

    @observable email = '';
    @observable password = '';
    @observable rememberMe = false;

    @observable validationStarted = false;
    @observable loginStatus?: 'success' | 'failed';
    @observable loginStatusCode?: number;

    constructor(api: Api, intlStore: IntlStore, personStore: PersonStore) {
        this.api = api;
        this.intl = intlStore;
        this.personStore = personStore;
    }

    @asyncAction
    @action.bound
    async login(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        this.validationStarted = true;
        if (this.isValid) {
            try {
                await this.api.client(apiConfigs.login(this.asJson));
                await this.api.authVerify();
                await this.personStore.getInfo();
                this.loginStatus = 'success';
            } catch (e) {
                const { status } = e.response;

                this.loginStatus = 'failed';
                this.loginStatusCode = status;
            }
        }
    }

    @action.bound
    onChangeEmail(event: ChangeEvent<HTMLInputElement>): void {
        this.email = event.target.value;
    }

    @action.bound
    onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void {
        this.password = event.target.value;
    }

    @action.bound
    onChangeRememberMe(event: ChangeEvent, checked: boolean) {
        this.rememberMe = checked;
    }

    @computed
    get asJson(): LoginDTO {
        return {
            email: this.email,
            password: this.password,
            rememberMe: this.rememberMe,
        };
    }

    @computed
    get loginFailedMessage(): string {
        switch (this.loginStatusCode) {
            case 401:
                return this.intl.formatMessage('authentication.loginFailed');
            case 403:
                return this.intl.formatMessage('authentication.userBlocked');
            case 404:
                return this.intl.formatMessage('authentication.emailNotExist');
            case 405:
                return this.intl.formatMessage('authentication.emailNotConfirmed');
        }

        return '';
    }

    @computed
    get loginSucceed(): boolean {
        return this.loginStatus === 'success';
    }

    @computed
    get errorEmail(): string {
        if (this.validationStarted && !this.email) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get errorPassword(): string {
        if (this.validationStarted && !this.password) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorEmail && !this.errorPassword;
    }
}
