import { FormDTO } from '@platform/formiojs-react';
import { IdTitle } from '../../models/IdTitle';
import { PersonDTO } from '../../models/person/PersonModel';

export interface RequestDTO {
    id: string;
    number: string;
    regFormTitle: string;
    campaign: IdTitle;
    state: string;
    customTabs: TabDTO[];
    executors: EmployeeDTO[];
}

export interface TabDTO {
    id: string;
    title: string;
}

export interface RequestFormDTO {
    id: string;
    formInfo: FormDTO;
    tabExecutor: string;
}

export type EmployeeDTO = {
    userId: string;
    person: PersonDTO;
};

export enum DeadlineStatus {
    VALID = 'valid',
    WARNING = 'warning',
    INVALID = 'invalid',
    ERROR = 'error',
}

export type DeadlineDTO = {
    date?: Date;
    status: DeadlineStatus;
    delay: boolean;
};

export type DeadlinesDTO = {
    entityDeadline?: DeadlineDTO;
    stateDeadline?: DeadlineDTO;
};
