import React, { useEffect } from 'react';
import {
    Route,
    Switch,
    RouteComponentProps,
    Redirect,
    generatePath,
    useParams,
    useHistory,
    useLocation,
} from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { observer } from 'mobx-react';
import { RequestModel } from '../../../models';
import { CampaignRequestForm as CampaignRequestFormInj } from './CampaignRequestForm';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { ApprovalSheetDTO, ApprovalTab } from '@platform/approval-ui';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../components';
import { StyledCircularProgress } from './campaign-request-page-print-forms';
import { Box } from '@material-ui/core';

export type CampaignRequestRoutesProps = {
    locale: string;
    requestModel: RequestModel;
    setFormIsChanged: (formIsChanged: boolean) => void;
    requestNumber: string;
    reloadModelForModuleTab: () => void;
};

export type RequestFormParams = {
    id: string;
    formId: string;
};

export const CampaignRequestRoutes = observer(
    (props: CampaignRequestRoutesProps): JSX.Element => {
        const { requestModel, setFormIsChanged, requestNumber, reloadModelForModuleTab } = props;
        const { id } = useParams<RequestFormParams>();

        const [CampaignRequestForm] = di([CampaignRequestFormInj], CampaignRequestRoutes);

        const location = useLocation();
        const history = useHistory();

        const { approvalStore, intlStore, personStore } = useStore();
        const {
            loadApprovalSheets,
            sheets,
            isSheetsLoading,
            hasApprovalTab,
            changeApprovalSheetStatus,
            updateApprovalSheet,
            sendApprovalSheetResolution,
            saveApprovalSheetGroups,
            getAllUsers,
        } = approvalStore;

        useEffect(() => {
            loadApprovalSheets(id);
        }, [loadApprovalSheets, id]);

        useEffect(() => {
            if (location.pathname === generatePath(clientRoute.request, { id }) && requestModel.firstFormId) {
                history.push(generatePath(clientRoute.requestForm, { id, formId: requestModel.firstFormId }));
            }
        }, [location.pathname, requestModel.firstFormId, history, id]);

        const firstFormRedirect = requestModel.firstFormId && (
            <Redirect to={generatePath(clientRoute.requestForm, { id, formId: requestModel.firstFormId })} />
        );

        const getApprovalTabOrRedirect = (): JSX.Element | null =>
            hasApprovalTab ? (
                <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                    {(allowed: boolean): JSX.Element => (
                        <ApprovalTab
                            sheets={sheets?.toJS() as ApprovalSheetDTO[]}
                            locale={intlStore.locale}
                            currentUserId={personStore.user.id}
                            isAdmin={allowed}
                            updateApprovalSheet={updateApprovalSheet}
                            saveApprovalSheetGroups={saveApprovalSheetGroups}
                            changeApprovalSheetStatus={changeApprovalSheetStatus(reloadModelForModuleTab)}
                            sendApprovalSheetResolution={sendApprovalSheetResolution(reloadModelForModuleTab)}
                            getAllUsers={getAllUsers}
                        />
                    )}
                </AuthorizationCheck>
            ) : (
                firstFormRedirect || null
            );

        return (
            <Switch>
                <Route path={clientRoute.requestEdit} key={clientRoute.requestEdit} exact>
                    {requestModel.firstFormId && (
                        <Redirect
                            to={generatePath(clientRoute.requestFormEdit, { id, formId: requestModel.firstFormId })}
                        />
                    )}
                </Route>
                <Route
                    path={clientRoute.requestForm}
                    key={clientRoute.requestForm}
                    render={(props: RouteComponentProps<RequestFormParams>) => (
                        <CampaignRequestForm
                            key={props.match.params.formId}
                            requestModel={requestModel}
                            setFormIsChanged={setFormIsChanged}
                            requestNumber={requestNumber}
                        />
                    )}
                />
                <Route path={clientRoute.requestApprovalSheets} key={clientRoute.requestApprovalSheets}>
                    {isSheetsLoading ? (
                        <Box px={5}>
                            <StyledCircularProgress />
                        </Box>
                    ) : (
                        getApprovalTabOrRedirect()
                    )}
                </Route>
            </Switch>
        );
    },
);
