import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, Button, Checkbox, Dialog, FormControlLabel, Grid, IconButton } from '@material-ui/core';
import { AccessPermission, CodeTitle, RequestFormSettingModel } from '../../../../../../models';
import { observer } from 'mobx-react';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteProps, textFieldProps } from '../../../../../campaign-request';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    chipProps,
    editMessage,
    noOptionsText,
    renderOptionCampaignRegForm as renderOptionCampaignRegFormInj,
    roleMessage,
    statusMessage,
    useAccessPermissionStyles as useAccessPermissionStylesInj,
} from './constants';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CampaingPfTemplateTabModel } from '../../../../../../models/campaign-settings-docs-templates/CampaingPfTemplateTabModel';

export type EditPermissionDialogProps = {
    model: RequestFormSettingModel | CampaingPfTemplateTabModel;
    currentPermission: number;
    closeDialog: () => void;
    open: boolean;
    checkboxLabel?: string | JSX.Element;
};

export const EditPermissionDialog = observer(
    (props: EditPermissionDialogProps): JSX.Element => {
        const { model, currentPermission, closeDialog, open, checkboxLabel = editMessage } = props;

        const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], EditPermissionDialog);
        const [useAccessPermissionStyles] = di([useAccessPermissionStylesInj], EditPermissionDialog);
        const [renderOptionCampaignRegForm] = di([renderOptionCampaignRegFormInj], EditPermissionDialog);

        const currentRow = useMemo(() => model.permissions[currentPermission], [model, currentPermission]);

        const [newPermission, setNewPermission] = useState<CodeTitle>(currentRow.permission);
        const [newStates, setNewStates] = useState<CodeTitle[]>(currentRow.states);
        const [newEditPermission, setNewEditPermission] = useState<boolean>(currentRow.edit);

        const intl = useIntl();

        const updatedPermission: AccessPermission = useMemo(
            () => ({
                permission: newPermission,
                states: newStates,
                edit: newEditPermission,
            }),
            [newPermission, newStates, newEditPermission],
        );

        const classes = useAccessPermissionStyles();

        const onChangeNewPermission = useCallback(
            (e: ChangeEvent<{}>, value: CodeTitle | any): void => {
                value && setNewPermission(value);
            },
            [setNewPermission],
        );

        const onChangeNewStates = useCallback(
            (e: ChangeEvent<{}>, value: CodeTitle[] | any): void => {
                value && setNewStates(value);
            },
            [setNewStates],
        );

        const onChangeNewEditPermission = useCallback((): void => {
            setNewEditPermission((prevValue) => !prevValue);
        }, [setNewPermission]);

        const submit = useCallback((): void => {
            model.handleUpdatePermission(currentPermission, updatedPermission);
            closeDialog();
        }, [model, currentPermission, updatedPermission, closeDialog]);

        return (
            <Dialog maxWidth="sm" fullWidth open={open} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={closeDialog}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid item container spacing={4} direction="column">
                        <Grid item xs={12}>
                            <Autocomplete
                                {...AutocompleteProps}
                                value={newPermission}
                                onChange={onChangeNewPermission}
                                popupIcon={<ExpandMoreIcon />}
                                noOptionsText={noOptionsText}
                                ChipProps={chipProps}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.title}
                                options={model.permissionsList}
                                renderInput={renderAutoCompleteInput(
                                    roleMessage,
                                    true,
                                    model.permissionMessage,
                                    textFieldProps,
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                {...AutocompleteProps}
                                multiple
                                disableCloseOnSelect
                                value={newStates.slice()}
                                onChange={onChangeNewStates}
                                popupIcon={<ExpandMoreIcon />}
                                noOptionsText={noOptionsText}
                                ChipProps={chipProps}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.title}
                                options={model.statesList}
                                renderInput={renderAutoCompleteInput(statusMessage, false, '', {
                                    ...textFieldProps,
                                    placeholder: newStates.length ? '' : intl.formatMessage({ id: 'common.all' }),
                                })}
                                renderOption={renderOptionCampaignRegForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        className={classes.editPermission}
                                        checked={newEditPermission}
                                        onChange={onChangeNewEditPermission}
                                    />
                                }
                                label={checkboxLabel}
                            />
                        </Grid>
                    </Grid>
                    <Box pt={4} pr={4}>
                        <Grid container spacing={2} justify="center" direction="column">
                            <Grid item>
                                <Button color="primary" fullWidth variant="contained" onClick={submit}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={closeDialog} fullWidth variant="contained">
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);
