import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Checkbox,
    Chip,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    Portal,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Select as SelectInj, TotDropzoneArea as TotDropzoneAreaInj } from '../../../../../../../components';
import { CampaignPfTemplateListModel, CampaignPfTemplateModel } from '../../../../../../../models';
import { FormattedMessage } from 'react-intl';
import { clientRoute } from '../../../../../../../clientRoute';
import Alert from '@material-ui/lab/Alert';
import { useStore } from '../../../../../../../hooks';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { di } from 'react-magnetic-di';
import { DigitalSignaturePfFormFields as DigitalSignaturePfFormFieldsInj } from './DigitalSignaturePfFormFields';
import { bannedSymbols } from '../../../../../../../resources/constants';

const errorFormMessages: Record<string, JSX.Element> = {
    notUniqueTemplateName: <FormattedMessage id="campaign.templateFormErrors.notUniqueTemplateName" />,
};

const codeErrors: Record<string, string> = {
    notUniqueTemplateName: 'notUniqueTemplateName',
};

const useStyles = makeStyles(() => ({
    tooltip: {
        maxWidth: '592px',
    },
    checkbox: {
        padding: '0 5px',
    },
    checkboxContainer: {
        padding: '5px 12px !important',
    },
}));

export type CampaignPfTemplateFormDialogProps = {
    title: string | ReactNode;
    listModel: CampaignPfTemplateListModel;
    saveModel: (model: CampaignPfTemplateModel, settingsId: string) => Promise<void>;
};

type RouteParams = {
    id: string;
    rfId: string;
    settingsId: string;
    templateId?: string;
};

export const CampaignPfTemplateFormDialog = observer(
    (props: CampaignPfTemplateFormDialogProps): JSX.Element => {
        const { saveModel, title, listModel } = props;
        const classes = useStyles();

        const [Select] = di([SelectInj], CampaignPfTemplateFormDialog);
        const [TotDropzoneArea] = di([TotDropzoneAreaInj], CampaignPfTemplateFormDialog);
        const [DigitalSignaturePfFormFields] = di([DigitalSignaturePfFormFieldsInj], CampaignPfTemplateFormDialog);

        const history = useHistory();
        const { id: campaignId, rfId, templateId, settingsId } = useParams<RouteParams>();
        const rootStore = useStore();
        const { intlStore: intl, campaignsStore } = rootStore;
        const [titleValidationError, setTitleValidationError] = useState('');

        const model = useMemo(() => {
            return new CampaignPfTemplateModel(campaignId, settingsId, listModel.regFormsCatalog, rootStore);
        }, [campaignId, settingsId, listModel.regFormsCatalog, rootStore]);

        useEffect(() => {
            model.getFileFormats();
            model.getDocCategories().then(() => {
                if (!templateId) {
                    return;
                }

                campaignsStore.loadTemplate(templateId).then((template) => {
                    model.load(template);
                });
            });
        }, [templateId, campaignsStore, model]);

        const handleClose = (): void => {
            history.push(generatePath(clientRoute.campaignRegFormTemplates, { id: campaignId, rfId }));
        };

        const handleSave = (): void => {
            model.validationStarted = true;
            if (model.isValid) {
                saveModel(model, settingsId)
                    .then(handleClose)
                    .catch((e) => {
                        const message = e.response.data;
                        if (message.includes(model.title)) {
                            model.enableErrorForm(codeErrors.notUniqueTemplateName);
                        }
                    });
            }
        };

        const renderErrorMessage = (): JSX.Element => {
            switch (model.errorFormCode) {
                case codeErrors.notUniqueTemplateName:
                    return errorFormMessages[codeErrors.notUniqueTemplateName];
                default:
                    return <FormattedMessage id="common.defaultErrorMessage" />;
            }
        };

        const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const title = event.target.value;
            const clearTitle = title.replace(bannedSymbols, '');
            model.title = clearTitle;
            if (title !== clearTitle) {
                setTitleValidationError(
                    intl.formatMessage('validation.bannedSymbols', { symbols: '/ \\ : * ? < > |' }),
                );
            } else {
                setTitleValidationError('');
            }
        };

        return (
            <Portal>
                <Dialog fullWidth={true} maxWidth="sm" open={true} scroll="body">
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent dividers>
                        {model.errorForm && (
                            <Box pb={5}>
                                <Alert variant="filled" severity="error">
                                    {renderErrorMessage()}
                                </Alert>
                            </Box>
                        )}
                        <Grid container spacing={6} direction="column">
                            <Grid item>
                                <Tooltip
                                    open={!!titleValidationError}
                                    placement="top-start"
                                    classes={{ tooltip: classes.tooltip }}
                                    title={titleValidationError}
                                >
                                    <TextField
                                        variant="outlined"
                                        required
                                        error={!!model.errorTitle}
                                        helperText={model.errorTitle}
                                        label={<FormattedMessage id="campaign.templateForm.title" />}
                                        value={model.title}
                                        onChange={onTitleChange}
                                        onBlur={() => setTitleValidationError('')}
                                        fullWidth
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label={<FormattedMessage id="campaign.templateForm.description" />}
                                    value={model.description}
                                    onChange={(e): string => (model.description = e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <Select
                                    multiple={false}
                                    values={model.docCategory}
                                    label={<FormattedMessage id="campaign.documentCategory" />}
                                    selectData={model.docCategories}
                                    onChange={model.onChangeDocumentCategory}
                                    textFieldProps={{
                                        variant: 'outlined',
                                        required: false,
                                    }}
                                    autoCompleteProps={{
                                        fullWidth: true,
                                        size: 'medium',
                                        disableClearable: true,
                                        freeSolo: false,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    popupIcon={<ExpandMoreIcon />}
                                    fullWidth={true}
                                    size="medium"
                                    disableClearable={true}
                                    freeSolo={false}
                                    options={model.fileFormats.map((item) => item.title)}
                                    onChange={model.onChangeFileFormat}
                                    getOptionLabel={(option) => option}
                                    noOptionsText={<FormattedMessage id="campaignRequest.resultsOption" />}
                                    value={model.resultFileFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={true}
                                            label={<FormattedMessage id="campaign.fileFormat" />}
                                            error={!!model.errorFileFormat}
                                            helperText={model.errorFileFormat}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item className={classes.checkboxContainer}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            className={classes.checkbox}
                                            checked={model.required}
                                            onChange={model.onChangeRequired}
                                        />
                                    }
                                    label={<FormattedMessage id="campaign.templateForm.templateIsRequired" />}
                                />
                            </Grid>
                            <DigitalSignaturePfFormFields
                                validateMethodError={model.errorSignatureMethod}
                                signatureSettings={model.signatureSettings}
                                onChangeSignatureSettings={model.onChangeSignatureSettings}
                            />
                            <Grid item>
                                {/* Возможно стоит вынести в отдельный компонент,
                                    при повторном использовании Dropzone нужно попробовать */}
                                <FormControl fullWidth required error={!!model.errorFile} variant="outlined">
                                    <InputLabel shrink={true}>
                                        <FormattedMessage id="campaign.templateForm.file" />
                                    </InputLabel>
                                    <TotDropzoneArea
                                        showAlerts={false}
                                        useChipsForPreview={true}
                                        showPreviewsInDropzone={false}
                                        filesLimit={1}
                                        acceptedFiles={model.acceptedFiles}
                                        dropzoneText={intl.formatMessage('common.dropzoneText')}
                                        onDrop={model.onChangeFile}
                                        onChange={model.onChangeFile}
                                        onDropRejected={model.onRejectedFile}
                                        alertSnackbarProps={{
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                        }}
                                    />
                                    {model.templateFileName && (
                                        <Box pt={1}>
                                            <Chip label={model.templateFileName} onDelete={model.onDeleteFile} />
                                        </Box>
                                    )}
                                    <FormHelperText>{model.errorFile}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSave} color="primary" variant="contained">
                            <FormattedMessage id="common.save" />
                        </Button>
                        <Button onClick={handleClose} variant="text">
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Portal>
        );
    },
);
