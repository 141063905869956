import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { Grid } from '@material-ui/core';
import { Transition, TransitionsDTO } from '../../models';
import { ErrorDialog } from '../ErrorDialog';
import { ErrorMessage } from '../ErrorMessage';
import { TransitionDialog as TransitionDialogInj } from '../transition-dialogs/TransitionDialog';
import { useError, useModal, useStore } from '../../hooks';
import { renderTransitions } from './Transitions';
import { di } from 'react-magnetic-di';

export type TotObjectTransitionsProps = {
    objectId: string;
    updateObjectPage: () => void;
    getTransitions: (objectId: string) => Promise<TransitionsDTO>;
    lifeCycleTransition: (transitionId: string, objectId: string, validate?: boolean) => Promise<void>;
    validateFunction?: () => Promise<void>;
    errorMessage?: JSX.Element | string;
};

export const TotObjectTransitions = observer(
    (props: TotObjectTransitionsProps): JSX.Element => {
        const {
            lifeCycleTransition,
            objectId,
            updateObjectPage,
            getTransitions,
            validateFunction = () => Promise.resolve(),
            errorMessage,
        } = props;

        const [TransitionDialog] = di([TransitionDialogInj], TotObjectTransitions);

        const [transition, setTransition] = useState<Transition>();
        const [data, setData] = useState<TransitionsDTO>([]);

        const { intlStore } = useStore();

        const [isModalOpen, setModalIsOpen, setModalIsClosed] = useModal();
        const [isError, errorText, enableError, resetError] = useError();

        const updateTransitions = (): void => {
            getTransitions(objectId).then((data) => {
                setData(data);
            });
        };

        useEffect(() => {
            updateTransitions();
        }, [intlStore.locale]);

        const lifeCycleTransitionCallback = (): Promise<void> => {
            return lifeCycleTransition(transition?.id || '', objectId, transition?.params.validate || false);
        };

        const handleSuccessTransition = (): void => {
            updateObjectPage();
        };

        const handleErrorTransition = (error: AxiosError): void => {
            const errorText = ErrorMessage(error);
            enableError(errorText);
        };

        const onClickModalOpen = (transition: Transition): (() => void) => {
            return (): void => {
                setTransition(transition);
                setModalIsOpen();
            };
        };

        const closeModal = (): void => {
            setModalIsClosed();
            setTransition(undefined);
        };

        return (
            <React.Fragment>
                {transition && (
                    <TransitionDialog
                        objectId={objectId}
                        transition={transition}
                        isModalOpen={isModalOpen}
                        setModalIsClosed={closeModal}
                        lifeCycleTransition={lifeCycleTransitionCallback}
                        handleSuccessTransition={handleSuccessTransition}
                        handleErrorTransition={handleErrorTransition}
                        validateFunction={validateFunction}
                        errorMessage={errorMessage}
                    />
                )}
                <ErrorDialog message={errorText} open={isError} onClose={resetError} />
                <Grid container direction="row" spacing={4}>
                    {renderTransitions(data, onClickModalOpen)}
                </Grid>
            </React.Fragment>
        );
    },
);
