import React, { ReactNode, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage } from 'react-intl';
import { Portal } from '@material-ui/core';
import { StyledCircularProgress } from '../pages';

export type ConfirmationDialogProps = {
    id: string;
    keepMounted: boolean;
    open: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    title: ReactNode;
    message?: ReactNode;
    confirmText?: string;
};

export const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
    const { confirmText, onConfirm, onCancel, open, title, message, ...other } = props;
    const [isSending, setIsSending] = useState<boolean>(false);
    const startIcon = isSending && <StyledCircularProgress />;

    const handleCancel = (): void => {
        onCancel();
    };

    const handleOk = (): void => {
        setIsSending(true);
        onConfirm().finally(stopIsSending);
    };

    const stopIsSending = (): void => {
        setIsSending(false);
    };

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open} {...other}>
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        onClick={handleOk}
                        color="secondary"
                        variant="contained"
                        disabled={isSending}
                        startIcon={startIcon}
                    >
                        {confirmText || <FormattedMessage id={'common.confirm'} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
};
