import React from 'react';
import { generatePath, NavLink, useLocation } from 'react-router-dom';
import { AlertLink as AlertLinkInj } from '../components';
import { di } from 'react-magnetic-di';
import { App } from '../App';

export const validationErrorLink = (id: string) => (...chunks: string[]): JSX.Element => {
    const [AlertLink] = di([AlertLinkInj], App);
    const location = useLocation();

    return (
        <AlertLink
            component={NavLink}
            to={generatePath(`${location.pathname}/edit`, { id })}
            style={{ display: 'inline' }}
        >
            {chunks}
        </AlertLink>
    );
};
