import { action, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { apiConfigs } from '../apiConfigs';
import { di } from 'react-magnetic-di';
import { SignatureInfoDTO, HashSumDTO, SignatureStatus } from '@platform/crypto-ui';

export class FormioSignatureStore {
    @observable private rootStore: RootStore;
    @observable private api: Api;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    getHashSum(url: string): (oidAlgorithm: string) => Promise<HashSumDTO> {
        return (oidAlgorithm: string): Promise<HashSumDTO> => {
            return this.api.client(apiConfigs.getFormioHashSum(url, oidAlgorithm)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignature(url: string): (signature: string) => Promise<SignatureStatus> {
        return (signature: string): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadFormioSignature(url, signature)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignatureFile(url: string): (signatureFile: FormData) => Promise<SignatureStatus> {
        return (signatureFile: FormData): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadFormioSignatureFile(url, signatureFile)).then((r) => r.data);
        };
    }

    @action.bound
    getSignatureInfo(url: string): () => Promise<SignatureInfoDTO[]> {
        return (): Promise<SignatureInfoDTO[]> => {
            return this.api.client(apiConfigs.getFormioSignatureInfo(url)).then((r) => r.data);
        };
    }

    @action.bound
    downloadSignature(url: string): (isWithFile: boolean) => Promise<Blob> {
        return (isWithFile: boolean): Promise<Blob> => {
            return this.api.client(apiConfigs.downloadFormioSignature(url, isWithFile)).then((r) => r.data);
        };
    }

    @action.bound
    deleteSignature(url: string): () => Promise<SignatureStatus> {
        return (): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.deleteFormioSignature(url)).then((r) => r.data);
        };
    }
}

export const getFormioSignatureStore = (): any => {
    const [_FormioSignatureStore] = di([FormioSignatureStore], getFormioSignatureStore);
    return _FormioSignatureStore;
};
