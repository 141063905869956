import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { CampaignForm as CampaignFormInj } from './description/CampaignForm';
import { CampaignSettingTabs as CampaignSettingTabsInj } from './CampaignSettingTabs';
import { CampaignRegFormsList as CampaignRegFormsListInj } from './reg-form';
import { CampaignRegForm as CampaignRegFormInj } from './reg-form/CampaignRegForm';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    BreadcrumbsComponent as BreadcrumbsComponentInj,
} from '../../../components';
import { CampaignManagement as CampaignManagementInj } from './management/CampaignManagement';
import { CampaignGeneralSettings as CampaignGeneralSettingsInj } from './general-settings/CampaignGeneralSettings';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { getAdditionalCampaignRegFormTabsRoutes } from '../../../utils/AdditionalLinks';
import { useStore } from '../../../hooks';

type RouteProps = {
    id: string;
};

export const AdditionalCampaignSettingsPagesInj = (): JSX.Element => <React.Fragment />;

export const CampaignSettingsPage = (): JSX.Element => {
    const [CampaignForm] = di([CampaignFormInj], CampaignSettingsPage);
    const [CampaignSettingTabs] = di([CampaignSettingTabsInj], CampaignSettingsPage);
    const [CampaignRegFormsList] = di([CampaignRegFormsListInj], CampaignSettingsPage);
    const [CampaignRegForm] = di([CampaignRegFormInj], CampaignSettingsPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignSettingsPage);
    const [BreadcrumbsComponent] = di([BreadcrumbsComponentInj], CampaignSettingsPage);
    const [CampaignManagement] = di([CampaignManagementInj], CampaignSettingsPage);
    const [CampaignGeneralSettings] = di([CampaignGeneralSettingsInj], CampaignSettingsPage);
    const [AdditionalCampaignSettingsPages] = di([AdditionalCampaignSettingsPagesInj], CampaignSettingsPage);

    const { id } = useParams<RouteProps>();

    const { breadcrumbsStore } = useStore();
    const { breadCrumbsSettings } = breadcrumbsStore;

    return (
        <AuthorizationCheck entityCode={entities.Campaign} entityId={id} permCode={permissions.Campaign.Edit}>
            {(allowed: boolean): JSX.Element => {
                if (!allowed) {
                    return <Redirect to={clientRoute.notAllowed} />;
                }
                return (
                    <Container maxWidth="lg">
                        <Box pt={5.5} pb={20}>
                            <Box pl={3} pb={5}>
                                <BreadcrumbsComponent objectId={id} breadcrumbsSettings={breadCrumbsSettings} />
                            </Box>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    <CampaignSettingTabs />
                                </Grid>
                                <Grid item xs={9}>
                                    <Container maxWidth="md">
                                        <Switch>
                                            <Route
                                                path={[clientRoute.campaignSettings, clientRoute.campaignSettingsEdit]}
                                                exact
                                            >
                                                <CampaignForm />
                                            </Route>
                                            <Route path={clientRoute.campaignRegForms} exact>
                                                <CampaignRegFormsList />
                                            </Route>
                                            <Route path={[clientRoute.campaignManagement]} exact>
                                                <CampaignManagement />
                                            </Route>
                                            <Route path={clientRoute.generalSettings} exact>
                                                <CampaignGeneralSettings />
                                            </Route>
                                            <Route
                                                path={[
                                                    clientRoute.campaignRegForm,
                                                    clientRoute.campaignRegFormExecutors,
                                                    clientRoute.campaignRegFormTabsSettings,
                                                    clientRoute.campaignRequestFormTabSetting,
                                                    clientRoute.campaignRequestFormTabSettingCreate,
                                                    clientRoute.campaignRegFormTemplates,
                                                    clientRoute.campaignRegFormTemplatesCreate,
                                                    clientRoute.campaignRegFormTemplatesEdit,
                                                    clientRoute.campaignRegFormTemplatesPermissionSettings,
                                                    clientRoute.campaignRegFormCreate,
                                                    clientRoute.campaignRegFormEdit,
                                                    ...getAdditionalCampaignRegFormTabsRoutes(),
                                                ]}
                                            >
                                                <CampaignRegForm />
                                            </Route>
                                            <AdditionalCampaignSettingsPages />
                                        </Switch>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                );
            }}
        </AuthorizationCheck>
    );
};
