import React from 'react';
import { Container, Grid, Toolbar } from '@material-ui/core';
import { StickyWrapper as StickyWrapperInj } from '../../../../components/sticky/StickyWrapper';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { generatePath, Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { CampaignViewModeButtons as CampaignViewModeButtonsInj } from './buttons/CampaignViewModeButtons';
import { useStore } from '../../../../hooks/useStore';
import { CampaignEditModeButtonsWithDialog as CampaignEditModeButtonsWithDialogInj } from './buttons/CampaignEditModeButtonsWithDialog';
import { RequestModel } from '../../../../models/RequestModel';
import { observer } from 'mobx-react';
import { TotObjectTransitions as TotObjectTransitionsInj } from '../../../../components/tot-object/TotObjectTransitions';
import { di } from 'react-magnetic-di';

// Стили для контейнера всей панели
export const RequestActionsBar = withTheme(
    styled(Toolbar)`
        background-color: ${({ theme }): string => theme.palette.primary.contrastText};
        color: ${({ theme }): string => theme.variables.palette.textDark};
        border-top: ${({ theme }): string => `1px solid ${theme.variables.palette.hoverInLists}`};
    `,
);

export type RequestControlPanelProps = {
    model: RequestModel;
    reloadModel: () => void;
    formIsChanged: boolean;
    errorMessage: JSX.Element;
};

export const RequestControlPanel = observer(
    (props: RequestControlPanelProps): JSX.Element => {
        const { model, reloadModel, formIsChanged, errorMessage } = props;
        const {
            id,
            number,
            deleteRequest,
            transitionLifeCycle,
            goToRequestEditPageAndValidate,
            saveRequestForm,
            validateReadonlyPage,
        } = model;

        const [StickyWrapper] = di([StickyWrapperInj], RequestControlPanel);
        const [CampaignViewModeButtons] = di([CampaignViewModeButtonsInj], RequestControlPanel);
        const [CampaignEditModeButtonsWithDialog] = di([CampaignEditModeButtonsWithDialogInj], RequestControlPanel);
        const [TotObjectTransitions] = di([TotObjectTransitionsInj], RequestControlPanel);

        const { requestStore } = useStore();

        return (
            <StickyWrapper mode="bottom">
                <RequestActionsBar>
                    <Container maxWidth="lg">
                        <Grid alignItems="center" justify="space-between" container>
                            <Grid item>
                                <Switch>
                                    <Route key={clientRoute.request} path={clientRoute.request} exact>
                                        <CampaignViewModeButtons
                                            deleteRequest={deleteRequest}
                                            requestNumber={number}
                                            id={id}
                                        />
                                    </Route>
                                    {model.formsTabs
                                        .map((f) => {
                                            const formId = f.id;

                                            return [
                                                <Route
                                                    key={f.id}
                                                    path={generatePath(clientRoute.requestForm, { id, formId })}
                                                    exact
                                                >
                                                    <CampaignViewModeButtons
                                                        onEdit={goToRequestEditPageAndValidate(
                                                            generatePath(clientRoute.requestFormEdit, { id, formId }),
                                                        )}
                                                        deleteRequest={deleteRequest}
                                                        requestNumber={number}
                                                        id={id}
                                                        formId={formId}
                                                    />
                                                </Route>,
                                                <Route
                                                    key={`${formId}edit`}
                                                    path={generatePath(clientRoute.requestFormEdit, { id, formId })}
                                                    exact
                                                >
                                                    <CampaignEditModeButtonsWithDialog
                                                        onSubmit={saveRequestForm(formId)}
                                                        path={generatePath(clientRoute.requestForm, { id, formId })}
                                                        formIsChanged={formIsChanged}
                                                    />
                                                </Route>,
                                            ];
                                        })
                                        .reduce((a, b) => a.concat(b), [])}
                                </Switch>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={3}>
                                    <Switch>
                                        <Route path={[clientRoute.request, clientRoute.requestForm]} exact>
                                            <Grid item>
                                                <TotObjectTransitions
                                                    objectId={id}
                                                    getTransitions={requestStore.getTransitionRequest}
                                                    updateObjectPage={reloadModel}
                                                    lifeCycleTransition={transitionLifeCycle}
                                                    validateFunction={validateReadonlyPage}
                                                    errorMessage={errorMessage}
                                                />
                                            </Grid>
                                        </Route>
                                    </Switch>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </RequestActionsBar>
            </StickyWrapper>
        );
    },
);
