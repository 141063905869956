import { action, computed, observable } from 'mobx';
import { Api } from './Api';
import { RequestModel } from '../models/RequestModel';
import { FullSubmission } from '@platform/formiojs-react';
import { apiConfigs } from '../apiConfigs';
import downloadFile from 'js-file-download';
import fileDownload from 'js-file-download';
import { TableQueryData } from '../models/list/TableModel';
import { History } from 'history';
import { handleAxiosErrorByResponseStatus } from '../utils/errorHandleUtils';
import { clientRoute } from '../clientRoute';
import { CampaignRequestPfDTO } from '../models/campaign-request-pf/CampaignRequestPfModel';
import { FileDTO } from '../models/FileModel';
import { RequestFormModel } from '../models/RequestFormModel';
import { AxiosPromise } from 'axios';
import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import { RequestRow } from '../pages/campaign-request/campaign-request-list-page/CampaignRequestListPageNew';
import { DeadlinesDTO, DeadlineStatus, EmployeeDTO, RequestDTO, RequestFormDTO } from '../types/stores/requestStore';
import { TransitionsDTO } from '../models/tot-object/TotObjectTransitions';
import { RootStore } from './RootStore';
import { CampaignRequestRow } from '../models';
import { di } from 'react-magnetic-di';

export interface CampaignRequestRowNewDTO {
    id: string;
    number: string;
    author: {
        id: string;
        title: string;
    };
    campaign: {
        id: string;
        title: string;
    };
    regForm: {
        id: string;
        title: string;
    };
    state: {
        code: string;
        title: string;
        processId: string;
        processTitle: string;
    };
    created: string; //date
    sent: string; //date
    executors: EmployeeDTO[];
}

export class RequestStore {
    @observable protected rootStore: RootStore;
    @observable protected api: Api;
    @observable showValidation: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getRequestModel(requestId: string): RequestModel {
        const model = new RequestModel(requestId, this.rootStore);
        this.getRequestDTO(requestId).then(model.load);
        return observable(model);
    }

    @action.bound
    getRequestDTO(requestId: string): Promise<RequestDTO> {
        return this.api
            .client(apiConfigs.getRequest(requestId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    getDeadlinesDTO(requestId: string): Promise<DeadlinesDTO> {
        return this.api.client(apiConfigs.getDeadlines(requestId)).then((r) => r.data);
    }

    @action.bound
    createRequest(regFormId: string): Promise<string> {
        return this.api.client(apiConfigs.createRequest(regFormId)).then((r) => r.data);
    }

    @action.bound
    loadRequestForm(formId: string): Promise<RequestFormDTO> {
        return this.api.client(apiConfigs.loadRequestForm(formId)).then((r) => r.data);
    }

    @action.bound
    requestFormCheckEditable(requestFormId: string): Promise<void> {
        return this.api.client(apiConfigs.requestFormCheckEditable(requestFormId)).then((r) => r.data);
    }

    @action.bound
    getRequestFormModel(formId: string): any {
        const model = new RequestFormModel(formId);
        return this.loadRequestForm(formId)
            .then(model.load)
            .then(() => {
                return observable(model);
            });
    }

    @action.bound
    saveRequestForm(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveRequestForm(id, submission)).then((r) => r.data);
    }

    @action.bound
    deleteRequest(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteRequest(id)).then((r) => r.data);
    }

    @action.bound
    exportListXls(queryData: TableQueryData): void {
        const filename = this.rootStore.intlStore.formatMessage('campaignRequest.registryTitle');
        this.api
            .client(apiConfigs.requestListXls(queryData))
            .then((r) => r.data)
            .then((f) =>
                downloadFile(
                    f,
                    `${filename}.xlsx`,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ),
            );
    }

    @action.bound
    getTransitionRequest(requestId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.transitionRequest(requestId)).then((r) => r.data);
    }

    @action.bound
    transitionToNextLifeCycleStep(transitionId: string, requestId: string): Promise<void> {
        return this.api.client(apiConfigs.transitionToNextLifeCycleStep(transitionId, requestId)).then((r) => r.data);
    }

    @action.bound
    loadPrintForms(formId: string): Promise<CampaignRequestPfDTO[]> {
        return this.api.client(apiConfigs.requestPrintForms(formId)).then((r) => r.data);
    }

    @action.bound
    getPrintForm(templateId: string, formId: string): Promise<CampaignRequestPfDTO> {
        return this.api.client(apiConfigs.getPrintForm(templateId, formId)).then((r) => r.data);
    }

    @action.bound
    generatePrintForm(formId: string, templateId: string, submission?: FullSubmission): Promise<CampaignRequestPfDTO> {
        return this.api.client(apiConfigs.generatePrintForm(formId, templateId, submission)).then((r) => r.data);
    }

    @action.bound
    downloadPrintFormFile(fileDTO: FileDTO): void {
        this.api
            .client(apiConfigs.downloadPrintFormFile(fileDTO.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    @action.bound
    downloadAllPrintFormFiles(formId: string): AxiosPromise<Blob> {
        return this.api.client(apiConfigs.downloadAllPrintFormFiles(formId));
    }

    @action.bound
    getEmployeesList(): Promise<EmployeeDTO[]> {
        return this.api.client(apiConfigs.getAllActiveEmployees()).then((r) => r.data);
    }

    @action.bound
    setCurrentUserAsExecutor(requestId: string): Promise<void> {
        return this.api.client(apiConfigs.setCurrentUserAsExecutor(requestId)).then((r) => r.data);
    }

    @action.bound
    getExecutorSettings(requestId: string): Promise<EmployeeDTO> {
        return this.api.client(apiConfigs.getExecutorSettings(requestId)).then((r) => r.data);
    }

    @action.bound
    editExecutorSettings(requestId: string, userId: string): Promise<void> {
        return this.api.client(apiConfigs.editExecutorSettings(requestId, userId)).then((r) => r.data);
    }

    @action.bound
    registry(
        registryCode: string,
        query: TTableQueryData<RequestRow & CampaignRequestRowNewDTO>,
    ): Promise<RowsData<RequestRow & CampaignRequestRowNewDTO>> {
        return this.api.client(apiConfigs.registry(registryCode, query)).then((r) => r.data);
    }

    @action.bound
    registryColumns(registryCode: string): Promise<ColumnData<RequestRow>[]> {
        return this.api.client(apiConfigs.registryColumns(registryCode)).then((r) => r.data);
    }

    @action.bound
    mapDtoToRow(dto: CampaignRequestRowNewDTO): CampaignRequestRow {
        const row = {
            id: dto.id,
            number: dto.number,
            authorName: dto.author.title,
            authorUserId: dto.author.id,
            campaignId: dto.campaign.id,
            campaignTitle: dto.campaign.title,
            regFormTitle: dto.regForm.title,
            created: dto.created,
            sent: dto.sent,
            state: dto.state.code,
            executors: dto.executors,
        };
        return row;
    }

    @action.bound
    async upload<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.upload(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.requestsNew');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    checkEditablePf(customFormId: string): Promise<void> {
        return this.api.client(apiConfigs.checkEditablePf(customFormId)).then((r) => r.data);
    }
}

export const getRequestStore = (): any => {
    const [_RequestStore] = di([RequestStore], getRequestStore);
    return _RequestStore;
};
