import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Form, FormApi, FormModel } from '@platform/formiojs-react';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../../../components';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { Delete, Edit } from '@material-ui/icons';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { useFormStyles } from '../../../../../hooks';
import { CampaignRegFormRouteParams } from '../types';
import { di } from 'react-magnetic-di';

export type CampaignRegFormCardProps = {
    model: FormModel;
    onFormReady: (form: FormApi) => void;
    onDeleteLinkClick: () => void;
};

export const CampaignRegFormCard = (props: CampaignRegFormCardProps): JSX.Element => {
    const { model, onFormReady, onDeleteLinkClick } = props;

    const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignRegFormCard);

    const { id, rfId } = useParams<CampaignRegFormRouteParams>();
    const classes = useFormStyles();

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item container justify="flex-end" spacing={5}>
                <AuthorizationCheck
                    entityCode={entities.CampaignRegForm}
                    entityId={rfId}
                    permCode={permissions.CampaignRegForm.Edit}
                >
                    <Grid item>
                        <Button
                            variant="text"
                            color="primary"
                            startIcon={<Edit />}
                            component={NavLink}
                            to={generatePath(clientRoute.campaignRegFormEdit, { id, rfId })}
                        >
                            <FormattedMessage id="common.edit" />
                        </Button>
                    </Grid>
                </AuthorizationCheck>
                <AuthorizationCheck
                    entityCode={entities.CampaignRegForm}
                    entityId={rfId}
                    permCode={permissions.CampaignRegForm.Delete}
                >
                    <Grid item>
                        <Button
                            aria-haspopup="true"
                            aria-controls="confirm-delete-rf"
                            variant="text"
                            color="primary"
                            startIcon={<Delete />}
                            onClick={onDeleteLinkClick}
                        >
                            <FormattedMessage id="common.delete" />
                        </Button>
                    </Grid>
                </AuthorizationCheck>
            </Grid>
            <Grid item>
                <Form className={classes.formWithNoOffset} form={model} readOnly onFormReady={onFormReady} />
            </Grid>
        </Grid>
    );
};
