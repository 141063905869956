import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { StartPageHeaderLink as StartPageHeaderLinkInj } from './StartPageHeaderLink';
import { StartPageLoginLink as StartPageLoginLinkInj } from './StartPageLoginLink';
import { useStore } from '../../hooks/useStore';
import { di } from 'react-magnetic-di';

export type StartAuthHeaderLinkProps = {
    anchorRef: React.RefObject<HTMLDivElement>;
    handleToggle: () => void;
};

export const StartAuthHeaderLink = observer(
    (props: StartAuthHeaderLinkProps): JSX.Element => {
        const [StartPageHeaderLink] = di([StartPageHeaderLinkInj], StartAuthHeaderLink);
        const [StartPageLoginLink] = di([StartPageLoginLinkInj], StartAuthHeaderLink);

        const { anchorRef, handleToggle } = props;
        const { api, personStore } = useStore();

        useEffect(() => {
            personStore.getInfo().then(api.authVerify);
        }, [personStore, api]);

        switch (api.authStatus) {
            case 'ok':
                return (
                    <StartPageHeaderLink
                        component={RouterLink}
                        to={clientRoute.campaigns}
                        color="secondary"
                        underline="none"
                    >
                        {personStore.person.lastNameWithInitials}
                    </StartPageHeaderLink>
                );
            default:
                return <StartPageLoginLink handleToggle={handleToggle} anchorRef={anchorRef} />;
        }
    },
);
