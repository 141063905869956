import { action, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { CodeTitle } from '../models/CodeTitle';
import { IntlStore } from './IntlStore';
import { AxiosRequestConfig } from 'axios';
import { IdTitle } from '../models/IdTitle';
import { apiConfigs } from '../apiConfigs';
import { SelectData } from '@platform/ttable';
import { di } from 'react-magnetic-di';

export const CatalogCodes = {
    documentCategory: 'DocumentCategory',
    fileFormat: 'FileFormat',
};

type CatalogDTO = {
    code: string;
    catalogCode: string;
    title: string;
};

export class CatalogStore {
    @observable rootStore: RootStore;
    @observable api: Api;
    @observable intlStore: IntlStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intlStore = rootStore.intlStore;
    }

    @action.bound
    getSelectData(requestConfig: AxiosRequestConfig): Promise<IdTitle[]> {
        return this.api
            .client(requestConfig)
            .then((r) => r.data)
            .then((data: CodeTitle[]) => {
                return data.map(({ code, title }) => ({
                    id: code,
                    title,
                }));
            });
    }

    @action.bound
    getCatalog(catalogCode: string, lang: string): Promise<CodeTitle[]> {
        return this.api
            .client(apiConfigs.catalogItems(catalogCode, lang))
            .then((r) => r.data)
            .then((arr) =>
                arr.map((item: CatalogDTO) => ({ code: `${item.code}@${item.catalogCode}`, title: item.title })),
            );
    }

    @action.bound
    getSelectDataByUrl(url: string): Promise<SelectData> {
        return this.api
            .client({
                url: url,
                method: 'GET',
            })
            .then((r) => r.data);
    }
}

export const getCatalogStore = (): any => {
    const [_CatalogStore] = di([CatalogStore], getCatalogStore);
    return _CatalogStore;
};
