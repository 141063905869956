import React, { ChangeEvent } from 'react';
import { Box, FormHelperText, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SignatureSettings } from '@platform/crypto-ui';
import { observer } from 'mobx-react';
import { DigitalSignaturePfFormFieldsCheckbox as DigitalSignaturePfFormFieldsCheckboxInj } from './DigitalSignaturePfFormFieldsCheckbox';
import { di } from 'react-magnetic-di';

export type DigitalSignaturePfFormFieldsProps = {
    signatureSettings: SignatureSettings;
    onChangeSignatureSettings: (e: ChangeEvent<HTMLInputElement>, fieldKey: keyof SignatureSettings) => void;
    validateMethodError?: string;
};

export const DigitalSignaturePfFormFields = observer(
    (props: DigitalSignaturePfFormFieldsProps): JSX.Element => {
        const [DigitalSignaturePfFormFieldsCheckbox] = di(
            [DigitalSignaturePfFormFieldsCheckboxInj],
            DigitalSignaturePfFormFields,
        );

        const { signatureSettings, onChangeSignatureSettings, validateMethodError } = props;

        return (
            <Grid container direction="column">
                <Box mx="12px">
                    <Grid item>
                        <DigitalSignaturePfFormFieldsCheckbox
                            signatureSettings={signatureSettings}
                            onChange={onChangeSignatureSettings}
                            fieldName={'signatureEnabled'}
                        />
                    </Grid>

                    {signatureSettings.signatureEnabled && (
                        <React.Fragment>
                            <Grid item>
                                <DigitalSignaturePfFormFieldsCheckbox
                                    signatureSettings={signatureSettings}
                                    onChange={onChangeSignatureSettings}
                                    fieldName={'signatureRequired'}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    <Box fontWeight="600" margin="6px" component="span">
                                        <FormattedMessage id="digitalSignature.form.signatureVariants" />
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <DigitalSignaturePfFormFieldsCheckbox
                                    signatureSettings={signatureSettings}
                                    onChange={onChangeSignatureSettings}
                                    fieldName={'signatureBrowserPlugin'}
                                />
                            </Grid>
                            <Grid item>
                                <DigitalSignaturePfFormFieldsCheckbox
                                    signatureSettings={signatureSettings}
                                    onChange={onChangeSignatureSettings}
                                    fieldName={'signatureUpload'}
                                />
                            </Grid>
                            {validateMethodError && (
                                <Grid item>
                                    <Box mx="14px" mb="10px">
                                        <FormHelperText error={true}>{validateMethodError}</FormHelperText>
                                    </Box>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Box>
            </Grid>
        );
    },
);
