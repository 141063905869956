import { action, computed, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Api } from './Api';
import { asyncAction } from 'mobx-utils';
import { apiConfigs } from '../apiConfigs';
import { UserModel } from '../models/person/UserModel';
import { PersonModel, PersonDTO } from '../models/person/PersonModel';
import { AxiosResponse } from 'axios';
import { clientRoute } from '../clientRoute';
import { di } from 'react-magnetic-di';

interface PersonInfo {
    user: UserModel;
    person: PersonDTO;
    roles: string[];
}

export type RolePriority = {
    [role: string]: number;
};

export type RedirectByRole = {
    [role: string]: string;
};

export const loginRedirectByRole: RedirectByRole = {
    User: clientRoute.requests,
    Employee: clientRoute.requestsNew,
    SeniorEmployee: clientRoute.requestsNew,
    Moderator: clientRoute.requestsNew,
};

export const rolePriorities: RolePriority = {
    User: 0,
    Employee: 1,
    SeniorEmployee: 2,
    Moderator: 3,
};

export const getRouteByRoles = (
    roles: string[],
    rolePriorities: RolePriority,
    loginRedirectByRole: RedirectByRole,
): string => {
    const rolesLength = roles.length;

    let redirect = '';
    let priority = 0;
    for (let index = 0; index < rolesLength; index++) {
        const role = roles[index];
        const rolePriority = rolePriorities[role];
        if ((rolePriority || rolePriority === 0) && rolePriority >= priority) {
            priority = rolePriority;
            redirect = loginRedirectByRole[role];
        }
    }

    if (redirect) {
        return redirect;
    }

    return clientRoute.campaigns;
};

export class PersonStore {
    @observable rootStore: RootStore;
    @observable api: Api;

    @observable user: UserModel = new UserModel();
    @observable person: PersonModel = new PersonModel();
    @observable roles: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @asyncAction
    @action.bound
    async getInfo(): Promise<void> {
        const response: AxiosResponse<PersonInfo> = await this.api.client(apiConfigs.person);
        const info: PersonInfo = response.data;
        this.user = info.user;
        this.person.load(info.person);
        this.roles = info.roles;
    }

    @action.bound
    clearPerson() {
        this.user = new UserModel();
        this.person = new PersonModel();
        this.roles = [];
    }

    get rolePriorities(): RolePriority {
        return rolePriorities;
    }

    get loginRedirectByRole(): RedirectByRole {
        return loginRedirectByRole;
    }

    @computed
    get redirectPath(): string {
        return getRouteByRoles(this.roles, this.rolePriorities, this.loginRedirectByRole);
    }
}

export const getPersonStore = (): any => {
    const [_PersonStore] = di([PersonStore], getPersonStore);
    return _PersonStore;
};
