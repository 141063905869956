import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal, useStore } from '../../../../../../hooks';
import { CampaignPfTemplateListModel } from '../../../../../../models';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { CampaingPfTemplateTabModel } from '../../../../../../models/campaign-settings-docs-templates/CampaingPfTemplateTabModel';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { RequestFormPermissionSettingsTable as RequestFormPermissionSettingsTableInj } from '../RequestFormPermissionSettingsTable';
import { EditPermissionDialog as EditPermissionDialogInj } from './EditPermissionDialog';
import { di } from 'react-magnetic-di';

type RouteParams = {
    id: string;
    rfId: string;
    settingsId: string;
};

export const TemplatePermissionSettingsDialog = observer(
    (): JSX.Element => {
        const history = useHistory();
        const { intlStore: intl, campaignsStore } = useStore();

        const { id: campaignId, rfId, settingsId } = useParams<RouteParams>();

        const [EditPermissionDialog] = di([EditPermissionDialogInj], TemplatePermissionSettingsDialog);
        const [RequestFormPermissionSettingsTable] = di(
            [RequestFormPermissionSettingsTableInj],
            TemplatePermissionSettingsDialog,
        );

        const templateTab = useMemo(() => new CampaingPfTemplateTabModel(intl), [intl]);

        const [currentPermission, setCurrentPermission] = useState<number>();
        const [editPermissionDialogOpen, setEditPermissionDialogOpen, setEditPermissionDialogClosed] = useModal();

        const handleEditPermissionDialogOpen = useCallback(
            (index: number): void => {
                setCurrentPermission(index);
                setEditPermissionDialogOpen();
            },
            [setCurrentPermission, setEditPermissionDialogOpen],
        );

        const handleClose = (): void => {
            history.push(generatePath(clientRoute.campaignRegFormTemplates, { id: campaignId, rfId }));
        };

        const handleSave = (): void => {
            templateTab.validationStarted = true;

            if (!templateTab.isValid) {
                return;
            }

            campaignsStore
                .saveTemplatePermissions(settingsId, templateTab.permissions)
                .then(handleClose)
                .finally(() => (templateTab.validationStarted = false));
        };

        const checkboxLabel = <FormattedMessage id="campaign.generate" />;

        useEffect(() => {
            campaignsStore.loadTemplatePermissions(settingsId).then((permissions) => {
                templateTab.permissions = permissions;
            });
            campaignsStore.loadStates(rfId).then((states) => {
                templateTab.statesList = states;
            });
            campaignsStore.loadPermissions().then((permissions) => {
                templateTab.permissionsList = permissions;
            });
        }, [campaignsStore, templateTab]);

        return (
            <React.Fragment>
                {currentPermission !== undefined && editPermissionDialogOpen && (
                    <EditPermissionDialog
                        open={editPermissionDialogOpen}
                        closeDialog={setEditPermissionDialogClosed}
                        currentPermission={currentPermission}
                        model={templateTab}
                        checkboxLabel={checkboxLabel}
                    />
                )}
                <Dialog maxWidth="md" fullWidth open={true} scroll="body">
                    <DialogTitle>
                        <FormattedMessage id="campaign.accessSetting" />
                    </DialogTitle>
                    <DialogContent dividers>
                        <RequestFormPermissionSettingsTable
                            model={templateTab}
                            handleEditPermissionDialogOpen={handleEditPermissionDialogOpen}
                            checkboxLabel={checkboxLabel}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSave} color="primary" variant="contained">
                            <FormattedMessage id="common.save" />
                        </Button>
                        <Button onClick={handleClose} variant="text">
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    },
);
