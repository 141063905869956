import React, { useState } from 'react';
import { Box, CircularProgress, Grid, useTheme } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ReactComponent as FileIcon } from '../resources/images/icons/file.svg';
import { FileModel } from '../models/FileModel';
import { IconLink as IconLinkInj } from './links/IconLink';
import { di } from 'react-magnetic-di';

export type FileComponentProps = {
    file: FileModel;
    onDownloadFile: (file: FileModel) => Promise<void>;
};

export const FileComponent = (props: FileComponentProps): JSX.Element => {
    const { file, onDownloadFile } = props;
    const [IconLink] = di([IconLinkInj], FileComponent);
    const theme = useTheme();
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [isCompletedLoading, setIsCompletedLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const onDownloadFileClick = (): void => {
        setIsLoadingFile(true);
        setIsCompletedLoading(false);
        setIsError(false);
        onDownloadFile(file)
            .then(() => {
                setIsCompletedLoading(true);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoadingFile(false);
            });
    };

    return (
        <Grid container alignItems="center">
            <Grid item>
                <Box pr={4}>
                    <IconLink
                        component="button"
                        onClick={onDownloadFileClick}
                        icon={<FileIcon />}
                        text={file.filename}
                    />
                </Box>
            </Grid>
            <Grid item>
                {isLoadingFile && <CircularProgress size={15} />}
                {isCompletedLoading && <DoneIcon style={{ color: theme?.variables.palette.green, fontSize: '17px' }} />}
                {isError && <ErrorOutlineIcon style={{ color: theme?.variables.palette.red, fontSize: '20px' }} />}
            </Grid>
        </Grid>
    );
};
