import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Toolbar, Tooltip } from '@material-ui/core';
import { withConfirmDialog } from '../../hocs/withConfirmDialog';
import { FormattedMessage } from 'react-intl';
import { IconButtonLarge } from '../buttons/IconButtonLarge';
import { ReactComponent as DeleteIcon } from '../../resources/images/icons/delete.svg';
import React, { PropsWithChildren } from 'react';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';

const TotObjectActionsBarStyled = withTheme(styled(Toolbar)`
    background-color: ${({ theme }): string => theme.palette.primary.contrastText};
    color: ${({ theme }): string => theme.variables.palette.textDark};
    border-top: ${({ theme }): string => `1px solid ${theme.variables.palette.hoverInLists}`};
`);

type TotObjectActionsBarProps = {
    style?: object;
};

export const TotObjectActionsBar = withTheme(
    (props: PropsWithChildren<ThemedComponentProps & TotObjectActionsBarProps>) => {
        return (
            <TotObjectActionsBarStyled
                style={{
                    ...props.style,
                }}
            >
                {props.children}
            </TotObjectActionsBarStyled>
        );
    },
);

type TotObjectDeleteButtonProps = {
    tooltipTitleId: string;
};

export const TotObjectDeleteButton = withConfirmDialog<TotObjectDeleteButtonProps>((props) => (
    <Tooltip title={<FormattedMessage id={props.tooltipTitleId} />}>
        <IconButtonLarge onClick={props.openConfirmDialog}>
            <DeleteIcon />
        </IconButtonLarge>
    </Tooltip>
));
