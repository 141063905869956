import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NavLink, generatePath, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormModel, Form, FormApi } from '@platform/formiojs-react';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks/useStore';
import { useFormStyles } from '../../../hooks';

type RouteParams = {
    id: string;
};

export const CreateCampaignPage = observer(
    (): JSX.Element => {
        const classes = useFormStyles();
        const { campaignsStore, notificationStore } = useStore();
        const { id } = useParams<RouteParams>();
        const history = useHistory();

        const [formApi, setFormApi] = useState<FormApi>();
        const [campaignFormModel] = useState<FormModel>(new FormModel(id));

        useEffect(() => {
            campaignsStore.loadCampaignForm(id).then(campaignFormModel.load);
        }, [campaignsStore, id, campaignFormModel]);

        const onFormReady = (form: FormApi): void => {
            setFormApi(form);
        };

        const onSubmit = (): void => {
            if (formApi && formApi.validate()) {
                formApi
                    .submit(false)
                    .then(() => {
                        return campaignsStore.saveCampaignForm(id, formApi.getSubmission());
                    })
                    .then(() => {
                        history.push(generatePath(clientRoute.campaign, { id }));
                    })
                    .catch((error) => {
                        notificationStore.onError(error.response.data);
                    });
            }
        };

        return (
            <Container maxWidth="lg">
                <Box pt={5.5} pb={5.5}>
                    <Box pb={5.5}>
                        <Typography variant="h3">
                            <FormattedMessage id="campaign.titleCreateCampaignPage" />
                        </Typography>
                    </Box>

                    <Box pb={5.5}>
                        <Form className={classes.formWithNoOffset} form={campaignFormModel} onFormReady={onFormReady} />
                    </Box>

                    <Grid container justify="center">
                        <Grid item style={{ width: '40%' }}>
                            <Box pr={5}>
                                <Button
                                    fullWidth={true}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                    onClick={onSubmit}
                                >
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item style={{ width: '40%' }}>
                            <Button
                                fullWidth={true}
                                size="large"
                                component={NavLink}
                                variant="text"
                                to={clientRoute.campaigns}
                                color="primary"
                            >
                                <FormattedMessage id="common.cancel" />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        );
    },
);
