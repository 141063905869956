import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { CampaignRequestPfModel, TaskStatus } from '../../../../../models';
import { Button, Grid, TableCell, TableRow } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ErrorDialog as ErrorDialogInj, FileDownload as FileDownloadInj } from '../../../../../components';
import { useLocation, useParams } from 'react-router-dom';
import { validationErrorLink as validationErrorLinkInj } from '../../../../../utils/validationErrorLink';
import { di } from 'react-magnetic-di';
import { StyledCircularProgress as StyledCircularProgressInj } from '../CampaignRequestPrintForms.styled';
import { CampaignRequestPrintFormsSignature as CampaignRequestPrintFormsSignatureInj } from '../CampaignRequestPrintFormsSignature';

export const taskStatus: Record<TaskStatus, JSX.Element> = {
    COMPLETED: <FormattedMessage id="campaignRequest.taskStatus.completed" />,
    NEW: <FormattedMessage id="campaignRequest.taskStatus.generating" />,
    ATTEMPT_FAILED: <FormattedMessage id="campaignRequest.taskStatus.generating" />,
    FAILED: <FormattedMessage id="campaignRequest.taskStatus.failed" />,
};

type RouteParams = {
    id: string;
};

export type CampaignRequestPrintFormsRowProps = {
    printForm: CampaignRequestPfModel;
    handleGenerateAction(printForm: CampaignRequestPfModel): () => void;
};

export const CampaignRequestPrintFormsRow = observer(
    (props: CampaignRequestPrintFormsRowProps): JSX.Element => {
        const [validationErrorLink] = di([validationErrorLinkInj], CampaignRequestPrintFormsRow);
        const [ErrorDialog] = di([ErrorDialogInj], CampaignRequestPrintFormsRow);
        const [FileDownload] = di([FileDownloadInj], CampaignRequestPrintFormsRow);
        const [StyledCircularProgress] = di([StyledCircularProgressInj], CampaignRequestPrintFormsRow);
        const [CampaignRequestPrintFormsSignature] = di(
            [CampaignRequestPrintFormsSignatureInj],
            CampaignRequestPrintFormsRow,
        );

        const { printForm, handleGenerateAction } = props;
        const { templateId, templateTitle, pf, errorCode, isGenerating, dropErrorCode, required } = printForm;
        const { id: requestId } = useParams<RouteParams>();

        const startIcon = isGenerating && <StyledCircularProgress />;

        const location = useLocation();

        const buttonTextCode = pf?.id
            ? 'campaignRequest.printFormsTable.updateAction'
            : 'campaignRequest.printFormsTable.generateAction';

        useEffect(() => {
            dropErrorCode();
        }, [location.pathname]);

        return (
            <React.Fragment>
                <TableRow key={templateId}>
                    <TableCell>
                        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <FileDownload
                                    fileName={pf?.filename}
                                    title={templateTitle}
                                    downloadFile={printForm.downloadPf}
                                    required={required}
                                />
                            </Grid>
                            {printForm.pf && (
                                <Grid item>
                                    <CampaignRequestPrintFormsSignature
                                        printForm={printForm}
                                        onSuccess={printForm.updatePf}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </TableCell>
                    <TableCell>{printForm.docCategory || ''}</TableCell>
                    <TableCell>{printForm.delayedTask && taskStatus[printForm.delayedTask?.taskStatus]}</TableCell>
                    <TableCell>{printForm.created && <FormattedDate value={printForm.created} />}</TableCell>
                    <TableCell>
                        <Button
                            disabled={isGenerating}
                            onClick={handleGenerateAction(printForm)}
                            color="secondary"
                            variant="contained"
                            startIcon={startIcon}
                        >
                            <FormattedMessage id={buttonTextCode} />
                        </Button>
                    </TableCell>
                </TableRow>

                <ErrorDialog
                    message={
                        !!errorCode && (
                            <FormattedMessage
                                id={errorCode}
                                values={{
                                    a: validationErrorLink(requestId),
                                }}
                            />
                        )
                    }
                    open={!!errorCode}
                    onClose={dropErrorCode}
                />
            </React.Fragment>
        );
    },
);
