import React, { ReactNode } from 'react';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { FormattedMessage } from 'react-intl';
import { RoutedTabs as RoutedTabsInj, RouteSetting } from '../../../components';
import { Tab } from '@material-ui/core';
import { di } from 'react-magnetic-di';

type RouteParams = {
    id: string;
    rfId?: string;
};

export type AdditionalCampaignSettingTabsInjProps = {
    id: string;
};
export const AdditionalCampaignSettingTabsInj = (props: AdditionalCampaignSettingTabsInjProps): JSX.Element => (
    <React.Fragment />
);
export const getAdditionalCampaignSettingTabsRoutesInj = (): RouteSetting[] => [];

export const CampaignSettingTabs = (): JSX.Element => {
    const [RoutedTabs] = di([RoutedTabsInj], CampaignSettingTabs);
    const [AdditionalCampaignSettingTabs] = di([AdditionalCampaignSettingTabsInj], CampaignSettingTabs);
    const [getAdditionalCampaignSettingTabsRoutes] = di(
        [getAdditionalCampaignSettingTabsRoutesInj],
        CampaignSettingTabs,
    );

    const { id } = useParams<RouteParams>();

    return (
        <RoutedTabs
            tabsProps={{ orientation: 'vertical', indicatorColor: 'primary' }}
            settings={[
                { tab: 0, path: [clientRoute.campaignSettings, clientRoute.campaignSettingsEdit], exact: true },
                { tab: 1, path: clientRoute.campaignRegForms },
                { tab: 2, path: clientRoute.campaignManagement },
                { tab: 3, path: clientRoute.generalSettings },
                ...getAdditionalCampaignSettingTabsRoutes(),
            ]}
        >
            {(updateIndicator): ReactNode => [
                <Tab
                    key="description"
                    value={0}
                    component={NavLink}
                    to={generatePath(clientRoute.campaignSettings, { id })}
                    label={<FormattedMessage id="campaign.campaignDescription" />}
                />,
                <Tab
                    key="regForms"
                    value={1}
                    component={NavLink}
                    to={generatePath(clientRoute.campaignRegForms, { id })}
                    label={<FormattedMessage id="campaign.campaignRegForms" />}
                />,
                <Tab
                    key="management"
                    value={2}
                    component={NavLink}
                    to={generatePath(clientRoute.campaignManagement, { id })}
                    style={{ whiteSpace: 'nowrap' }}
                    label={<FormattedMessage id="campaign.campaignManagement" />}
                />,
                <Tab
                    key="access"
                    value={3}
                    component={NavLink}
                    to={generatePath(clientRoute.generalSettings, { id })}
                    style={{ whiteSpace: 'nowrap' }}
                    label={<FormattedMessage id="campaign.generalSettings" />}
                />,
                <AdditionalCampaignSettingTabs id={id} />,
            ]}
        </RoutedTabs>
    );
};
